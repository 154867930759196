import React, { useState, ChangeEvent } from 'react';
import Logo from '../../img/renata-logo.svg';
import loadingGif from '../../img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/login.css';
import axios from 'axios';
import config from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { BsXCircle } from "react-icons/bs";
import handleLogout from '../../functions/functions';


function DeleteAccount() {
    const [isLoading, setIsLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [loginErrorText, setLoginErrorText] = useState('');
    const gojimx_token = localStorage.getItem('gojimx_token');
    const [accountDeleted, setAccountDeteled] = useState(false);
    const [showModal, setShowModal] = useState(false); // To control modal visibility
    const username = localStorage.getItem('gojimx_username');
    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const gojimx_email = localStorage.getItem('gojimx_email');

    const navigate = useNavigate();

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        if (event.target.value.trim()) {
            setPasswordError(false);
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setShowModal(true);
    };



    const handleConfirmDelete = async () => {
        if (!password) {
            setLoginErrorText('La contraseña es obligatoria.')
            setPasswordError(true);
            return;
        }

        setIsLoading(true);
        try {
            await axios.post(
                `${config.backendURL}/delete_account`,
                { business_id: gojimx_businessId, password },
                {
                    headers: {
                        Authorization: `Bearer ${gojimx_token}`
                    }
                }
            );
            const obj = {
                email: gojimx_email,
                subjectData: "Cuenta desactivada",
                text: "Estimado usuario, " + username + "\n\nNos dirigimos a usted para notificarle que su cuenta ha sido desactivada correctamente.\n\nSi tiene alguna pregunta o desea que sus datos sean borrados permanentemente, por favor comuníquese al correo contacto@goji.mx. \n\nGracias por todo.\n\nAtentamente,\n\nEl equipo de Goji.mx",
                businessId: gojimx_businessId
            };
            await axios.post(`${config.backendURL}/send_email`, obj, {
                headers: {
                    Authorization: `Bearer ${gojimx_token}`, // Use the stored token
                    'Content-Type': 'application/json',
                }
            })

            setAccountDeteled(true);
            setTimeout(() => {
                handleLogout();
            }, 3000);

        } catch (error: any) {
            // Manejar diferentes errores según el código de respuesta
            if (error.response && error.response.status === 401) {
                setPasswordError(true);
                setLoginErrorText('Contraseña incorrecta. Por favor, revise su contraseña e intente nuevamente.');
            } else {
                setPasswordError(true);
                setLoginErrorText('Ocurrió un error interno. Por favor, intente nuevamente más tarde.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='flex-column align-items-center py-3 px-5'>
            <div className='row justify-content-center'>
                <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
                    <img className="w-50 mt-5" src={Logo} alt="Renata Logo" />
                </div>
            </div>

            <div className='row justify-content-center'>
                <div className="col-sm-12 col-md-4 col-lg-3 p-login">
                    <h1 className='fw-bolder text-dark text-center mb-4 animate__animated animate__fadeInUp'>Desactivar su cuenta</h1>
                    <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <p>
                                {username},<br />
                                ¿Estás seguro de que desea desactivar su cuenta?
                                <br />
                                Esta acción ocasionará lo siguiente:
                            </p>
                            <ul className="list-unstyled mb-4">
                                <li className="align-items-center mb-3">
                                    <BsXCircle className='text-danger me-2' size={24} />
                                    <span>Se cancelará su suscripción actual</span>
                                </li>
                                <li className="align-items-center mb-3">
                                    <BsXCircle className='text-danger me-2' size={24} />
                                    <span>Se eliminarán sus Renata Points</span>
                                </li>
                                <li className=" align-items-center mb-3">
                                    <BsXCircle className='text-danger me-2' size={24} />
                                    <span>Perdera acceso a su cuenta</span>
                                </li>
                            </ul>
                        </div>

                        <div className='col'>
                            <div className="d-flex justify-content-between">
                                <button type="button" className="btn btn-dark w-45" disabled={isLoading} onClick={() => { navigate('/home') }}>
                                    No, mantener cuenta
                                </button>
                                <button type="submit" className="btn btn-danger w-45" disabled={isLoading}>
                                    Sí, desactivar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* Confirmation Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {accountDeleted ? <p>Su cuenta ha sido desactivada. Esta página se recargará automáticamente.</p>
                        : (
                            <input
                                type="password"
                                className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                placeholder="Contraseña"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                        )

                    }
                    {passwordError && <div className="invalid-feedback">{loginErrorText}</div>}
                </Modal.Body>
                <Modal.Footer>
                    {!accountDeleted && (
                        <>
                            <Button variant="light" onClick={() => setShowModal(false)}>
                                Cancelar
                            </Button>
                            <Button variant="danger" onClick={handleConfirmDelete} disabled={isLoading}>
                                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Confirmar'}
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default DeleteAccount;