import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Logo from '../../img/renata-settings.png';
import CreatableSelect from 'react-select/creatable';
import ReactSelect, { ActionMeta, MultiValue } from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

interface OptionType {
  value: string;
  label: string;
}

interface SkillType {
  skill_id: string;
  skill_name: string;
}

interface KnowledgeBaseOption {
  knowledge_base_nickname: string;
}

interface CreateAsistenteModalProps {
  showCreateAsistenteModal: boolean;
  handleCloseCreateAsistenteModal: () => void;
  newAsistenteName: string;
  setAssistantName: (value: string) => void;
  newAsistenteDescription: string;
  setAssistantDescription: (value: string) => void;
  intelligenceLevel: string;
  setIntelligenceLevel: (value: string) => void;
  responseLength: string;
  setResponseLength: (value: string) => void;
  workingHoursStart: string;
  setWorkingHoursStart: (value: string) => void;
  workingHoursEnd: string;
  setWorkingHoursEnd: (value: string) => void;
  responseSpeed: string;
  setResponseSpeed: (value: string) => void;
  isTextImproving: boolean;
  loadingRenataLoader: string;
  instructions: string;
  handleTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleTextIA: () => void;
  isButtonDisabledModify: boolean;
  loadingGif: string;
  selectedWhiteList: MultiValue<OptionType>;
  handleChangeWhiteList: (newValue: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => void;
  customStyles: any; // Define el tipo adecuado si lo conoces
  selectedSkill: OptionType[];
  handleSkillChange: (options: OptionType[]) => void;
  skills: SkillType[];
  options: KnowledgeBaseOption[];
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  savingAssistant: boolean;
  handleSaveNewAsistente: () => void;
}

const CreateAssistantModal: React.FC<CreateAsistenteModalProps> = ({
  showCreateAsistenteModal,
  handleCloseCreateAsistenteModal,
  newAsistenteName,
  setAssistantName,
  newAsistenteDescription,
  setAssistantDescription,
  intelligenceLevel,
  setIntelligenceLevel,
  responseLength,
  setResponseLength,
  workingHoursStart,
  setWorkingHoursStart,
  workingHoursEnd,
  setWorkingHoursEnd,
  responseSpeed,
  setResponseSpeed,
  isTextImproving,
  loadingRenataLoader,
  instructions,
  handleTextChange,
  handleTextIA,
  isButtonDisabledModify,
  loadingGif,
  selectedWhiteList,
  handleChangeWhiteList,
  selectedSkill,
  handleSkillChange,
  skills,
  options,
  handleSelectChange,
  savingAssistant,
  handleSaveNewAsistente,
  customStyles,
}) => {
  return (
    <Modal className="modal-lg" show={showCreateAsistenteModal} onHide={handleCloseCreateAsistenteModal}>
      <Modal.Header closeButton>
        <div className="btn bg-light p-3 w-100 d-flex align-items-center" style={{ textAlign: 'left' }}>
          <img style={{ width: '12%' }} className="me-2" src={Logo} alt="Renata Logo" />
          <div className="d-flex flex-column justify-content-center">
            <h3 className="fw-bold mb-0">Nuevo Asistente</h3>
            <p className="mb-0">Configura tu nuevo asistente</p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form className="p-3">
          <Form.Group controlId="asistenteName" className="mb-4">
            <Form.Label>Nombre del asistente</Form.Label>
            <Form.Control
              type="text"
              value={newAsistenteName}
              onChange={(e) => setAssistantName(e.target.value)}
              placeholder="Introduce el nombre del asistente"
            />
          </Form.Group>
          <Form.Group controlId="asistenteDescription" className="mb-4">
            <Form.Label>Descripción del asistente</Form.Label>
            <Form.Control
              type="text"
              value={newAsistenteDescription}
              onChange={(e) => setAssistantDescription(e.target.value)}
              placeholder="Introduce la descripción del asistente"
            />
          </Form.Group>
          <Form.Group controlId="intelligenceLevel" className="mb-4">
            <div className="row">
              <Form.Label className="col-sm-4 col-form-label">Nivel de inteligencia</Form.Label>
              <div className="col-sm-8 d-flex">
                <Button
                  variant={intelligenceLevel === 'media' ? 'dark' : 'light'}
                  className="me-2"
                  onClick={() => setIntelligenceLevel('media')}
                >
                  😊
                </Button>
                <Button
                  variant={intelligenceLevel === 'alta' ? 'dark' : 'light'}
                  onClick={() => setIntelligenceLevel('alta')}
                >
                  😎
                </Button>
              </div>
            </div>
          </Form.Group>
          <Form.Group controlId="responseLength" className="mb-4">
            <div className="row">
              <Form.Label className="col-sm-4 col-form-label">Longitud de respuestas</Form.Label>
              <div className="col-sm-8 d-flex">
                <Button
                  variant={responseLength === 'cortas' ? 'dark' : 'light'}
                  className="me-2"
                  onClick={() => setResponseLength('cortas')}
                >
                  cortas
                </Button>
                <Button
                  variant={responseLength === 'media' ? 'dark' : 'light'}
                  className="me-2"
                  onClick={() => setResponseLength('media')}
                >
                  medio
                </Button>
                <Button
                  variant={responseLength === 'larga' ? 'dark' : 'light'}
                  onClick={() => setResponseLength('larga')}
                >
                  largo
                </Button>
              </div>
            </div>
          </Form.Group>
          <Form.Group controlId="workingHours" className="mb-4">
            <div className="row">
              <Form.Label className="col-sm-4 col-form-label">Horario de atención</Form.Label>
              <div className="col-sm-8 d-flex">
                <Form.Select
                  value={workingHoursStart}
                  onChange={(e) => setWorkingHoursStart(e.target.value)}
                  className="me-2"
                >
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i} value={i}>{`${i}h`}</option>
                  ))}
                </Form.Select>
                <Form.Select value={workingHoursEnd} onChange={(e) => setWorkingHoursEnd(e.target.value)}>
                  {Array.from({ length: 24 }, (_, i) => (
                    <option key={i} value={i}>{`${i}h`}</option>
                  ))}
                </Form.Select>
              </div>
            </div>
          </Form.Group>
          <Form.Group controlId="responseSpeed" className="mb-4">
            <div className="row">
              <Form.Label className="col-sm-4 col-form-label">Velocidad de respuesta</Form.Label>
              <div className="col-sm-8">
                <div className="d-flex align-items-center">
                  <p className="mb-0">🐢</p>
                  <Form.Range
                    value={responseSpeed}
                    onChange={(e) => setResponseSpeed(e.target.value)}
                    min="0"
                    max="2"
                    step="1"
                    className="ms-2 me-2"
                  />
                  <p className="mb-0">🐇</p>
                </div>
                <div className="d-flex justify-content-between">
                  <small>lenta</small>
                  <small>media</small>
                  <small>rápida</small>
                </div>
              </div>
            </div>
          </Form.Group>
          <Form.Group controlId="instructions" className="mb-4">
            <Form.Label>Instrucciones</Form.Label>
            {isTextImproving ? (
              <div className="renata-loader-container">
                <img className="renata-loader" src={loadingRenataLoader} alt="Cargando..." />
              </div>
            ) : (
              <Form.Control as="textarea" rows={3} value={instructions} onChange={handleTextChange} />
            )}
          </Form.Group>
          <Button
            className="mt-3"
            variant="primary"
            onClick={handleTextIA}
            disabled={isButtonDisabledModify || isTextImproving}
          >
            {isTextImproving ? (
              <img className="loading" src={loadingGif} alt="Cargando..." />
            ) : (
              'Mejorar Texto con IA'
            )}
          </Button>
          <Form.Group controlId="whiteList" className="mt-3">
            <Form.Label>Números permitidos</Form.Label>
            <CreatableSelect
              isClearable
              value={selectedWhiteList}
              onChange={handleChangeWhiteList}
              options={[]}
              isMulti={true}
              components={animatedComponents}
              placeholder="Números que Renata puede responder (deje en blanco si no hay limitación)"
              styles={customStyles}
            />
          </Form.Group>
          <Form.Group controlId="abilities" className="mt-3">
            <Form.Label>Habilidades</Form.Label>
            <ReactSelect
              value={selectedSkill}
              closeMenuOnSelect={true}
              placeholder="Habilidades"
              components={animatedComponents}
              isMulti={true}
              onChange={(option) => handleSkillChange(option as OptionType[])}
              options={skills.map((skill) => ({
                value: skill.skill_id,
                label: skill.skill_name,
              }))}
              menuPortalTarget={document.body}
              styles={customStyles}
            />
          </Form.Group>
          <Form.Group controlId="baseConocimiento" className="mt-4">
            <Form.Label>Base de conocimiento</Form.Label>
            <Form.Select aria-label="Default select example" onChange={handleSelectChange}>
              <option value="">Ninguna</option>
              {options.map((option, index) => (
                <option key={index} value={option.knowledge_base_nickname}>
                  {option.knowledge_base_nickname}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center justify-content-between w-100">
          <span></span>
          {!savingAssistant ? (
            <Button variant="dark" onClick={handleSaveNewAsistente}>
              Guardar
            </Button>
          ) : (
            <div className="ms-auto me-3">
              <h1 style={{ fontWeight: 'bold' }}>
                <img className="loading" src={loadingGif} alt="Cargando..." />
              </h1>
            </div>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateAssistantModal;