import React, { useEffect, useRef, useState } from 'react';
import { formatPhoneNumber } from '../../functions/functions';
import config from '../../config/config';
import loadingGif from '../../img/loading.gif';
import renata_img from '../../img/renata.png'; // Import Renata's image
import user_img from '../../img/user.png';
import ConfirmationModal from '../Modals/ConfirmationModal';
import axios from 'axios';

interface Conversation {
    category: string;
    id: string;
    threadId: string;
    business_id: string;
    summary: string;
    user_name: string;
    user_phone: string;
    renata_sleep: boolean;
    active_conversation: boolean;
    last_message_date: string;
}

interface Message {
    id: string;
    object: string;
    created_at: number;
    assistant_id: string | null;
    thread_id: string;
    run_id: string | null;
    role: string;
    content: {
        type: string;
        text: {
            value: string;
            annotations: any[];
        };
    }[];
    file_ids: any[];
    metadata: Record<string, unknown>;
}

interface RightColumnProps {
    selectedConversationPreview: Conversation | undefined;
    categoryIcons: { [key: string]: string | undefined };
    handleShowModalDetails: () => void;
    fetchConversations: () => void;

}

const RightColumn: React.FC<RightColumnProps> = ({ selectedConversationPreview, categoryIcons, handleShowModalDetails, fetchConversations }) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const gojimx_token = localStorage.getItem('gojimx_token');
    const [fetchingMessages, setFetchingMessages] = useState(false);
    const conversationBoxRef = useRef<HTMLDivElement>(null); // Specify the type
    const [renata_sleep, setRenataSleep] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState<string>('');
    const [confirmModalType, setConfirmModalType] = useState('✅')

    async function handleRenataSleepChange(e: { target: { checked: any; }; }) {
        const newRenataSleepStatus = !e.target.checked;

        // Ensure opened_conversation is not null or undefined
        if (selectedConversationPreview) {
            // Create a new object with the updated renata_sleep value
            const updatedConversation = { ...selectedConversationPreview, renata_sleep: newRenataSleepStatus };

            // Update the state to trigger a re-render
            //   setOpenedConversation(updatedConversation);

            const url = `${config.backendURL}/updateRenataSleep`;
            const requestBody = {
                threadId: updatedConversation.threadId,
                renata_sleep: newRenataSleepStatus,
            };

            try {
                await axios.post(url, requestBody, {
                    headers: {
                        Authorization: `Bearer ${gojimx_token}`,
                        'Content-Type': 'application/json',
                    },
                });
                setConfirmModalType('✅');
                setModalMessage(`Renata ha sido ${newRenataSleepStatus ? 'apagada' : 'encendida'} en esta conversación.`);
                setIsModalOpen(true);
                selectedConversationPreview.renata_sleep = newRenataSleepStatus;
                // fetchConversations();
            } catch (error) {
                console.error('Error updating Renata sleep status:', error);
                setConfirmModalType('❌');

                setModalMessage('Failed to update Renata sleep status. Please try again.');
                setIsModalOpen(true);
                window.location.reload();
            }
        }
    }

    function convertirAFecha(fechaEnSegundos: number) {
        const fechaEnMilisegundos = fechaEnSegundos * 1000;
        const fecha = new Date(fechaEnMilisegundos);
        const fechaFormateada = fecha.toLocaleString();
        return fechaFormateada;
    }

    // Helper function to remove brackets from message text
    function eliminarCorchetes(text: string) {
        if (text.startsWith('[RENATA MESSAGE]')) {
            return text;
        }
        const regex = /\[.*?\]/g;
        return text.replace(regex, "");
    }

    const scrollToBottom = () => {
        if (conversationBoxRef.current) {
          conversationBoxRef.current.scrollTo({
            top: conversationBoxRef.current.scrollHeight,
            behavior: 'smooth',
          });
        }
      };

    const fetchMessages = async (firstTime: boolean) => {
        if (!selectedConversationPreview) return;
        setFetchingMessages(true);

        const url = `${config.backendURL}/get_messages/${selectedConversationPreview?.threadId}`;
        const response = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${gojimx_token}`,
            },
        });

        if (!response.ok) {
            setFetchingMessages(false);

            console.error("Network response was not ok");
            return;
        }

        const data = await response.json();
        setMessages(data.data.reverse());
        if (firstTime) {
            setTimeout(scrollToBottom, 200);
            if (data.renata_sleep) {
                setRenataSleep(data.renata_sleep);
            }
        }

        setFetchingMessages(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (selectedConversationPreview?.threadId) {
                
                await fetchMessages(true);
            }
        };

        fetchData();
    }, [selectedConversationPreview]);


    return (
        <div className='col-md-6' style={{ height: 'calc(100vh - 80px)', display: 'flex', flexDirection: 'column' }}>
            {selectedConversationPreview && (
                <>
                    {selectedConversationPreview.threadId && (

                        <div className='bg-gray p-3 rounded'>
                            <>

                                <div className='d-flex justify-content-between align-items-center my-2'>

                                    <div>

                                        <h5 className='fw-bold text-dark mb-1'>{selectedConversationPreview.user_name}</h5>
                                        <p className='text-muted mb-0'>{selectedConversationPreview.summary}</p>
                                        <div className='badge bg-warning text-dark mt-3 d-inline-flex align-items-center'>
                                            <h4 className='mb-0'>{categoryIcons[selectedConversationPreview.category]}</h4>
                                            <h6 className='mx-2 mb-0 fw-bold text-dark'>{selectedConversationPreview.category}</h6>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-start mx-2'>
                                        <button className='btn btn-outline-dark' onClick={() => {
                                            handleShowModalDetails();
                                        }}>
                                            <i className='bi bi-gear'></i>
                                        </button>
                                    </div>
                                </div>
                            </>
                        </div>
                    )}

                    <div className='bg-gray p-3 rounded mt-4' style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                        <>
                            <div className='d-flex align-items-center mx-3 justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <h5 className='fw-bold text-dark mb-0 me-3'>WhatsApp</h5>
                                    <h5 className='fw-bolder mb-0 text-secondary'>{formatPhoneNumber(selectedConversationPreview.user_phone)}</h5>
                                    {selectedConversationPreview.threadId && (
                                        <div className="form-check mx-3 form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedConversationPreview?.renata_sleep === false || selectedConversationPreview?.renata_sleep === undefined}
                                                onChange={handleRenataSleepChange}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='d-flex align-items-center ml-auto'>
                                    {selectedConversationPreview.threadId && (
                                        <>
                                            <button
                                                className='btn btn-outline-dark mx-2'
                                                onClick={() => {
                                                    const link = config.frontURL +
                                                        "/messages?thread=" + String(selectedConversationPreview?.threadId) +
                                                        "&name=" + selectedConversationPreview?.user_name +
                                                        "&id=" + selectedConversationPreview?.id +
                                                        "&phone=" + selectedConversationPreview?.user_phone +
                                                        "&summary=" + selectedConversationPreview?.summary +
                                                        "&categoryName=" + selectedConversationPreview?.category +
                                                        (categoryIcons && selectedConversationPreview?.category ? "&icon=" + categoryIcons[selectedConversationPreview?.category] : "");

                                                    navigator.clipboard.writeText(link)
                                                        .then(() => {
                                                            setModalMessage('Se copió el link para compartir la conversación');
                                                            setIsModalOpen(true);
                                                            console.log("Link copied to clipboard!");
                                                        })
                                                        .catch(err => {
                                                            setModalMessage('No se pudeo copiar el link para compartir la conversación');
                                                            setIsModalOpen(true);
                                                            console.error("Failed to copy: ", err);
                                                        });
                                                }}
                                            >
                                                <i className="bi bi-share"></i>
                                            </button>
                                            <a
                                                href={
                                                    "/messages?thread=" + String(selectedConversationPreview?.threadId) +
                                                    "&name=" + selectedConversationPreview?.user_name +
                                                    "&id=" + selectedConversationPreview?.id +
                                                    "&phone=" + selectedConversationPreview?.user_phone +
                                                    "&summary=" + selectedConversationPreview?.summary +
                                                    "&categoryName=" + selectedConversationPreview?.category +
                                                    (categoryIcons && selectedConversationPreview?.category ? "&icon=" + categoryIcons[selectedConversationPreview?.category] : "")
                                                }
                                                className="btn btn-outline-dark"
                                            >
                                                <i className="bi bi-fullscreen"></i>
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>

                        </>
                        {fetchingMessages ?
                            <div className='d-flex justify-content-center mt-5'>
                                <img src={loadingGif} alt="Cargando..." style={{ width: '30px' }} />
                            </div>
                            :
                            (selectedConversationPreview.threadId && selectedConversationPreview.active_conversation != false ? (
                                <div className='rounded conversation-box-my-conversations m-3' style={{ flexGrow: 1, overflow: 'hidden' }}>
                                    <div className='conversation-scroll-my-conversations bg-white' ref={conversationBoxRef} style={{ height: '100%', overflowY: 'auto' }}>
                                    <div className="fade-top-preview"></div>

                                        {messages.map((message) => {
                                            const messageText = message.content[0].text.value;
                                            const messageClass = messageText.startsWith('[RENATA MESSAGE]') || message.role !== 'user'
                                                ? 'message-item-2'
                                                : 'message-item-1';
                                            const isRenataMessage = (message.role === 'user' && !messageText.startsWith('[RENATA MESSAGE]'));
                                            return (
                                                <div key={message.id} className="row px-3 animate__animated animate__fadeIn">
                                                    <div className={`d-flex justify-content-${isRenataMessage ? 'start' : 'end'}`}>
                                                        {isRenataMessage ? <img src={user_img} className='user_image rounded mx-2' alt="User" /> : ""}
                                                        <p className={`${messageClass} mb-0 p-3`}>
                                                            {eliminarCorchetes(messageText)}
                                                        </p>
                                                        {!isRenataMessage ? <img src={renata_img} className='renata_image rounded mx-2' alt="Renata" /> : ""}
                                                    </div>
                                                    <div className={`d-flex justify-content-${isRenataMessage ? 'start' : 'end'}`}>
                                                        <p className={`text-secondary pt-0 mt-0 mb-3 ${isRenataMessage ? 'me-date2' : 'me-date'}`}>
                                                            {(message?.metadata?.audio ? '🎤(Mensaje de voz) ' : '') + convertirAFecha(Number(message.created_at))}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) :
                                <div className='d-flex justify-content-center mt-5'>
                                    <h5 className='fw-bold text-dark mb-0 me-3'>Conversación reiniciada</h5>

                                </div>
                            )
                        }

                    </div>
                </>
            )}

            <ConfirmationModal
                show={isModalOpen}
                onHide={() => setIsModalOpen(false)}
                text={modalMessage}
                type={confirmModalType}
                titleText="Resultado de la Operación"
            />
        </div>
    );
};

export default RightColumn;