import React, { ChangeEvent, useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config/config';
import ConfirmationModal from './ConfirmationModal';
import loadingGif from '../../img/loading.gif';


interface EditChannelModalProps {
  show: boolean;
  onHide: () => void;
  channel?: Channel;
  fetchBusiness: () => void;
}

interface Channel {
    channel_name: string;
    active: boolean;
    username: string;
    main_number: boolean,
    baileys_port: string,
    baileys_status: boolean,
    channel_type: string
}

const EditChannelModal: React.FC<EditChannelModalProps> = ({ show, onHide, channel, fetchBusiness }) => {
  const [newChannelName, setNewChannelName] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [confirmationModalText, setConfirmationModalText] = useState('');
  const [confirmationModalType, setConfirmationModalType] = useState('');
  const [newChannelNameError, setNewChannelNameError] = useState(false);

  const gojimx_businessId = localStorage.getItem('gojimx_businessId');
  const gojimx_token = localStorage.getItem('gojimx_token');

  const handleNewNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewChannelName(event.target.value);
    setNewChannelNameError(false);
  };

  useEffect(() => {
    setNewChannelName(channel ? channel.channel_name : '');
  }, [show])

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!newChannelName.trim().length) {
      setNewChannelNameError(true);
      return;
    }

    setSaving(true);

    try {
      await axios.post(
        `${config.backendURL}/update_channel_name`,
        {
          business_id: gojimx_businessId,
          username: channel?.username,
          new_channel_name: newChannelName,
        },
        {
          headers: {
            Authorization: `Bearer ${gojimx_token}`,
          },
        }
      );

      setConfirmationModalText('El nombre del canal se ha actualizado correctamente.');
      setConfirmationModalType('✅');
      setConfirmationModalShow(true);
      fetchBusiness();
      onHide(); 
    } catch (error: any) {
      console.error('Error updating channel name:', error);
      setConfirmationModalText('No se pudo actualizar el nombre del canal, inténtelo más tarde.');
      setConfirmationModalType('❌');
      setConfirmationModalShow(true);
      onHide();

    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Editar Canal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formChannelName">
              <Form.Label>Nombre del canal</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingresa el nuevo nombre del canal"
                value={newChannelName}
                onChange={handleNewNameChange}
                isInvalid={newChannelNameError}
                required
              />
              {newChannelNameError && (
                <Form.Control.Feedback type="invalid">
                  Por favor, introduce un nombre válido.
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {saving ? (
            <div className="ms-auto me-3">
              <h1 style={{ fontWeight: 'bold' }}>
                <img className="loading" src={loadingGif} alt="Cargando..." />
              </h1>
            </div>
          ) : (
            <>
              <Button variant="light"  onClick={onHide}>
                Cancelar
              </Button>
              <Button variant="dark"  onClick={handleFormSubmit}>
                Guardar Cambios
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <ConfirmationModal
        show={confirmationModalShow}
        onHide={() => setConfirmationModalShow(false)}
        text={confirmationModalText}
        type={confirmationModalType}
        titleText="Resultado de la Operación"
      />
    </>
  );
};

export default EditChannelModal;