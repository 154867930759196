import React, { useState, ChangeEvent } from 'react';
import axios, { AxiosError } from 'axios';
import Logo from '../../img/renata-logo.svg';
import loadingGif from '../../img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/login.css';
import config from '../../config/config';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { useNavigate } from 'react-router-dom';

function CreateUser() {

  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(false);

  const gojimx_token = localStorage.getItem('gojimx_token');
  const gojimx_businessId = localStorage.getItem('gojimx_businessId');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [confirmModalType, setConfirmModalType] = useState('✅')
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate('/');
  };
  
  const registerUser = async () => {
    try {
      //TODO Cambiar url
      //const response = await axios.post(`${config.backendURL}/}`, {
        const response = await axios.post(`${config.backendURL}/addNewUser`, {
        businessId: gojimx_businessId,
        username: username,
        password: password,
        phoneNumber: phoneNumber,
        email: email
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        }
      });
      if (response.status === 200) {
        setConfirmModalType('✅');
        setModalMessage('Usuario agregado correctamente');
        setIsModalOpen(true);

        setUsername('');
        setPassword('');
        setPhoneNumber('');
        setEmail('');
        setIsValidNumber(false);
        setTimeout(() => {
          redirectToHome();
      }, 2500);
      }
      
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const getError = error.response.data.message;
          if (getError === 'user limit reached.') {
            setConfirmModalType('⚠️');
            setModalMessage('Alcanzaste el número máximo de usuarios permitidos');
            setIsModalOpen(true);
          }
          if (getError === 'username already exists.') {
            setConfirmModalType('⚠️');
            setModalMessage('El usuario que intentas agregar ya existe');
            setIsModalOpen(true);
          }
          if (getError === 'phoneNumber already exists.') {
            setConfirmModalType('⚠️');
            setModalMessage('El número que intentas agregar ya existe');
            setIsModalOpen(true);
          }
          if (getError === 'email already exists.') {
            setConfirmModalType('⚠️');  
            setModalMessage('El correo que intentas agregar ya existe');
            setIsModalOpen(true);
          }
        } else if (error.request) {
          console.log('Request made but no response received:', error.request);
        } else {
          console.log('Error message:', error.message);
        }
      } else {
        console.error('An unknown error occurred:', error);
      }
    }
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/\D/g, '');
    const prefix = value.slice(0, 2);
    if (prefix === '55' || '56' || '33' || '81') {
      if (value.length > 2 && value.length <= 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2);
      } else if (value.length > 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2, 6) + ' ' + value.slice(6, 10);
      }
    } else {
      if (value.length > 3 && value.length <= 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3);
      } else if (value.length > 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6, 10);
      }
    }

    setPhoneNumber(value);
    setPhoneError(false);
    setIsValidNumber(value.length === 12);
  };

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setUsernameError(false);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let valid = true;
    setIsLoading(true);

    if (!username.trim()) {
      setUsernameError(true);
      valid = false;
    } else {
      setUsernameError(false);
    }

    if (!isValidNumber) {
      setPhoneError(true);
      valid = false;
    } else {
      setPhoneError(false);
    }

    if (password.length < 5) {
      setPasswordError(true);
      valid = false;
    } else {
      setPasswordError(false);
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }

    if (!valid) {
      setIsLoading(false);
      return;
    }

    await registerUser();

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (

    <div className='flex-column align-items-center py-3 px-5'>
      <ConfirmationModal
        show={isModalOpen}
        onHide={() => {
          setIsModalOpen(false);
        }}
        text={modalMessage}
        type={confirmModalType}
        titleText='Registro'
      />
      <div className='row justify-content-center'>
        <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
          <img className="w-50 mt-5" src={Logo} alt="Renata Logo" />
        </div>
      </div>

      <div className='row justify-content-center'>
        <div className='col-sm-12 col-md-4 col-lg-3 p-login'>
          <h1 className='fw-bolder text-dark text-center mb-4 animate__animated animate__fadeInUp'>Agregar usuario</h1>
          <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
            <div className='animate__animated animate__fadeIn'>
              <div className="mb-3">
                <input
                  id='name'
                  type="text"
                  className={`form-control bg-light centered-placeholder centered-input-container d-flex ${usernameError ? 'is-invalid' : ''}`}
                  placeholder='Nombre completo'
                  onChange={handleUsernameChange}
                  value={username}
                  autoComplete="off"
                />
                {usernameError && <div className="invalid-feedback">Por favor, introduce un nombre.</div>}
              </div>
              <div className="mb-3">
                <input
                  id='email'
                  type="email"
                  className={`form-control bg-light centered-placeholder centered-input-container d-flex ${emailError ? 'is-invalid' : ''}`}
                  placeholder='Correo electrónico'
                  onChange={handleEmailChange}
                  value={email}
                  autoComplete="off"
                />
                {emailError && <div className="invalid-feedback">Por favor, introduce un correo electrónico válido.</div>}
              </div>
              <div className="mb-3">
                <input
                  id='tel'
                  type="tel"
                  className={`form-control bg-light centered-placeholder centered-input-container d-flex ${phoneError ? 'is-invalid' : ''}`}
                  placeholder='Número de teléfono'
                  onChange={handlePhoneChange}
                  value={phoneNumber}
                  autoComplete="off"
                />
                {phoneError && <div className="invalid-feedback">Por favor, introduce un teléfono válido.</div>}
              </div>
              <div className="mb-3">
                {isValidNumber && (
                  <input
                    id='password'
                    type="password"
                    className={`form-control bg-light centered-placeholder centered-input-container d-flex ${passwordError ? 'is-invalid' : ''}`}
                    placeholder='Contraseña'
                    onChange={handlePasswordChange}
                    autoComplete="off"
                  />
                )}
                {passwordError && <div className="invalid-feedback">La contraseña debe tener al menos 5 caracteres.</div>}
              </div>
              <button type="submit" className="btn btn-dark w-100 mt-4" disabled={isLoading}>
                {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Guardar usuario'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateUser;
