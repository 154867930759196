import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import loadingGif from '../../img/loading.gif'


function DiscountCodeModal({ show, handleClose, handleApplyCoupon, codeError, errorMessage, setCodeError, setErrorMessage, discountCode, setDiscountCode, isLoading }) {


    const handleChange = (e) => {
        setDiscountCode(e.target.value);
        if (e.target.value.trim()) {
            setCodeError(false);
            setErrorMessage('');
        }
    };

    const handleApply = () => {
        if (!discountCode.trim()) {
            setErrorMessage('El código de descuento es obligatorio.');
            setCodeError(true);
            return;
        }
        handleApplyCoupon(discountCode);
        setDiscountCode();
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Ingrese su cupón de descuento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formDiscountCode">
                        <Form.Label>Cupón de descuento</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ingrese el cupón proporcionado"
                            value={discountCode}
                            onChange={handleChange}
                            className={codeError ? 'is-invalid' : ''}
                        />
                        {codeError && <div className="invalid-feedback">{errorMessage}</div>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="dark" onClick={handleApply}>
                    {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Aplicar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DiscountCodeModal;