import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Slider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config/config';
import SupportChatModal from '../Home/SupportChatModal';

interface StoreModalProps {
    show: boolean;
    handleClose: () => void;
}

const StoreModal: React.FC<StoreModalProps> = ({ show, handleClose }) => {
    const [points, setPoints] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('');
    const pricePerPoint = 0.02;
    const navigate = useNavigate();
    const [supportChatOpen, setSupportChatOpen] = useState(false);
    const [predefinedQuestion, setPredefinedQuestion] = useState<string>();

    const handleSupportChatOpen = (question: string) => {
        setPredefinedQuestion(question);
        setSupportChatOpen(true);
      };
    const handleSupportChatClose = () => setSupportChatOpen(false);

    const paymentType = localStorage.getItem('gojimx_payment_type');

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setPoints(newValue as number);
    };

    const formatNumberWithCommas = (number: number) => {
        return new Intl.NumberFormat().format(number);
    };

    const handleProceedToPurchase = () => {
        const amount = Number((points * pricePerPoint).toFixed(2));
        navigate(`/payment-custom?amount=${amount}&renataPoints=${points}`);
    };

    const handlePlanClick = (plan: string) => {
        if (plan !== paymentType) {
            setSelectedPlan(plan);
            setShowConfirmModal(true);
            handleClose();  // Close the store modal immediately
        }
    };

    const handleConfirmPlanChange = async () => {
        // Logic to change the plan can be added here
        console.log(`Changing plan to: ${selectedPlan}`);
        setShowConfirmModal(false);

        localStorage.setItem("gojimx_payment_type", selectedPlan)
        
        const response = await axios.post(
            `${config.backendURL}/change_business_payment`,
            {
                business_id: localStorage.getItem('gojimx_businessId'),
                new_payment_type: selectedPlan,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('gojimx_token')}`,
                },
            }
        );

        navigate('/payment');
    };

    return (
        <>
            <Modal className='modal-lg' show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>
                        <i className='bi bi-x-diamond-fill text-primary me-2'></i>Tienda Renata Points
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-1 mx-auto text-center">
                        <div className='row px-4'>
                            <p className='px-1 fw-bold lead'>Plan actual <i className="bi bi-question-circle" onClick={() => handleSupportChatOpen("¿Cuáles son los planes actuales?")}></i></p>
                        </div>

                        <div className="row justify-content-center pt-2">
                            {/* Standard Plan */}
                            <div
                                className="px-0 col-lg-5 col-md-8 mb-4 pricing card mb-4 bg-white border-radius-custom-3 me-3"
                                onClick={() => handlePlanClick('Standard')}
                                style={{
                                    cursor: 'pointer',
                                    border: paymentType === 'Standard' ? '1px solid black' : '',
                                    boxShadow: paymentType === 'Standard' ? '0 0 10px rgba(0, 0, 0, 0.5)' : '',
                                }}
                            >
                                <div
                                    className={`card-header border-radius-custom-3 w-100 ${paymentType === 'Standard' ? 'bg-dark text-white' : 'bg-light'}`}
                                >
                                    <h4 className={`my-0 fw-bold p-4 w-100 ${paymentType === 'Standard' ? 'text-white' : 'text-dark'}`}>🙋🏻‍♀️ Standard</h4>
                                </div>
                                <div className="card-body pt-4 pb-5 border-radius-custom-3">
                                    <h1 className="card-title pricing-card-title text-start ps-3">$990<small className="text-muted ms-1">/mes*</small></h1>
                                    <ul className="list-unstyled mt-3 mb-4 text-start ps-3">
                                        <li>• 3 Números de WhatsApp</li>
                                        <li>• 3 Asistentes Chat</li>
                                        <li>• 3 Accesos Renata-APP</li>
                                        <li>• Contesta 24/7</li>
                                        <li><i className='bi bi-x-diamond-fill text-primary me-2'></i>55,000 puntos mensuales</li>
                                    </ul>
                                </div>
                            </div>

                            {/* Premium Plan */}
                            <div
                                className="px-0 col-lg-5 col-md-8 mb-4 pricing card mb-4 bg-white border-radius-custom-3 me-3"
                                onClick={() => handlePlanClick('Premium')}
                                style={{
                                    cursor: 'pointer',
                                    border: paymentType === 'Premium' ? '1px solid black' : '',
                                    boxShadow: paymentType === 'Premium' ? '0 0 10px rgba(0, 0, 0, 0.5)' : '',
                                }}
                            >
                                <div
                                    className={`card-header border-radius-custom-3 w-100 ${paymentType === 'Premium' ? 'bg-dark text-white' : 'bg-light'}`}
                                >
                                    <h4 className={`my-0 fw-bold p-4 w-100 ${paymentType === 'Premium' ? 'text-white' : 'text-dark'}`}>⭐ Premium</h4>
                                </div>
                                <div className="card-body pt-4 pb-5 border-radius-custom-3">
                                    <h1 className="card-title pricing-card-title text-start ps-3">$2990<small className="text-muted ms-1">/mes*</small></h1>
                                    <ul className="list-unstyled mt-3 mb-4 text-start ps-3">
                                        <li>• 6 Números de WhatsApp</li>
                                        <li>• 6 Asistentes Chat y Llamada</li>
                                        <li>• 6 Accesos Renata-APP</li>
                                        <li>• Contesta 24/7</li>
                                        <li>• Soporte Técnico</li>
                                        <li><i className='bi bi-x-diamond-fill text-primary me-2'></i>180,000 puntos mensuales</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='row px-4'>
                            <p className='px-1 fw-bold lead'>Comprar otra cantidad</p>
                        </div>

                        <div className='row justify-content-center'>
                            <div className='col-6 border-radius-custom-3 bg-white p-3 border border-1 mx-5'>
                                <div className="row d-flex ">
                                    <span className='lead display-6'> ${points == 0 ? (points * pricePerPoint).toFixed(2) : (points * pricePerPoint).toFixed(2) + 'MXN'} </span>
                                    <span>
                                        (<i className='bi bi-x-diamond-fill text-primary'></i><samp className='ms-1'>{formatNumberWithCommas(points)}</samp>)
                                    </span>
                                </div>

                                {/* Slider Section */}
                                <div className="row justify-content-center mt-4">
                                    <div className="col-10">
                                        <Slider
                                            value={points}
                                            onChange={handleSliderChange}
                                            min={0}
                                            max={500000}
                                            step={30000}
                                            valueLabelDisplay="auto"
                                            marks={[
                                                { value: 0, label: '0' },
                                                { value: 100000, label: '100,000' },
                                                { value: 200000, label: '200,000' },
                                                { value: 300000, label: '300,000' },
                                                { value: 400000, label: '400,000' },
                                                { value: 500000, label: '500,000' }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Conditionally render the "Proceed to Purchase" button */}
                        {points > 0 && (
                            <div className='row justify-content-center mt-3'>
                                <div className='col-6'>
                                    <Button variant="dark" className="w-100" onClick={handleProceedToPurchase}>
                                        <span><i className="bi bi-lock-fill me-2"></i>Proceder a la compra</span>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            {/* Confirmation Modal */}
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Cambio de Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Seguro que deseas cambiar tu plan a <span className='text-primary fw-bold'>{selectedPlan}</span>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-dark" onClick={() => setShowConfirmModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="dark" onClick={handleConfirmPlanChange}>
                        Continuar <i className="bi bi-box-arrow-up-right ms-1"></i>
                    </Button>
                </Modal.Footer>
            </Modal>
            <SupportChatModal show={supportChatOpen} handleClose={handleSupportChatClose} predefinedQuestion={predefinedQuestion}/>
        </>
    );
};

export default StoreModal;