import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import AdvancedFilters from './AdvancedFilters';
import { formatPhoneNumber } from '../../functions/functions';
import loadingGif from '../../img/loading.gif';
import { CSSTransition, TransitionGroup, Transition } from 'react-transition-group';


interface CategoryIcons {
  [key: string]: string | undefined;
}

interface Conversation {
  category: string;
  id: string;
  threadId: string;
  business_id: string;
  summary: string;
  user_name: string;
  user_phone: string;
  renata_sleep: boolean;
  active_conversation: boolean;
  last_message_date: string;
}

interface LeftColumnProps {
  conversations: Conversation[];
  categoryIcons: CategoryIcons;
  selectedConversationPreview: Conversation | undefined;
  setSelectedConversationPreview: React.Dispatch<React.SetStateAction<Conversation | undefined>>;
  handleShowModalCarga: () => void;
  handleShowModalMensaje: () => void;
  exportToExcel: (conversations: Conversation[]) => void;
  gojimx_businessId: string | null;
  gojimx_token: string | null;
  filterFecha: string | null;
  setFilterFecha: React.Dispatch<React.SetStateAction<string | null>>;
  filterEstado: string | null;
  setFilterEstado: React.Dispatch<React.SetStateAction<string | null>>;
  filterRenataState: string | null;
  setFilterRenataState: React.Dispatch<React.SetStateAction<string | null>>;
  filterCategory: string | null;
  setFilterCategory: React.Dispatch<React.SetStateAction<string | null>>;
  filterRange: [Date | null, Date | null];
  setFilterRange: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>;
  fetchingConversations: boolean;
  selectedIds: string[];
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const LeftColumn: React.FC<LeftColumnProps> = ({
  conversations,
  categoryIcons,
  selectedConversationPreview,
  setSelectedConversationPreview,
  handleShowModalCarga,
  handleShowModalMensaje,
  exportToExcel,
  gojimx_businessId,
  gojimx_token,
  filterFecha,
  setFilterFecha,
  filterEstado,
  setFilterEstado,
  filterRenataState,
  setFilterRenataState,
  filterCategory,
  setFilterCategory,
  filterRange,
  setFilterRange,
  fetchingConversations,
  selectedIds,
  setSelectedIds,
}) => {
  // State variables specific to LeftColumn
  const [searchTerm, setSearchTerm] = useState('');
  const selectedConversations = conversations.filter(conversation => selectedIds.includes(conversation.id));
  const conversationListRef = useRef<HTMLDivElement>(null);

  // Function to handle search input change
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  // Function to handle selecting a conversation
  const handleSelectConversation = (id: string) => {
    setSelectedIds(prevSelectedIds => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(selectedId => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  // Function to select or deselect all conversations
  const selectAll = () => {
    if (filteredConversations.length === selectedIds.length) {
      setSelectedIds([]);
    } else {
      const allIds = filteredConversations.map(conversation => conversation.id);
      setSelectedIds(allIds);
    }
  };

  // Function to determine if a conversation is active
  const isActive = (fechaStr: string): boolean => {
    const fecha = new Date(fechaStr);
    const fechaActual = new Date();
    const diferencia = fechaActual.getTime() - fecha.getTime();
    const horas = diferencia / (1000 * 60 * 60);
    return horas <= 24;
  };

  // Filtering conversations based on search term and filters
  const now = new Date();
  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - now.getDay() + 1);
  startOfWeek.setHours(0, 0, 0, 0); // Set to the beginning of the day for accurate comparison

  const filteredConversations = conversations.filter(conversation => {
    const matchesSearchTerm =
      conversation.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      conversation.user_phone.includes(searchTerm);

    // Determine if the conversation matches the active state filter
    let matchesEstadoFilter = true; // Assume true by default
    if (filterEstado) {
      if (filterEstado === 'Activas') {
        matchesEstadoFilter = isActive(conversation.last_message_date);
      } else if (filterEstado === 'Inactivas') {
        matchesEstadoFilter = !isActive(conversation.last_message_date);
      }
    }

    // Existing logic for date filtering
    const conversationDate = new Date(conversation.last_message_date);
    let matchesDateFilter = true; // Initialize as true by default

    if (filterFecha === 'hoy') {
      matchesDateFilter = conversationDate.toDateString() === now.toDateString();
    } else if (filterFecha === 'esta semana') {
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Sunday)
      matchesDateFilter = conversationDate >= startOfWeek && conversationDate <= endOfWeek;
    } else if (filterFecha === 'este mes') {
      matchesDateFilter = conversationDate.getMonth() === now.getMonth() && conversationDate.getFullYear() === now.getFullYear();
    } else if (filterFecha === 'este año') {
      matchesDateFilter = conversationDate.getFullYear() === now.getFullYear();
    } else if (filterFecha === 'rango' && filterRange[0] && filterRange[1]) {
      matchesDateFilter = conversationDate >= filterRange[0] && conversationDate <= filterRange[1];
    }

    //     switch (filterFecha) {
    //       case 'hoy':
    //         matchesDateFilter = conversationDate.toDateString() === now.toDateString();
    //         break;
    //       case 'esta semana':
    //         const endOfWeek = new Date(startOfWeek);
    //         endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Sunday)
    //         matchesDateFilter = conversationDate >= startOfWeek && conversationDate <= endOfWeek;
    //         break;
    //       case 'este mes':
    //         matchesDateFilter = conversationDate.getMonth() === now.getMonth() && conversationDate.getFullYear() === now.getFullYear();
    //         break;
    //       case 'este año':
    //         matchesDateFilter = conversationDate.getFullYear() === now.getFullYear();
    //         break;
    //       case "rango" && filterRange[0] && filterRange[1])
    //       default:
    // break;
    //     }


    // Filtering based on Renata's sleep state
    let matchesRenataState = true; // Assume true if not filtering by Renata state
    if (filterRenataState === 'renata_sleep') {
      matchesRenataState = conversation.renata_sleep === true;
    } else if (filterRenataState === 'renata_on') {
      matchesRenataState = conversation.renata_sleep !== true;
    }

    // Filtering based on Category
    let matchesCategory = true; // Assume true if not filtering by Category
    if (filterCategory != null) {
      matchesCategory = conversation.category === filterCategory;
    }

    let activeConversation = true;
    if (!conversation.active_conversation) {
      activeConversation = false;
    }

    return (
      matchesSearchTerm &&
      activeConversation &&
      matchesDateFilter &&
      matchesEstadoFilter &&
      matchesRenataState &&
      matchesCategory
    );
  });

  return (
    <div
      className='col-md-6'
      style={{
        height: 'calc(100vh - 80px)',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >      {/* Header Section */}
      <div className='row px-3 animate__animated animate__fadeIn d-flex' style={{ flexShrink: 0 }}>
        <div className="col-9">
          <h1 className='fw-bold text-dark mt-0'>Mis Conversaciones</h1>
        </div>
        <div className="col-3 d-flex justify-content-end">
          <div className='btn btn-light float-end me-3' title="Cargar conversaciones" onClick={handleShowModalCarga}>
            <i className="icon-upload bi bi-cloud-upload"></i>
          </div>
          <div className='btn btn-light float-end' title="Exportar conversaciones" onClick={() => exportToExcel(conversations)}>
            <i className="icon-upload bi bi-file-earmark-arrow-down"></i>
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className='row animate__animated animate__fadeIn d-flex' style={{ flexShrink: 0 }}>
        <div className='w-100 my-3'>
          <div className='border animate__animated animate__fadeIn'></div>
        </div>
      </div>

      {/* Advanced Filters */}
      <div className='row animate__animated animate__fadeIn d-flex' style={{ flexShrink: 0 }}>
        <AdvancedFilters
          selectedFecha={filterFecha}
          setSelectedFecha={setFilterFecha}
          selectedEstado={filterEstado}
          setSelectedEstado={setFilterEstado}
          selectedRenataState={filterRenataState}
          setSelectedRenataState={setFilterRenataState}
          selectedCategory={filterCategory}
          setSelectedCategory={setFilterCategory}
          selectedRange={filterRange}
          setSelectedRange={setFilterRange}
        />
      </div>

      {/* Conversation Section */}
      <div
        className="bg-gray rounded mt-4"
        style={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          position: 'relative', // Add this line
          overflow: 'hidden'
        }}
      >    {/* Conversation Header */}
        <div style={{ flexShrink: 0 }}>
          <div className='row px-4 animate__animated animate__fadeIn mb-3 mt-3'>
            <h5 className='fw-bold text-dark me-3 mb-3'>Conversaciones</h5>
            <div className='col-9 ps-0'>
              <input
                type="text"
                className={'form-control bg-white mx-2'}
                placeholder='Buscar...  '
                onChange={handleSearchChange}
              />
            </div>
            <div className='col-3'>
              <button className='btn btn-light w-100 border-black' onClick={selectAll}>
                Seleccionar todo
              </button>
            </div>
          </div>

          {(filteredConversations.length === 0 && !fetchingConversations) && (
            <div className='d-flex justify-content-center mt-5'>
              <h5 className='fw-bold text-dark mb-0 me-3'>No se han encontrado conversaciones</h5>

            </div>
          )}

          {(filteredConversations.length === 0 && fetchingConversations) && (
            <div className='d-flex justify-content-center'>
              <h1 style={{ fontWeight: 'bold' }}>
                <img className='loading' src={loadingGif} alt="Cargando..." />
              </h1>
            </div>
          )}

          {(filterCategory != null && filteredConversations.length > 0) && (
            <div className='row w-100 mx-2 p-2 animate__animated animate__fadeIn'>
              <h3 className='fw-bold'>{categoryIcons[filterCategory]} {filterCategory}</h3>
            </div>
          )}
        </div>

        {/* Conversation List */}

        <div
          className={`mx-3 p-3 conversation-list ${selectedIds.length !== 0 ? 'with-button' : 'no-button'
            }`}
          style={{ flexGrow: 1, overflowY: 'auto' }}
        >
          {filteredConversations.length > 0 && filteredConversations.map((conversation_item, index) => (
            <div
              key={index}
              className={`row p-3 conversation-item animate__animated animate__fadeIn
                ${selectedConversationPreview === conversation_item ? 'bg-black' : 'bg-white'} 
                ${selectedIds.includes(conversation_item.id) ? 'selected-item' : ''} 
                ${index === 0 ? 'rounded-top' : ''} 
                ${index === filteredConversations.length - 1 ? 'rounded-bottom' : ''}`}
            >
              <div
                onClick={() => {
                  setSelectedConversationPreview(conversation_item);
                }}
                className="col-11 d-flex flex-wrap align-items-center"
              >
                <h5 className={`${isActive(conversation_item.last_message_date) ? 'text-success' : 'text-secondary'} mb-0 fw-bolder me-3`}>
                  <i className='bi bi-circle-fill'></i>
                </h5>
                <h5 className={`${selectedConversationPreview === conversation_item ? 'text-white' : 'text-black'} fw-bold text-dark mb-0 me-3`}>
                  {conversation_item.user_name}
                </h5>
                <h5 className={`${selectedConversationPreview === conversation_item ? 'text-white text-secondary' : 'text-secondary'} mb-0 fw-bolder`}>
                  {formatPhoneNumber(conversation_item.user_phone)}
                </h5>
                <h5 className='text-secondary mb-0 ms-3 fw-bolder'>
                  {conversation_item.renata_sleep ? "💤" : ""}
                </h5>
                {filterCategory == null && (
                  <h5 className='text-secondary mb-0 fw-bolder'>
                    {categoryIcons[conversation_item.category]}
                  </h5>
                )}
              </div>
              <div className="col-1" onClick={() => { handleSelectConversation(conversation_item.id); }}>
                <h4 className="lead mt-1">
                  <i className={
                    selectedIds.includes(conversation_item.id)
                      ? selectedConversationPreview === conversation_item
                        ? 'bi bi-check-circle-fill text-white'
                        : 'bi bi-check-circle-fill text-black'
                      : 'hide bi bi-circle'
                  }></i>                </h4>
              </div>
            </div>
          ))}
        </div>

        <TransitionGroup>
          {selectedIds.length !== 0 && (
            <CSSTransition timeout={300} classNames="fade" unmountOnExit>
              <div
                className="text-end pe-4"
                style={{
                  flexShrink: 0,
                  position: 'absolute',
                  bottom: '20px', /* Adjust as needed */
                  right: '0',
                  width: '100%',
                }}
              >
                <div
                  className="btn btn-lg btn-dark px-3 rounded"
                  onClick={handleShowModalMensaje}
                >
                  <p className="lead py-0 mb-0">
                    <i className="bi bi-whatsapp me-2"></i> Enviar Mensaje
                  </p>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </div >
  );
};

export default LeftColumn;