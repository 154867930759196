import React, { useState, useEffect } from 'react';
import '../../css/main.css';
import '../../css/modal.css';
import Menu from '../Menu/Menu';
import TopBar from '../../TopBar';
import loadingGif from '../../img/loading.gif';
import loadingRenataLoader from '../../img/LOADER RENATA.gif';
import CreatableSelect from 'react-select/creatable';

import { Button, Col, Form, Modal, Row, } from 'react-bootstrap';
import axios from 'axios';
import { fetchBusinessStatus, handleDeductionTransaction } from '../../functions/functions';
import ReactSelect, { ActionMeta, MultiValue, OnChangeValue } from 'react-select';
import makeAnimated from 'react-select/animated';
import Logo from '../../img/renata-settings.png';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../Modals/ConfirmationModal';
import config from '../../config/config';
import '../../css/canales.css';
import ChannelTab from '../../Components/Settings/Channel';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Tooltip } from 'react-tooltip'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import MySkills from '../../MySkills';
import UsersModal from './UsersModal';
import { Slider } from '@mui/material';
import SupportChatModal from '../Home/SupportChatModal';
import FileModal from '../Modals/FileModal';
import AddBaseModal from '../Modals/AddBaseModal';
import ConfirmDeleteBaseModal from '../Modals/ConfirmDeleteBaseModal';
import ConfirmDeleteAssistantModal from '../Modals/ConfirmDeleteAssistantModal';
import AddCategoriaModal from '../Modals/AddCategoriaModal';
import Categories from './Categories';
import CreateAssistantModal from '../Modals/CreateAssistantModal';
import EditAssistantModal from '../Modals/EditAssistantModal';
import KnowledgeTab from './KnowledgeTab';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js`;

interface Option {
  option_name: string,
  description: string,
  icon: string
}

interface Category {
  category_name: string,
  options: Array<Option>;
}

interface OptionType {
  value: string;
  label: string;
}

interface Assistant {
  assistant_id: string;
  name: string;
  description: string;
  intelligenceLevel: string;
  responseLength: string;
  workingHoursStart: number;
  workingHoursEnd: number;
  responseSpeed: string;
  instructions: string;
  active: boolean;
  active_skills: ActiveSkill[];
  assistant_knowledge_base: String;
  whitelist: string[];
}

interface ActiveSkill {
  skill_id: string,
  skill_name: string
}

interface Channel {
  channel_name: string;
  active: boolean;
  username: string;
  main_number: boolean,
  baileys_port: string,
  baileys_status: boolean,
  channel_type: string
}

//Knowledge
interface Base {
  _id: string,
  knowledge_base_name: string,
  knowledge_base_nickname: string,
  baseName: string,
  archivos: Array<Files>,
  stat: string;
}
interface Files {
  file: string,
  file_url: string
}

interface SkillProperties {
  [key: string]: any; // Allows any structure within skill_properties
}


interface Skill {
  skill_name: string;
  skill_description: string;
  skill_id: string;
  skill_type: string;
  skill_properties: SkillProperties;
}

function Settings() {
  const navigate = useNavigate();

  /* Set app data */
  const gojimx_token = localStorage.getItem('gojimx_token');
  const gojimx_phone = localStorage.getItem('gojimx_phone');
  const gojimx_username = localStorage.getItem('gojimx_username');
  const gojimx_businessId = localStorage.getItem('gojimx_businessId');
  const gojimx_business_name = localStorage.getItem('gojimx_business_name');
  const gojimx_assistant_id = localStorage.getItem('gojimx_assistant_id');
  const gojimx_email = localStorage.getItem('gojimx_email');
  const gojimx_payment_type = localStorage.getItem('gojimx_payment_type');
  const [mainAssistant, setMainAssistant] = useState<Assistant | null>(null);
  const [predefinedQuestion, setPredefinedQuestion] = useState<string>();



  const [supportChatOpen, setSupportChatOpen] = useState(false);
  const handleSupportChatOpen = (question: string) => {
    setPredefinedQuestion(question);
    setSupportChatOpen(true);
  };
  const handleSupportChatClose = () => setSupportChatOpen(false);

  /*Tab State */
  const [activeTab, setActiveTab] = useState('Cuenta');

  const [changed_use_cases, setChangedUseCases] = useState(false);
  const [changedSkills, setChangedSkillS] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [whatsapp_number, setWhatsapp_number] = useState('');
  const [whatsapp_businessID, setWhatsapp_businessID] = useState('');
  const [renataID, setRenataID] = useState('');
  const [consultorio, setConsultorio] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [correo, setCorreo] = useState('');

  const [horario, setHorario] = useState('');
  const [contextoAdicional, setContextoAdicional] = useState('');
  const [selectedWhiteList, setSelectedWhiteList] = useState<MultiValue<OptionType>>([]);

  /* Modales */
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [show, setShow] = useState(false);
  const [showAddCategoriaModal, setShowAddCategoriaModal] = useState(false);
  const [confirmationModalText, setConfirmationModalText] = useState('Los datos han sido guardados correctamente');
  const [showEditAsistenteModal, setShowEditAsistenteModal] = useState(false);
  const [showCreateAsistenteModal, setShowCreateAsistenteModal] = useState(false);
  const [showModalConfirmDeleteAssistant, setConfirmDeleteAssistant] = useState(false);
  const [showModalConfirmDeleteBase, setConfirmDeleteBase] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState('✅')
  const [confirmModalText, setConfirmModalText] = useState('Resultado de la Operación')
  const [confirmationModalOnHide, setConfirmationModalOnHide] = useState<(() => void) | undefined>(undefined);

  /*Knowledge Modals*/
  const [showAddBaseModal, setShowAddBaseModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [modalPdfUrl, setModalPdfUrl] = useState<string | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  /* Categories State */
  const [categorias, setCategorias] = useState<Category[]>([]);
  const [originalOptionName, setOriginalOptionName] = useState('');
  const [newOptionName, setNewOptionName] = useState('');
  const [newOptionDescription, setNewOptionDescription] = useState('');
  const [newOptionIcon, setNewOptionIcon] = useState('');
  const [titleModalAdd, setTitleModalAdd] = useState('');
  const [editAsistente, setEditAsistente] = useState<Assistant | null>(null);
  const handleNewOptionNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => setNewOptionName(event.target.value);
  const handleNewOptionDescriptionChange = (event: { target: { value: React.SetStateAction<string>; }; }) => setNewOptionDescription(event.target.value);
  const handleNewOptionIconChange = (event: { target: { value: React.SetStateAction<string>; }; }) => setNewOptionIcon(event.target.value);
  const [updatingAssistant, setUpdatingAsisstant] = useState(false);
  const [savingAssistant, setSavingAssistant] = useState(false);

  /* Assistant data */
  const [intelligenceLevel, setIntelligenceLevel] = useState('medio');
  const [responseLength, setResponseLength] = useState('medio');
  const [workingHours, setWorkingHours] = useState('24h');
  const [responseSpeed, setResponseSpeed] = useState('1');
  const [skills, setSkills] = useState<Skill[]>([]);
  const [options, setOptions] = useState<Base[]>([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [loadingCost, setLoadingCost] = useState(false);
  const [assistantCost, setAssistantCost] = useState('');

  // Function to convert slider value to string
  const [instructions, setInstructions] = useState('Lorem ipsum dolor sit amet...');
  const [workingHoursStart, setWorkingHoursStart] = useState('0');
  const [workingHoursEnd, setWorkingHoursEnd] = useState('24');
  const [newAsistenteName, setAssistantName] = useState('');
  const [newAsistenteDescription, setAssistantDescription] = useState('');
  const [asistentes, setAsistentes] = useState<Assistant[]>([]);

  const [isButtonDisabledNew, setIsButtonDisabledNew] = useState(true);
  const [isButtonDisabledModify, setIsButtonDisabledModify] = useState(false);

  const [channels, setChannels] = useState<Channel[]>([]);
  const [isTextImproving, setIsTextImproving] = useState(false);

  /* Knowledge Data */
  const [basesConocimiento, setBases] = useState<Base[]>([]);
  const [selectedBase, setSelectedBase] = useState<Base | null>(null);
  const [baseName, setBaseName] = useState('');
  const [originalBaseName, setOriginalBaseName] = useState('');
  const [selectedFile, setSelectedFile] = useState<Files | null>(null);
  const [selectedFilesModal, setSelectedFilesModal] = useState<File[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const handleBaseNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => setBaseName(event.target.value);
  const [sizeResponse, setSizeResponse] = useState('2');
  const [wordNumber, setWordNumber] = useState('150');
  const [fileDate, setFileDate] = useState('');
  const [totalSize, setTotalSize] = useState(0);
  const maxFileSize = 50 * 1024 * 1024;
  const maxTotalSize = 50 * 1024 * 1024;
  //Delete
  const [deleteInfo, setDeleteInfo] = useState<{ file: string | null, baseName: string | null }>({ file: null, baseName: null });
  const [selectedFilesDelete, setSelectedFilesDelete] = useState<string[]>([]);
  const [isSelectMode, setIsSelectMode] = useState(false); // Estado para alternar entre modos
  const [baseAssistantId, setBaseAssistantId] = useState('');
  //Sync
  const [isSyncing, setIsSyncing] = useState(false);
  //Tokens
  const [knowledgeTokens, setTokens] = useState(0);
  const [tokenPoints, setTokenPoints] = useState(0);
  const [refreshTopbar, setRefreshTopbar] = useState(false);
  const ganancia = 0.65;
  const valorTokenInput = 0.0000005;
  const multiply = 20;
  const precioGojiPoints = 0.0001;

  const GPT4oMiniOutputThousandTokensValue = 0.0015;
  const USDtoRenataPoints = 1000;


  //Webscrap
  const [webScrapName, setWebScrapName] = useState('');
  const handleWebScrapName = (event: { target: { value: React.SetStateAction<string>; }; }) => setWebScrapName(event.target.value);
  const [inputValue, setInputValue] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const handleFocus = () => {
    setIsExpanded(true);
  };

  const handleBlur = () => {
    if (!inputValue) {
      setIsExpanded(false);
    }
  };

  /* Users */
  const [usersModal, setUsersModal] = useState(false);

  const handleUsersOpenModal = () => {
    setUsersModal(true);
  };

  const handleUsersCloseModal = () => {
    setUsersModal(false);
  };

  const toggleSelectMode = () => {
    setIsSelectMode(!isSelectMode);
    setSelectedFilesDelete([]);
  };

  const handleChangeWhiteList = (newValue: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    const filteredValue = newValue.filter(option => /^\d+$/.test(option.value));
    setSelectedWhiteList(filteredValue);
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const handleChangePasswordClick = () => {
    navigate('/change-password');
  };

  const handleChangeMainAssistant = async (assistantId: string) => {
    try {
      setUpdatingAsisstant(true)
      const response = await axios.post(`${config.backendURL}/updateSelectedAssistant`, {
        businessId: gojimx_businessId,
        assistantId: assistantId
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        }
      });
      if (response.status === 200) {
        fetchBusiness(); // Actualiza la lista de asistentes
      }
    } catch (error) {
      console.error('Error seleccionando asistente principal:', error);
      setConfirmationModalText('Ocurrió un error al cambiar el asistente principal');
      setConfirmModalType('❌')
      setShowConfirmationModal(true);
    } finally {
      setTimeout(() => {
        setConfirmationModalText('Asistente principal seleccionado correctamente');
        setConfirmModalType('✅')
        setShowConfirmationModal(true);
        setUpdatingAsisstant(false);
      }, 1000);
    }
  };


  const fetchBusiness = async () => {
    try {
      const response = await axios.get(`${config.backendURL}/get_business/${gojimx_businessId}`, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`
        }
      });
      const businessData = response.data[0];
      // localStorage.setItem('gojimx_businessId', businessData.wa_phone_id);
      localStorage.setItem('gojimx_assistant_id', businessData.assistant_id);

      setAsistentes(businessData.assistants);
      setChannels(businessData.channels);
      const fetchedSkills: Skill[] = businessData.skills;
      setSkills(fetchedSkills);
    } catch (error) {
      console.error('Error fetching business status:', error);
    }
  };


  useEffect(() => {
    if (asistentes) {
      const activeAssistant = asistentes.find(asistente => asistente.assistant_id === gojimx_assistant_id);
      setMainAssistant(activeAssistant ? activeAssistant : null);
    }
  }, [asistentes])

  useEffect(() => {
    if (gojimx_businessId && gojimx_token) {
      fetchBusiness()
    }
  }, [gojimx_businessId, gojimx_token]);


  const getSpeedLabel = (value: string) => {
    switch (value) {
      case '0':
        return 'lenta';
      case '1':
        return 'media';
      case '2':
        return 'rápida';
      default:
        return 'media';
    }
  };

  const getIntelligenceValue = (value: string) => {
    switch (value) {
      case '😊':
        return 'media';
      case '😎':
        return 'alta';
      default:
        return 'media';
    }
  };

  const handleCreateAsistente = () => {
    setAssistantName('');
    setSelectedWhiteList([]);
    setAssistantDescription('');
    setIntelligenceLevel('medio');
    setResponseLength('medio');
    setWorkingHoursStart('0');
    setWorkingHoursEnd('24');
    setResponseSpeed('1');
    setInstructions('');
    setShowCreateAsistenteModal(true);
    setIsButtonDisabledNew(true);
    setSelectedSkill([]);
  };

  const handleSaveNewAsistente = async () => {
    if (!newAsistenteName || !newAsistenteDescription || !intelligenceLevel || !responseLength || !workingHoursStart || !workingHoursEnd || !responseSpeed || !instructions) {
      setShowCreateAsistenteModal(false);
      setConfirmationModalText('Por favor, complete todos los campos antes de guardar');
      setConfirmModalType('❌')
      const reopenCreateAssistantModal = () => {
        setShowCreateAsistenteModal(true);
        setShowConfirmationModal(false);
      };
      setConfirmationModalOnHide(() => reopenCreateAssistantModal);

      setShowConfirmationModal(true);
      return;
    }
    const newAssistantData = {
      name: newAsistenteName,
      description: newAsistenteDescription,
      intelligenceLevel: intelligenceLevel,
      responseLength,
      workingHoursStart,
      workingHoursEnd,
      responseSpeed: getSpeedLabel(responseSpeed),
      instructions,
      active: false,
      knowledge_base_name: selectedValue,
      skills: selectedSkill,
      whitelist: selectedWhiteList

    };

    try {
      setSavingAssistant(true);
      const response = await axios.post(`${config.backendURL}/add_assistant`, {
        businessId: gojimx_businessId,
        assistant: newAssistantData
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        }
      });

      if (response.status === 200) {
        setShowCreateAsistenteModal(false);
        setConfirmationModalText('Se ha creado su asistente');
        setConfirmModalType('✅')
        setShowConfirmationModal(true);

      }
    } catch (error: any) {
      console.error('Error creating new assistant:', error);
      setShowCreateAsistenteModal(false);
      if (error.response.status === 409) {
        setConfirmationModalText('Máximo número de asistentes creados para su tipo de cuenta');

      } else {
        setConfirmationModalText('No se ha podido crear el asistente, intente más tarde');

      }
      setConfirmModalType('❌');
      setShowConfirmationModal(true);

    } finally {
      setSavingAssistant(false)
      fetchBusiness();
      setConfirmationModalOnHide(() => undefined);

    }
  };

  const handleCloseCreateAsistenteModal = () => {
    setShowCreateAsistenteModal(false);
    setIsButtonDisabledNew(true);
  };


  const addNewOptionToDefaultCategory = async () => {
    setIsLoading(true); // Use your existing loading state to indicate loading

    try {
      const response = await axios.post(`${config.backendURL}/add_option_to_category/${gojimx_businessId}`, {
        option_name: newOptionName,
        description: newOptionDescription,
        icon: newOptionIcon
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        }
      });

      if (response.status === 200) {
        // Success - Handle accordingly, maybe refresh the categories list
        handleCloseAddCategoriaModal()
        setConfirmationModalText('Se ha agregado la categoría correctamente');
        setConfirmModalType('✅');
        setShowConfirmationModal(true);
        fetchCategories(); // Assuming this function fetches the updated list of categories
      }
    } catch (error) {
      setConfirmationModalText('No se ha podido agregar la categoría, intente de nuevo');
      setConfirmModalType('❌')
      setShowConfirmationModal(true);
    }

    setIsLoading(false);
  };


  const handleEditAsistente = (asistente: Assistant) => {
    setEditAsistente(asistente);
    console.log(asistente);
    setSelectedWhiteList(asistente.whitelist.map((number) => ({
      value: number,
      label: number,
    })))
    setAssistantName(asistente.name)
    setAssistantDescription(asistente.description)
    setIntelligenceLevel(asistente.intelligenceLevel);
    setResponseLength(asistente.responseLength);
    setWorkingHoursStart(asistente.workingHoursStart.toString());
    setWorkingHoursEnd(asistente.workingHoursEnd.toString());
    setResponseSpeed(asistente.responseSpeed === 'lenta' ? '0' : asistente.responseSpeed === 'media' ? '1' : '2');
    setInstructions(asistente.instructions);
    setSelectedSkill(asistente.active_skills.map((skill) => ({
      value: skill.skill_id,
      label: skill.skill_name,
    })))
    setShowEditAsistenteModal(true);
    if (asistente.instructions)
      setIsButtonDisabledModify(false);
    else {
      setIsButtonDisabledModify(true);
    }
    setSelectedValue(String(asistente.assistant_knowledge_base));
    setLoadingCost(true);
    axios.get(`${config.backendURL}/calculate_assistant_cost`, {
      params: {
        assistant_id: asistente.assistant_id,
        intelligenceLevel: asistente.intelligenceLevel,

      },
      headers: {
        'Authorization': `Bearer ${gojimx_token}`
      }
    })
      .then(response => {
        const assistantCost = response.data.cost;
        setAssistantCost(response.data.cost);
      })
      .catch(error => {
        setAssistantCost('--');
      }).finally(() => {
        setLoadingCost(false);
      });
  };

  const handleCloseEditAsistenteModal = () => {
    setShowEditAsistenteModal(false);
  };

  const handleSaveAssistantChanges = async () => {

    if (!newAsistenteName || !newAsistenteDescription || !intelligenceLevel || !responseLength || !workingHoursStart || !workingHoursEnd || !responseSpeed || !instructions) {
      setShowEditAsistenteModal(false);
      setConfirmationModalText('Por favor, complete todos los campos antes de guardar');
      setConfirmModalType('❌')
      const reopenCreateAssistantModal = () => {
        setShowEditAsistenteModal(true);
        setShowConfirmationModal(false);
      };
      setConfirmationModalOnHide(() => reopenCreateAssistantModal);
      setShowConfirmationModal(true);
      return;
    }

    const newAssistantData = {
      assistant_id: editAsistente?.assistant_id,
      name: newAsistenteName,
      description: newAsistenteDescription,
      intelligenceLevel,
      responseLength,
      workingHoursStart,
      workingHoursEnd,
      responseSpeed: getSpeedLabel(responseSpeed),
      instructions,
      active: false,
      skills: selectedSkill,
      knowledge_base_name: selectedValue,
      whitelist: selectedWhiteList
    };


    try {
      setSavingAssistant(true);

      const response = await axios.post(`${config.backendURL}/update_assistant`, {
        businessId: gojimx_businessId,
        assistant: newAssistantData
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        }
      });

      if (response.status === 200) {
        // Success - Handle accordingly, maybe refresh the assistants list
        //console.log('Assistant updated successfully:', response.data);
        setConfirmationModalText('Asistente actualizado correctamente');
        setConfirmModalType('✅');
        setShowConfirmationModal(true);
        setShowEditAsistenteModal(false);

      }
    } catch (error) {
      setShowEditAsistenteModal(false);
      setConfirmationModalText('No se ha podido actualizar el asistente, intente de nuevo');
      setConfirmModalType('❌')
      setShowConfirmationModal(true);
      setShowEditAsistenteModal(true)
    } finally {
      setSavingAssistant(false)
      fetchBusiness();
      setConfirmationModalOnHide(() => undefined);

    }
  };

  const handleDeleteAssistant = async () => {
    try {
      setSavingAssistant(true);
      if (editAsistente?.assistant_id === gojimx_assistant_id) {
        setConfirmationModalText('No puedes eliminar un asistente que se encuentra activo para responder mensajes');
        setConfirmModalType('❌')
        setShowConfirmationModal(true);
        return;
      }
      const response = await axios.post(`${config.backendURL}/delete_assistant`, {
        businessId: gojimx_businessId,
        assistant_id: editAsistente?.assistant_id
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        }
      });

      if (response.status === 200) {
        // Success - Handle accordingly, maybe refresh the assistants list
        //console.log('Assistant deleted successfully:', response.data);
        setConfirmationModalText('Asistente eliminado correctamente');
        setConfirmModalType('✅');
        setShowConfirmationModal(true);
      }
    } catch (error) {
      console.error('Error eliminando assistant:', error);
      setConfirmationModalText('No se ha podido eliminar el asistente, intente más tarde');
      setConfirmModalType('❌')
      setShowConfirmationModal(true);
      // Handle the error, maybe display a message to the user
    } finally {
      setSavingAssistant(false)
      setShowEditAsistenteModal(false);
      setConfirmDeleteAssistant(false);
      fetchBusiness();

    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    const { id } = e.target;
    setInstructions(value);
    id === 'instructions new' ? setIsButtonDisabledNew(value.trim() === '') : setIsButtonDisabledModify(value.trim() === '');
  };

  const handleTextIA = async () => {
    //console.log('Texto mejorado por IA:', instructions);
    try {
      setIsTextImproving(true);

      const response = await axios.post(`${config.backendURL}/improve-text`, {
        text: instructions,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        }
      });
      const improvedText = response.data.improvedText;
      setInstructions(improvedText);
    } catch (error) {
      console.error('Error al mejorar el texto:', error);
    } finally {
      setIsTextImproving(false);
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value);
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleCloseConfirmationModal = () => {
    if (confirmationModalOnHide) {
      confirmationModalOnHide();
      setConfirmationModalOnHide(undefined);
    } else {
      setShowConfirmationModal(false);
    }
  };

  const handleCloseAddCategoriaModal = () => {
    setShowAddCategoriaModal(false);
    setNewOptionName("");
    setNewOptionDescription("");
    setNewOptionIcon("");
  };

  const handleAddCategoria = () => {
    setTitleModalAdd("Agregar Categoría");
    setShowAddCategoriaModal(true);
  }

  const handleDuplicateAssistant = (assistant: Assistant) => {
    setAssistantName(assistant.name);
    setAssistantDescription(assistant.description);
    setIntelligenceLevel(assistant.intelligenceLevel);
    setResponseLength(assistant.responseLength);
    setWorkingHoursStart(assistant.workingHoursStart.toString());
    setWorkingHoursEnd(assistant.workingHoursEnd.toString());
    setResponseSpeed(assistant.responseSpeed === 'lenta' ? '0' : assistant.responseSpeed === 'media' ? '1' : '2');
    setInstructions(assistant.instructions);
    setShowCreateAsistenteModal(true);
  };

  const handleEditCategoria = (option_data: Option) => {
    setTitleModalAdd("Editar Categoría");
    setShowAddCategoriaModal(true);
    setOriginalOptionName(option_data.option_name);

    setNewOptionName(option_data.option_name);
    setNewOptionDescription(option_data.description);
    setNewOptionIcon(option_data.icon);
  }

  const handleConsultorioChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setConsultorio(event.target.value);
  };

  const handleDoctorNameChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setDoctorName(event.target.value);
  };

  const handleEmailChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setCorreo(event.target.value);
  };


  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.patch(`${config.backendURL}/updateBusiness`, {
        business_id: whatsapp_businessID,
        new_business_name: consultorio,
        new_username: doctorName,
        new_use_case: selectedCase,
        new_email: correo
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + gojimx_token, // Replace tuTokenJWT with your actual token
        }
      });

      if (response.status === 200) {
        setConfirmModalType('✅');
        setConfirmationModalText('Los datos han sido guardados correctamente');
        setShowConfirmationModal(true);
        setChangedUseCases(false);
        localStorage.setItem('gojimx_business_name', consultorio);
        localStorage.setItem('gojimx_username', doctorName);
        localStorage.setItem('gojimx_email', correo);

      }
    } catch (error) {
      // Handle error
      setConfirmationModalText('No se ha podido guardar la configuración');
      setConfirmModalType('❌');
      setShowConfirmationModal(true);
      setCorreo(gojimx_email ? gojimx_email : '');
      setDoctorName(gojimx_username ? gojimx_username : '');
      setConsultorio(gojimx_business_name ? gojimx_business_name : '');
      console.error('Error updating business name:', error);
    }

    setIsLoading(false);
  };

  const handleTabChange = (tab: React.SetStateAction<string>) => {
    setActiveTab(tab);
  };

  const fetchCategories = () => {
    axios.get(`${config.backendURL}/get_category/${gojimx_businessId}`, {
      headers: {
        Authorization: `Bearer ${gojimx_token}`
      }
    })
      .then(response => {
        setCategorias(response.data[0]);
      })
      .catch(error => {
        console.error('There was an error fetching the categories:', error);
      });
  };

  useEffect(() => {
    fetchBusinessStatus();
  }, [])

  const handleDeleteOption = async (optionName: any) => {
    setIsLoading(true);

    try {
      const response = await axios.delete(`${config.backendURL}/delete_option_from_category/${gojimx_businessId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        },
        data: { // Axios DELETE requests send data in the 'data' key
          option_name: optionName
        }
      });

      if (response.status === 200) {
        handleCloseAddCategoriaModal();
        setConfirmModalType('✅');
        setConfirmationModalText('Se ha eliminado la categoría');
        setShowConfirmationModal(true);

        fetchCategories(); // Refresh the categories from the backend or update state directly
      }
    } catch (error) {
      console.error('Error deleting option:', error);
      // Optionally handle the error, e.g., by showing an error message
    }

    setIsLoading(false);
  };

  const editOptionInDefaultCategory = async () => {
    setIsLoading(true); // Use your existing loading state to indicate loading

    try {
      const response = await axios.patch(`${config.backendURL}/edit_option_in_category/${gojimx_businessId}`, {
        original_option_name: originalOptionName,
        new_option_name: newOptionName,
        description: newOptionDescription,
        icon: newOptionIcon
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        }
      });

      if (response.status === 200) {
        // Success - Handle accordingly, maybe refresh the categories list
        handleCloseAddCategoriaModal();
        setConfirmModalType('✅');
        setConfirmationModalText('Se ha editado la categoría correctamente');
        setShowConfirmationModal(true);
        fetchCategories(); // Assuming this function fetches the updated list of categories
      }
    } catch (error) {
      setIsLoading(false);
      setConfirmModalType('❌');
      setConfirmationModalText('No se ha podido editar la categoría, intente más tarde');
      setShowConfirmationModal(true);
      // Handle the error, maybe display a message to the user
    }

    setIsLoading(false);
  };

  /*Knowledge */
  const truncateFileName = (name: string, maxLength: number) => {
    if (name.length <= maxLength) {
      return name;
    }
    const extIndex = name.lastIndexOf('.');
    const ext = extIndex !== -1 ? name.substring(extIndex) : '';
    const truncatedName = name.substring(0, maxLength - ext.length - 3);
    return `${truncatedName}...${ext}`;
  };

  const handleAddBase = () => {
    setTitleModalAdd("Añadir Base de Conocimiento");
    setShowAddBaseModal(true);
  }
  const handleEditBase = (nombre: string) => {
    setBaseName(nombre);
    setOriginalBaseName(nombre);
    setTitleModalAdd("Editar Base de Conocimiento");
    setShowAddBaseModal(true);
  }
  const handleCloseAddBaseModal = () => {
    setShowAddBaseModal(false);
    setBaseName("");
    setSelectedFilesModal([]);
    setTotalSize(0);
  };
  const handleCloseFileModal = () => {
    setShowFileModal(false);
    setSelectedFile(null);
    setModalPdfUrl(null);
  };
  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const handleSliderResponse = (e: Event, value: number | number[]) => {
    setSizeResponse(value.toString());
  };
  const handleSliderWord = (e: Event, value: number | number[]) => {
    setWordNumber(value.toString());
  };

  const handleShowFiles = async (baseName: string) => {
    try {
      //const response = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/getBusinessKnowledgeBasesFiles`, {
      const response = await axios.post(`${config.backendURL}/v1/knowledgebases/getBusinessKnowledgeBasesFiles`, {
        business_id: gojimx_businessId,
        knowledge_base_nickname: baseName
      },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${gojimx_token}`
          }
        });

      if (response.status === 200) {
        const archivoBase = response.data;
        const fecha = archivoBase.archivos[0].fecha;
        const fechaFormateada = new Date(fecha);
        const dia = fechaFormateada.getDate();
        const mes = fechaFormateada.toLocaleString('es-ES', { month: 'short' }); // Devuelve el nombre completo del mes en español
        const anio = fechaFormateada.getFullYear();
        const fechaFull = dia + '-' + mes + '-' + anio;
        setFileDate(fechaFull);
        if (archivoBase.mensaje === 'No tiene archivos relacionados') {
          archivoBase['stat'] = 'no-sync';
        } else {
          archivoBase['stat'] = 'ok';
        }
        archivoBase['baseName'] = baseName;
        setSelectedBase(archivoBase);
      }

    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setConfirmModalText('Base de conocimiento')
          setConfirmModalType('❌');
          setModalMessage('Ocurrió un error al obtener los archivos de la base, intenta de nuevo más tarde.');
          setIsModalOpen(true);
        } else if (error.request) {
          console.log('Request made but no response received:', error.request);
        } else {
          console.log('Error message:', error.message);
        }
      } else {
        console.error('An unknown error occurred:', error);
      }
    }
  };

  const handleFileClick = async (file: Files) => {
    if (isSelectMode) {
      setSelectedFilesDelete((prevSelected) =>
        prevSelected.includes(file.file)
          ? prevSelected.filter((f) => f !== file.file)
          : [...prevSelected, file.file]
      );
    } else {
      setSelectedFile(file);
      setModalPdfUrl(file.file_url);
      setShowFileModal(true);
    }
  };

  const handleFileChangeModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    let valid = true;
    let newFiles = [...selectedFilesModal];
    let newSize = totalSize;

    if (totalSize > maxTotalSize) {
      valid = false;
      setShowAddBaseModal(false);
      setConfirmModalType('⚠️');
      setModalMessage('El límite es de 25MB por Base');
      setIsModalOpen(true);
    }

    const files = Array.from(event.target.files || []);
    const validFiles = files.filter(file =>
      (file.name.endsWith('.pdf') || file.name.endsWith('.txt')) &&
      !selectedFilesModal.some(selectedFile => selectedFile.name === file.name)
    );

    if (files.length > 5 || selectedFilesModal.length > 4) {
      valid = false;
      setShowAddBaseModal(false);
      setConfirmModalType('⚠️');
      setModalMessage('El límite es de 5 archivos por Base');
      setIsModalOpen(true);
    }

    files.forEach((file) => {
      if (file.size > maxFileSize) {
        valid = false;
        setShowAddBaseModal(false);
        setConfirmModalType('⚠️');
        setModalMessage('Solo se aceptan archivos de máximo 5MB');
        setIsModalOpen(true);
      } else if (!newFiles.some((f) => f.name === file.name && f.size === file.size)) {
        newFiles.push(file);
        newSize += file.size;
      }
    });

    if (valid) {
      if (validFiles.length < files.length) {
        setShowAddBaseModal(false);
        setConfirmModalType('⚠️');
        setModalMessage('Solo se aceptan archivos PDF y TXT, y no se permiten archivos duplicados');
        setIsModalOpen(true);
      }
      setTotalSize(newSize);
      setSelectedFilesModal([...selectedFilesModal, ...validFiles]);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    const validFiles = files.filter(file =>
      (file.name.endsWith('.pdf') || file.name.endsWith('.txt')) &&
      !selectedFiles.some(selectedFile => selectedFile.name === file.name)
    );

    if (validFiles.length < files.length) {
      setShowAddBaseModal(false);
      setConfirmModalType('⚠️');
      setModalMessage('Solo se aceptan archivos PDF y TXT, y no se permiten archivos duplicados');
      setIsModalOpen(true);
    }

    setSelectedFiles([...selectedFiles, ...validFiles]);
    updateSelectedBaseStat('no-sync');
  };

  const updateSelectedBaseStat = (newStat: string) => {
    if (selectedBase) {
      const updatedBase = { ...selectedBase, stat: newStat };
      setSelectedBase(updatedBase);
    }
  };

  const removeFileModal = (index: number) => {
    const fileToRemove = selectedFilesModal[index];
    if (fileToRemove) {
      setSelectedFilesModal(selectedFilesModal.filter((_, i) => i !== index));
      setTotalSize((prevSize) => prevSize - fileToRemove.size);
    }
  };

  const removeFile = (index: number) => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    if (selectedFiles.length === 1) {
      updateSelectedBaseStat('ok');
    }
  };

  const fetchBases = async (isEdit?: boolean) => {

    try {
      const response = await axios.post(`${config.backendURL}/v1/knowledgebases/getBusinessKnowledgeBases`, {
        business_id: gojimx_businessId
      },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${gojimx_token}`
          }
        });

      if (response.status === 200) {
        if (response.data.mensaje === "No tiene bases de conocimiento relacionadas") {
          console.log('No hay bases');
          setSelectedBase(null);
          setBases([]);
          setOptions([]);
        } else {
          const baseData = response.data.bases;
          setOptions(baseData);
          setBases(baseData);
          if (baseData.length > 0 && isEdit === undefined) {
            handleShowFiles(baseData[0].knowledge_base_nickname);
          }
        }
      }

    } catch (error) {
      setConfirmModalText('Base de conocimiento')
      if (axios.isAxiosError(error)) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            console.log('Error de respuesta:', error.response);
          } else if (error.request) {
            console.log('Se hizo el request pero no hubo respuesta:', error.request);
          } else {
            console.log('Error:', error.message);
          }
        } else {
          console.error('An unknown error occurred:', error);
        }
      } else {
        setConfirmModalType('❌');
        setModalMessage('Ocurrió un error al cargar tus bases de conocimiento, intenta de nuevo más tarde.');
        setIsModalOpen(true);
        console.error('Error:', error);
      }
    }
  };

  const getTokenEstimation = async () => {
    if (selectedFilesModal.length === 0) {
      setShowAddBaseModal(false);
      setConfirmModalType('⚠️');
      setConfirmModalText('Aviso')
      setModalMessage('Selecciona al menos un archivo');
      setIsModalOpen(true);
      return;
    }
    if (baseName === '') {
      setShowAddBaseModal(false);
      setConfirmModalType('⚠️');
      setConfirmModalText('Aviso')
      setModalMessage('Ingresa el nombre de la Base');
      setIsModalOpen(true);
      return;
    }
    if (baseName === '') {
      setShowAddBaseModal(false);
      setConfirmModalType('⚠️');
      setConfirmModalText('Aviso')
      setModalMessage('Ingresa el nombre de la Base');
      setIsModalOpen(true);
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    if (selectedFilesModal.length === 1) {
      /* Single File */
      try {
        formData.append('file', selectedFilesModal[0]);
        //const response = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/getTokenEstimation`, formData,
        const response = await axios.post(`${config.backendURL}/v1/knowledgebases/getTokenEstimation`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${gojimx_token}`
            }
          });

        if (response.status === 200) {
          const responsePoints = await axios.post(`${config.backendURL}/getRenataPoints`, {
            businessId: gojimx_businessId
          },
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${gojimx_token}`
              }
            });
          setTokenPoints(Number(responsePoints.data.renata_points));

          const tokens = response.data.data.tokens;
          const usageInput = (tokens * (GPT4oMiniOutputThousandTokensValue / 400));
          const usage_ammount = usageInput * USDtoRenataPoints;
          setTokens(usage_ammount);
          setShowAddBaseModal(false);
          setConfirmModalType('ℹ️');
          setConfirmModalText('Estimación');
          setModalMessage("Los puntos necesarios para subir el archivo son: " + (usage_ammount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
          setIsModalOpen(true);
        }

      } catch (error) {
        setShowAddBaseModal(false);
        setConfirmModalText('Base de conocimiento')
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const getError = error.response.data.error;
            if (getError === '[Renata-Backend] Knowledge Base Error: File is Empty') {
              setConfirmModalType('⚠️');
              setConfirmModalText('Aviso')
              setModalMessage('El archivo que intentas subir esta vacio');
              setIsModalOpen(true);
            }
            else {
              setConfirmModalType('❌');
              setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
              setIsModalOpen(true);
            }
          } else if (error.request) {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
            setIsModalOpen(true);
            console.log('Request made but no response received:', error.request);
          } else {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
            setIsModalOpen(true);
            console.log('Error message:', error.message);
          }
        } else {
          setConfirmModalType('❌');
          setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
          setIsModalOpen(true);
          console.error('An unknown error occurred:', error);
        }
      }
    } else {
      /* Multiple File */
      try {
        formData.append('business_id', whatsapp_businessID);
        formData.append('knowledge_base_nickname', baseName);
        selectedFilesModal.forEach(file => {
          formData.append('files', file);
        });
        //const response = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/getTokenEstimationMultiFile`, formData,
        const response = await axios.post(`${config.backendURL}/v1/knowledgebases/getTokenEstimationMultiFile`, formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${gojimx_token}`
            }
          });

        if (response.status === 200) {
          // const tokens = response.data.data.tokens;
          // const usageInput = ((tokens / (1 - ganancia)) * valorTokenInput) * multiply;
          // const usage_ammount = usageInput / precioGojiPoints;

          const tokens = response.data.data.tokens;
          const usageInput = (tokens * (GPT4oMiniOutputThousandTokensValue / 400));
          const usage_ammount = usageInput * USDtoRenataPoints;
          setTokens(usage_ammount);
          setShowAddBaseModal(false);
          setConfirmModalType('ℹ️');
          setConfirmModalText('Estimación')
          setModalMessage("Los puntos necesarios para subir el archivo son: " + (usage_ammount).toFixed(2));
          setIsModalOpen(true);
        }

      } catch (error) {
        setShowAddBaseModal(false);
        setConfirmModalText('Base de conocimiento')
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const getError = error.response.data.error;
            if (getError === '[Renata-Backend] Knowledge Base Error: File is Empty') {
              setConfirmModalType('⚠️');
              setConfirmModalText('Aviso')
              setModalMessage('El archivo que intentas subir esta vacio');
              setIsModalOpen(true);
            }
            else {
              setConfirmModalType('❌');
              setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
              setIsModalOpen(true);
            }
          } else if (error.request) {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
            setIsModalOpen(true);
            console.log('Request made but no response received:', error.request);
          } else {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
            setIsModalOpen(true);
            console.log('Error message:', error.message);
          }
        } else {
          setConfirmModalType('❌');
          setModalMessage('Ocurrió un error al calcular los tokens para el archivo, intenta de nuevo más tarde.');
          setIsModalOpen(true);
          console.error('An unknown error occurred:', error);
        }
      }
    }
    setIsLoading(false);
  };

  const addNewBase = async () => {
    setIsLoading(true);
    setRefreshTopbar((prev) => !prev);
    setConfirmModalText('Base de conocimiento');
    if (basesConocimiento.length === 3) {
      setConfirmModalType('⚠️');
      setConfirmModalText('Aviso');
      setModalMessage('Alcanzaste el número máximo de bases de conocimiento.');
      setIsModalOpen(true);
    } else if (knowledgeTokens > tokenPoints) {
      setConfirmModalType('⚠️');
      setConfirmModalText('Aviso')
      setModalMessage('No tienes suficientes puntos para añadir esta base de conocimiento');
      setIsModalOpen(true);
    } else {
      const estimation = handleDeductionTransaction(knowledgeTokens);
      const estimationState = (await estimation).message;
      if (estimationState === 'transaction saved successfully') {

        const formData = new FormData();
        if (selectedFilesModal.length === 1) {
          /* Single File */
          try {
            formData.append('file', selectedFilesModal[0]);
            formData.append('business_id', whatsapp_businessID);
            formData.append('knowledge_base_nickname', baseName);
            formData.append('num_answer', sizeResponse);
            formData.append('custom_chunck_size', wordNumber);
            //const response_base = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/createKnowledgeBase`, formData,
            const response_base = await axios.post(`${config.backendURL}/v1/knowledgebases/createKnowledgeBase`, formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${gojimx_token}`
                }
              });

            if (response_base.status === 200) {
              setConfirmModalType('✅');
              setConfirmModalText('Base de conocimiento')
              setModalMessage('La base de conocimiento ha sido creada correctamente');
              setIsModalOpen(true);
              handleCloseAddBaseModal();
              fetchBases();
            }

          } catch (error) {
            if (axios.isAxiosError(error)) {
              if (error.response) {
                const getError = error.response.data.error;
                handleCloseAddBaseModal()
                setConfirmModalText('Base de conocimiento')
                if (getError === '[Renata-Backend] Knowledge Base Error: Max Knowledge Bases reached!!') {
                  setConfirmModalType('⚠️');
                  setModalMessage('Alcanzaste el número máximo de bases de conocimiento.');
                  setIsModalOpen(true);
                }
                else {
                  setConfirmModalType('❌');
                  setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                  setIsModalOpen(true);
                }
              } else if (error.request) {
                setConfirmModalType('❌');
                setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                setIsModalOpen(true);
                console.log('Request made but no response received:', error.request);
              } else {
                setConfirmModalType('❌');
                setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                setIsModalOpen(true);
                console.log('Error message:', error.message);
              }
            } else {
              setConfirmModalType('❌');
              setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
              setIsModalOpen(true);
              console.error('An unknown error occurred:', error);
            }
          }
        } else {
          /* Multiple files */
          try {
            formData.append('business_id', whatsapp_businessID);
            formData.append('knowledge_base_nickname', baseName);
            selectedFilesModal.forEach(file => {
              formData.append('files', file);
            });
            //const response_base = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/createKnowledgeBase_multifile`, formData,
            const response_base = await axios.post(`${config.backendURL}/v1/knowledgebases/createKnowledgeBase_multifile`, formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${gojimx_token}`
                }
              });

            if (response_base.status === 200) {
              setConfirmModalType('✅');
              setConfirmModalText('Base de conocimiento')
              setModalMessage('La base de conocimiento ha sido creada correctamente');
              setIsModalOpen(true);
              handleCloseAddBaseModal();
              fetchBases();
            }

          } catch (error) {
            if (axios.isAxiosError(error)) {
              if (error.response) {
                const getError = error.response.data.error;
                handleCloseAddBaseModal()
                setConfirmModalText('Base de conocimiento')
                if (getError === '[Renata-Backend] Knowledge Base Error: Max Knowledge Bases reached!!') {
                  setConfirmModalType('⚠️');
                  setModalMessage('Alcanzaste el número máximo de bases de conocimiento.');
                  setIsModalOpen(true);
                }
                else {
                  setConfirmModalType('❌');
                  setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                  setIsModalOpen(true);
                }
              } else if (error.request) {
                setConfirmModalType('❌');
                setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                setIsModalOpen(true);
                console.log('Request made but no response received:', error.request);
              } else {
                setConfirmModalType('❌');
                setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
                setIsModalOpen(true);
                console.log('Error message:', error.message);
              }
            } else {
              setConfirmModalType('❌');
              setModalMessage('Ocurrió un error al crear la base de conocimiento, ponte en contacto con un administrador.');
              setIsModalOpen(true);
              console.error('An unknown error occurred:', error);
            }
          }
        }
      } else {
        setConfirmModalText('Base de conocimiento')
        setConfirmModalType('❌');
        setModalMessage('Ocurrió un error al procesar tu pago, ponte en contacto con un administrador.');
        setIsModalOpen(true);
      }
    }
    setIsLoading(false);
  };

  const addFileToBase = async (baseName: string) => {
    const formData = new FormData();
    formData.append('business_id', whatsapp_businessID);
    formData.append('knowledge_base_nickname', baseName);
    setIsSyncing(true);
    updateSelectedBaseStat('sync');

    if (selectedFiles.length === 0 && inputValue === "") {
      setConfirmModalType('⚠️');
      setConfirmModalText('Sincronizacion de Archivo')
      setModalMessage('Selecciona al menos un archivo o ingresa una url para sincronizar');
      setIsModalOpen(true);
      updateSelectedBaseStat('ok');
      setIsSyncing(false);
      return;
    }

    if (inputValue.indexOf('https://es.wikipedia.org/wiki') > -1) {
      try {
        //const response = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/uploadURLText2KnowledgeBase`, {
        const response = await axios.post(`${config.backendURL}/v1/knowledgebases/uploadURLText2KnowledgeBase`, {
          business_id: whatsapp_businessID,
          knowledge_base_nickname: baseName,
          url_to_scrap: inputValue
        },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${gojimx_token}`
            }
          });

        if (response.status === 200) {
          setConfirmModalType('✅');
          setConfirmModalText('Sincronizacion de Archivo')
          setModalMessage('El archivo fue vectorizado exitosamente a la base de conocimiento');
          setIsModalOpen(true);
          handleShowFiles(baseName);
          setInputValue('');
          updateSelectedBaseStat('ok');
          setIsExpanded(false);
        }
      } catch (error) {
        handleCloseAddBaseModal();
        setConfirmModalText('Sincronizacion de Archivo');
        if (axios.isAxiosError(error)) {
          if (error.response) {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al subir el archivo al generar el archivo, ponte en contacto con un administrador.');
            setIsModalOpen(true);
          }
        }
        updateSelectedBaseStat('ok');
      }
    } else if (!(inputValue.indexOf('https://es.wikipedia.org/wiki') < -1) && inputValue !== "") {
      setConfirmModalType('⚠️');
      setConfirmModalText('Sincronizacion de Archivo')
      setModalMessage('Ingresa una url válida');
      setIsModalOpen(true);
      updateSelectedBaseStat('no-sync');
    } else {

      if (selectedFiles.length === 1) {
        try {
          formData.append('file', selectedFiles[0]);
          //const response = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/uploadFile2KnowledgeBase`, formData,
          const response = await axios.post(`${config.backendURL}/v1/knowledgebases/uploadFile2KnowledgeBase`, formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${gojimx_token}`
              }
            });

          if (response.status === 200) {
            setConfirmModalType('✅');
            setConfirmModalText('Sincronizacion de Archivo')
            setModalMessage('El archivo \'' + selectedFiles[0].name + '\' fue vectorizado exitosamente a la base de conocimiento');
            setIsModalOpen(true);
            handleShowFiles(baseName);
            setSelectedFiles([]);
          }

        } catch (error) {
          handleCloseAddBaseModal();
          setConfirmModalText('Sincronizacion de Archivo');
          if (axios.isAxiosError(error)) {
            if (error.response) {
              const getError = error.response.data.error;
              if (getError === '[Renata-Backend] Knowledge Base Error: MaxFiles for Knowledge Bases reached!!') {
                setConfirmModalType('⚠️');
                setModalMessage('Alcanzaste el número máximo de archivos por base de conocimiento.');
                setIsModalOpen(true);
              }
              else {
                setConfirmModalType('❌');
                setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
                setIsModalOpen(true);
              }
            } else if (error.request) {
              setConfirmModalType('❌');
              setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
              setIsModalOpen(true);
              console.log('Request made but no response received:', error.request);
            } else {
              setConfirmModalType('❌');
              setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
              setIsModalOpen(true);
              console.log('Error message:', error.message);
            }
          } else {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
            setIsModalOpen(true);
            console.error('An unknown error occurred:', error);
          }
          setSelectedFiles([]);
          updateSelectedBaseStat('ok');
        }
        handleShowFiles(baseName);
      } else {
        try {
          selectedFiles.forEach(file => {
            formData.append('files', file);
          });
          //const response = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/uploadMultiFiles2KnowledgeBase`, formData,
          const response = await axios.post(`${config.backendURL}/v1/knowledgebases/uploadMultiFiles2KnowledgeBase`, formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${gojimx_token}`
              }
            });

          if (response.status === 200) {
            setConfirmModalType('✅');
            setConfirmModalText('Sincronizacion de Archivo')
            setModalMessage('Los archivos han sido vectorizados exitosamente a la base de conocimiento');
            setIsModalOpen(true);

            setSelectedFiles([]);
          }

        } catch (error) {
          handleCloseAddBaseModal();
          setConfirmModalText('Sincronizacion de Archivo');
          if (axios.isAxiosError(error)) {
            if (error.response) {
              const getError = error.response.data.error;
              if (getError === '[Renata-Backend] Knowledge Base Error: MaxFiles for Knowledge Bases reached!!') {
                setConfirmModalType('⚠️');
                setModalMessage('Solo puedes tener 5 archivos por base de conocimiento.');
                setIsModalOpen(true);
              }
              else {
                setConfirmModalType('❌');
                setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
                setIsModalOpen(true);
              }
            } else if (error.request) {
              setConfirmModalType('❌');
              setModalMessage('Ocurrió un error, intenta de nuevo más tarde.');
              setIsModalOpen(true);
              console.log('Request made but no response received:', error.request);
            } else {
              setConfirmModalType('❌');
              setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
              setIsModalOpen(true);
              console.log('Error message:', error.message);
            }
          } else {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al subir el archivo a la base de conocimiento, ponte en contacto con un administrador.');
            setIsModalOpen(true);
            console.error('An unknown error occurred:', error);
          }
          updateSelectedBaseStat('ok');
          // setSelectedFiles([]);
          // fetchBases();
        }
      }
    }
    handleShowFiles(baseName);
    setIsSyncing(false);
  }

  const updateBaseName = async () => {

    try {
      //TODO Agregar servicio de actualizacion de nombre y actualizar modales
      if (baseName == '') {
        setShowAddBaseModal(false);
        setConfirmModalType('⚠️');
        setModalMessage('Ingresa el nombre de la Base');
        setIsModalOpen(true);
        return;
      }
      setIsLoading(true);
      const data = {
        business_id: whatsapp_businessID,
        knowledge_base_nickname: originalBaseName,
        new_nickname: baseName
      }

      //const response = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/editKnowledgeBaseNikcname`, data,
      const response = await axios.post(`${config.backendURL}/v1/knowledgebases/editKnowledgeBaseNikcname`, data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${gojimx_token}`
          }
        });

      if (response.status === 200) {
        fetchBases(true);
        handleCloseAddBaseModal()
        setConfirmModalType('✅');
        setConfirmModalText('Base de conocimiento')
        setModalMessage('El nombre de la base fue actualizado correctamente');
        setIsModalOpen(true);
        handleShowFiles(baseName);
      }
    } catch (error) {
      setConfirmModalType('❌');
      setConfirmModalText('Base de conocimiento')
      setModalMessage('Ocurrió un error al actualizar el nombre de la base, intenta de nuevo más tarde');
      setIsModalOpen(true);
      console.error('Error changing base name:', error);
    }

    setIsLoading(false);
  };

  const handleFileDelete = async () => {
    const len = selectedBase?.archivos.length;
    if (len === 1) {
      setConfirmModalType('❌');
      setConfirmModalText('Base de conocimiento')
      setModalMessage('La base de conocimiento debe tener al menos un archivo');
      setIsModalOpen(true);
    } else {
      if (!deleteInfo.file || !deleteInfo.baseName) return;
      try {
        const bName = deleteInfo.baseName;
        //const response = await axios.post(`http://localhost:3333/backend/v1/knowledgebases/deleteFileFromKnowledgeBase`, {
        const response = await axios.post(`${config.backendURL}/v1/knowledgebases/deleteFileFromKnowledgeBase`, {
          business_id: whatsapp_businessID,
          knowledge_base_nickname: deleteInfo.baseName,
          file_name: deleteInfo.file
        },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${gojimx_token}`
            }
          });
        if (response.status === 200) {
          setConfirmModalType('✅');
          setConfirmModalText('Base de conocimiento')
          setModalMessage('El archivo fue eliminado correctamente');
          setIsModalOpen(true);
          handleShowFiles(bName);
        }
      } catch (error) {
        setConfirmModalText('Base de conocimiento')
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const getError = error.response.data.error;
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
            setIsModalOpen(true);
            console.log(getError);
          } else if (error.request) {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
            setIsModalOpen(true);
            console.log('Request made but no response received:', error.request);
          } else {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
            setIsModalOpen(true);
            console.log('Error message:', error.message);
          }
        } else {
          setConfirmModalType('❌');
          setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
          setIsModalOpen(true);
          console.error('An unknown error occurred:', error);
        }
      }
    }
  };

  const handleMultiFileDelete = async () => {
    //TODO Actualizar el request al servicio correspondiente e implementar try catch
    if (selectedFilesDelete.length === 0) return;
    if (!deleteInfo.file || !deleteInfo.baseName) return;
    const bName = deleteInfo.baseName;
    if (selectedFilesDelete.length === 1) {
      let data = JSON.stringify({
        "business_id": whatsapp_businessID,
        "knowledge_base_nickname": deleteInfo.baseName,
        "file_name": selectedFilesDelete
      });

      let configuration = {
        method: 'post',
        maxBodyLength: Infinity,
        //url: `http://localhost:3333/backend/v1/knowledgebases/deleteFileFromKnowledgeBase`,
        url: `${config.backendURL}/v1/knowledgebases/deleteFileFromKnowledgeBase`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        },
        data: data
      };

      axios.request(configuration)
        .then((response) => {
          if (response.status === 200) {
            setConfirmModalType('✅');
            setConfirmModalText('Base de conocimiento')
            setModalMessage('Archivo eliminado correctamente');
            setIsModalOpen(true);
            handleShowFiles(bName);
          } else {
            setConfirmModalType('❌');
            setConfirmModalText('Base de conocimiento')
            setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
            setIsModalOpen(true);
            console.log(JSON.stringify(response.data));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {

      let data = JSON.stringify({
        "business_id": whatsapp_businessID,
        "knowledge_base_nickname": deleteInfo.baseName,
        "file_name": selectedFilesDelete
      });

      let configuration = {
        method: 'post',
        maxBodyLength: Infinity,
        //url: `http://localhost:3333/backend/v1/knowledgebases/deleteMultiFileFromKnowledgeBase`,
        url: `${config.backendURL}/v1/knowledgebases/deleteMultiFileFromKnowledgeBase`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        },
        data: data
      };

      axios.request(configuration)
        .then((response) => {
          if (response.status === 200) {
            setConfirmModalType('✅');
            setConfirmModalText('Base de conocimiento')
            setModalMessage('Archivo eliminado correctamente');
            setIsModalOpen(true);
            handleShowFiles(bName);
          } else {
            setConfirmModalType('❌');
            setConfirmModalText('Base de conocimiento')
            setModalMessage('Ocurrió un error al eliminar el archivo, intenta de nuevo más tarde');
            setIsModalOpen(true);
            console.log(JSON.stringify(response.data));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setSelectedFilesDelete([]);
  };

  const handleFileDeleteModal = () => {
    setShowFileModal(false);
    setConfirmModalType('❌');
    setConfirmModalText('Eliminar archivo')
    setModalMessage('¿Estás seguro de que deseas eliminar este archivo? Esta acción no se puede deshacer.');
    setIsModalOpen(true);
    //handleFileDelete(selectedFile.);
    //handleCloseFileModal();
  };
  const handleCheckDeleteBase = async () => {
    let cont = 0;
    asistentes.forEach(asistente => {
      if (originalBaseName === asistente.assistant_knowledge_base) {
        cont = cont + 1;
        setBaseAssistantId(asistente.assistant_id);
      }
    });

    if (cont > 0) {
      setConfirmModalType('✅');
      setConfirmModalText('Base de conocimiento en uso')
      setModalMessage('La Base que intentas eliminar esta asignada a un asistente, seguro que deseas continuar ?');
      setConfirmDeleteBase(false);
      setIsModalOpen(true);
    }
    else {
      await handleDeleteBase();
    }
  }

  const handleDeleteBase = async () => {
    setIsLoading(true);

    if (baseAssistantId) {
      try {
        //const response = await axios.post(`http://localhost:3333/backend/deleteBaseAssistant`, {
        const response = await axios.post(`${config.backendURL}/deleteBaseAssistant`, {
          businessId: whatsapp_businessID,
          assistantId: baseAssistantId,
        },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${gojimx_token}`
            }
          });
        if (response.status === 200) {
          setBaseAssistantId('');
        }
      } catch (error) {
        setConfirmModalText('Base de conocimiento')
        if (axios.isAxiosError(error)) {
          if (error.response) {
            const getError = error.response.data.error;
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al eliminar la base del asistente, intenta de nuevo más tarde');
            setIsModalOpen(true);
            console.log(getError);
          } else if (error.request) {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al eliminar la base del asistente, intenta de nuevo más tarde');
            setIsModalOpen(true);
            console.log('Request made but no response received:', error.request);
          } else {
            setConfirmModalType('❌');
            setModalMessage('Ocurrió un error al eliminar la base del asistente, intenta de nuevo más tarde');
            setIsModalOpen(true);
            console.log('Error message:', error.message);
          }
        } else {
          setConfirmModalType('❌');
          setModalMessage('Ocurrió un error al eliminar la base del asistente, intenta de nuevo más tarde');
          setIsModalOpen(true);
          console.error('An unknown error occurred:', error);
        }
        setBaseAssistantId('');
      }
    }

    try {
      let data = JSON.stringify({
        "business_id": whatsapp_businessID,
        "knowledge_base_nickname": originalBaseName
      });

      let configuration = {
        method: 'post',
        maxBodyLength: Infinity,
        //url: `http://localhost:3333/backend/v1/knowledgebases/deleteKnowledgeBase`,
        url: `${config.backendURL}/v1/knowledgebases/deleteKnowledgeBase`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${gojimx_token}`
        },
        data: data
      };

      await axios.request(configuration)
        .then((response) => {
          if (response.status === 200) {
            setConfirmModalType('✅');
            setConfirmModalText('Base de conocimiento')
            setModalMessage('Base eliminada correctamente');
            setConfirmDeleteBase(false);
            setIsModalOpen(true);
            fetchBases();
          } else {
            setConfirmModalType('❌');
            setConfirmModalText('Base de conocimiento')
            setModalMessage('Ocurrió un error al eliminar la base, intenta de nuevo más tarde');
            setIsModalOpen(true);
            console.log(JSON.stringify(response.data));
          }
        })
        .catch((error) => {
          console.log(error);
        });

    } catch (error) {
      console.error('Error deleting base :', error);
    }
    setIsLoading(false);
    await fetchBusiness();
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin(
    //props?: DefaultLayoutPluginProps
  );


  /* Casos de uso */
  const animatedComponents = makeAnimated();
  const [selectedCase, setSelectedCase] = useState<OptionType[]>([]);
  const [selectedSkill, setSelectedSkill] = useState<OptionType[]>([]);


  const handleCaseUseChange = (selectedOption: OptionType[] | null) => {
    setSelectedCase(selectedOption || []);
    setChangedUseCases(true);
  };

  const handleSkillChange = (selectedSkill: OptionType[] | null) => {
    setSelectedSkill(selectedSkill || []);
    setChangedSkillS(true);
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: '12px',
      background: '#f2f2f2',
      align: 'center'
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#56595c',
      textAlign: 'center',
    })
  };

  const caseUseOptions: OptionType[] = [
    { value: "Atención al Cliente", label: "Atención al Cliente" },
    { value: "Gestión de Citas y Reservas", label: "Gestión de Citas y Reservas" },
    { value: "Soporte Técnico", label: "Soporte Técnico" },
    { value: "Encuestas y Feedback", label: "Encuestas y Feedback" },
    { value: "Promociones y Ofertas Especiales", label: "Promociones y Ofertas Especiales" },
    { value: "Información de Productos y Servicios", label: "Información de Productos y Servicios" },
    { value: "Asistencia en Compras", label: "Asistencia en Compras" },
    { value: "Confirmaciones y Seguimientos de Pedidos", label: "Confirmaciones y Seguimientos de Pedidos" },
    { value: "Resolución de Quejas y Reclamaciones", label: "Resolución de Quejas y Reclamaciones" },
    { value: "Información de Horarios y Disponibilidad", label: "Información de Horarios y Disponibilidad" },
  ];

  useEffect(() => {

    let business_data_resp = fetchBusinessStatus();
    business_data_resp.then((resp) => {
      //console.log(resp.use_case)
      setSelectedCase(resp.use_case)
    })

    fetchCategories();
    fetchBases();

    setConsultorio(String(gojimx_business_name));
    setDoctorName(String(gojimx_username));
    setCorreo(String(gojimx_email));
    setHorario(String("gojimx_horario"));
    setContextoAdicional(String("gojimx_contexto"));
    setWhatsapp_number(String(gojimx_phone));
    setWhatsapp_businessID(String(gojimx_businessId));
    setRenataID(String(gojimx_assistant_id));


  }, []);

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow} refresh={refreshTopbar} />
      </div>

      <ConfirmationModal
        show={isModalOpen}
        onHide={() => {
          if (confirmModalText === 'Sincronizacion de Archivo' || confirmModalText === 'La base de conocimiento ha sido creada correctamente') {
            setIsModalOpen(false);
          } else if (confirmModalText === 'Eliminar archivo') {
            setIsModalOpen(false);
            setShowFileModal(true);
          } else if (confirmModalText === 'Estimación' || confirmModalText === 'Aviso' || confirmModalText === 'base de conocimiento en uso' ||
            modalMessage === 'Solo se aceptan archivos de máximo 5MB' || modalMessage === 'Solo se aceptan archivos PDF y TXT, y no se permiten archivos duplicados'
            || modalMessage === 'El límite es de 25MB por Base' || modalMessage === 'El límite es de 5 archivos por Base') {
            setIsModalOpen(false);
            setShowAddBaseModal(true);
          } else if (modalMessage === 'Archivo eliminado correctamente' || 'Base eliminada correctamente') {
            setIsModalOpen(false);
          } else if (modalMessage === 'Ocurrió un error al eliminar la base, intenta de nuevo más tarde') {
            setIsModalOpen(false);
            setConfirmDeleteBase(true);
          } else {
            setIsModalOpen(false);
            setShowAddBaseModal(true);
          }
        }}
        text={modalMessage}
        type={confirmModalType}
        titleText={confirmModalText}
        isLoading={isLoading}
        onClick={() => {
          if (confirmModalText === 'Estimación') addNewBase();
          if (confirmModalText === 'Eliminar archivo') handleFileDelete();
          if (confirmModalText === 'Base de conocimiento en uso') handleDeleteBase();
        }}
      />
      {/* <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} message={modalMessage} /> */}

      <div className='row justify-content-center'>
        <div className='col-lg-6 col-md-10 col-sm-12 py-4'>
          <div className='row px-3 animate__animated animate__fadeIn'>
            <h1 className='fw-bold text-dark mt-0'>Configuración</h1>
          </div>

          <div className='row mx-3 animate__animated animate__fadeIn mb-4'>
            <ul className="nav justify-content-start">
              <li className="nav-item border-radius-custom-1 bg-light">
                <button
                  className={activeTab == "Cuenta" ? 'btn border-0 btn-dark rounded-pill' : 'btn border-0 btn-light rounded-pill'}
                  onClick={() => handleTabChange('Cuenta')}
                >Cuenta</button>
              </li>
              <li className="nav-item border-radius-custom-4 bg-light">
                <button
                  className={activeTab == "Canales" ? 'btn border-0 btn-dark rounded-pill' : 'btn border-0 btn-light rounded-pill'}
                  onClick={() => handleTabChange('Canales')}
                >Canales</button>
              </li>
              <li className="nav-item border-radius-custom-4 bg-light">
                <button
                  className={activeTab == "Asistente" ? 'btn border-0 btn-dark rounded-pill' : 'btn border-0 btn-light rounded-pill'}
                  onClick={() => handleTabChange('Asistente')}
                >Asistentes</button>
              </li>
              <li className="nav-item border-radius-custom-4 bg-light">
                <button
                  className={activeTab == "Conocimiento" ? 'btn border-0 btn-dark rounded-pill' : 'btn border-0 btn-light rounded-pill'}
                  onClick={() => {
                    handleTabChange('Conocimiento');
                    //fetchBases();
                  }}
                >Conocimiento</button>
              </li>
              <li className="nav-item border-radius-custom-4 bg-light">
                <button
                  className={activeTab == "Habilidades" ? 'btn border-0 btn-dark rounded-pill' : 'btn border-0 btn-light rounded-pill'}
                  onClick={() => handleTabChange('Habilidades')}
                >Habilidades</button>
              </li>
              <li className="nav-item border-radius-custom-2 bg-light">
                <button
                  className={activeTab == "Categorias" ? 'btn border-0 btn-dark rounded-pill' : 'btn border-0 btn-light rounded-pill'}
                  onClick={() => handleTabChange('Categorias')}
                >Categorías</button>
              </li>
            </ul>
          </div>

          {/* CUENTA TAB */}
          {activeTab == "Habilidades" && (
            <MySkills fetchBusiness={fetchBusiness}></MySkills>
          )}

          {/* CUENTA TAB */}
          {activeTab == "Cuenta" && (
            <form onSubmit={handleSubmit}>

              <div className='row px-3 animate__animated animate__fadeIn mb-3'>
                <h5 className='fw-bold text-dark my-0'>Nombre del negocio <i className="bi bi-question-circle" onClick={() => handleSupportChatOpen("¿Qué es el nombre del negocio?")}></i></h5>
                <p className='text-dark mt-0 mb-2'>Renata lo utiliza para dar información</p>
                <div>
                  <input
                    type="text"
                    className={'form-control bg-light'}
                    placeholder='Nombre del consultorio'
                    value={consultorio}
                    autoComplete="off"
                    onChange={handleConsultorioChange}
                  />
                </div>
              </div>
              <div className='row px-3 animate__animated animate__fadeIn mb-3'>
                <h5 className='fw-bold text-dark my-0'>Nombre del usuario</h5>
                <p className='text-dark mt-0 mb-2'>Nombre completo con el que te identificas</p>
                <div>
                  <input
                    type="text"
                    className={'form-control bg-light'}
                    placeholder='Nombre del doctor'
                    autoComplete="off"
                    value={doctorName}
                    onChange={handleDoctorNameChange}
                  />
                </div>
              </div>

              <div className='row px-3 animate__animated animate__fadeIn mb-3'>
                <h5 className='fw-bold text-dark my-0'>Correo</h5>
                <p className='text-dark mt-0 mb-2'>Correo electrónico al cual se te enviarán nottificaciones relacionadas a tu cuenta</p>
                <div>
                  <input
                    type="text"
                    className={'form-control bg-light'}
                    placeholder='Correo electrónico'
                    autoComplete="off"
                    value={correo}
                    onChange={handleEmailChange}
                  />
                </div>
              </div>

              <div className='row px-3 animate__animated animate__fadeIn mb-3'>
                <h5 className='fw-bold text-dark my-0'>Casos de uso</h5>
                <p className='text-dark mt-0 mb-2'>Selecciona el caso de uso para Renata</p>
                <ReactSelect
                  value={selectedCase}
                  closeMenuOnSelect={true}
                  placeholder={'Caso de uso'}
                  components={animatedComponents}
                  isMulti={true}
                  onChange={(option) => handleCaseUseChange(option as OptionType[])}
                  options={caseUseOptions}
                  menuPortalTarget={document.body}
                  styles={customStyles}
                />
              </div>



              <div className='w-100 my-4'>
                <div className='border mb-2 animate__animated animate__fadeIn'></div>
              </div>

              <div className='row px-3 animate__animated animate__fadeIn mb-3'>
                <h5 className='fw-bold text-dark mt-0 mb-2'>Teléfono de WhatsApp</h5>
                <div>
                  <input
                    type="text"
                    className={'form-control bg-light'}
                    placeholder={whatsapp_number}
                    autoComplete="off"
                    disabled
                  />
                </div>
              </div>
              <div className='row px-3 animate__animated animate__fadeIn mb-3'>
                <h5 className='fw-bold text-dark mt-0 mb-2'>BussinessID <i className="bi bi-question-circle" onClick={() => handleSupportChatOpen("¿Qué es el BussinessID?")}></i></h5>
                <div>
                  <input
                    type="text"
                    className={'form-control bg-light'}
                    placeholder={whatsapp_businessID}
                    autoComplete="off"
                    disabled
                  />
                </div>
              </div>
              <div className='row px-3 animate__animated animate__fadeIn mb-3'>
                <h5 className='fw-bold text-dark mt-0 mb-2'>RenataID <i className="bi bi-question-circle" onClick={() => handleSupportChatOpen("¿Qué es el RenataID?")}></i></h5>
                <div>
                  <input
                    type="text"
                    className={'form-control bg-light'}
                    placeholder={renataID}
                    autoComplete="off"
                    disabled
                  />
                </div>
              </div>

              <div className='row px-3 animate__animated animate__fadeIn mb-3 mt-5'>
                <div>
                  <button type="button" className="btn btn-light w-100 mb-2" onClick={handleUsersOpenModal}>
                    Ver usuarios secundarios
                  </button>
                  {usersModal && (
                    <UsersModal
                      show={usersModal}
                      onHide={handleUsersCloseModal}
                    />
                  )}
                </div>
                <div className='col'>
                  <button type="button" className="btn btn-light w-100" onClick={handleChangePasswordClick}>
                    Cambiar contraseña
                  </button>
                </div>
                {(gojimx_username !== doctorName || gojimx_business_name !== consultorio || correo != gojimx_email || changed_use_cases) && (

                  <div className='col'>
                    <button type="submit" className="btn btn-dark w-100 mb-2" disabled={isLoading}>
                      {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Guardar'}
                    </button>


                  </div>
                )}

              </div>

            </form>
          )}

          {activeTab == "Asistente" && (
            <div className='row px-3 animate__animated animate__fadeIn mb-3'>
              <h2 className='fw-bold text-dark my-0'>Mis asistentes <i className="bi bi-question-circle" onClick={() => handleSupportChatOpen("¿Qué es un asistente?")}></i></h2>
              <p className='text-dark mt-2 mb-2'>Selecciona qué asistente contesta tus mensajes y configura sus instrucciones</p>

              <div className='d-block w-100'>
                <div className="row">
                  {asistentes.map((asistente, index) => (
                    <div
                      key={index}
                      className='btn bg-light p-3 mb-3 w-100 d-flex align-items-center'
                      style={{ textAlign: 'left' }}
                      onClick={() => handleEditAsistente(asistente)}
                    >
                      <img style={{ width: '12%' }} className="me-2" src={Logo} alt="Renata Logo" />
                      <div className="d-flex flex-column justify-content-center">
                        <h5 className='fw-bold mb-2'>{asistente.name}</h5>
                        <p className='mb-0'>{asistente.description}</p>
                      </div>
                      {!updatingAssistant ? (
                        <div className="ms-auto">
                          <Button
                            variant="light"
                            onClick={(e) => {
                              e.stopPropagation(); // Detiene la propagación del evento de clic
                              handleDuplicateAssistant(asistente);
                            }}
                          >
                            <i className="bi bi-copy"></i>
                          </Button>
                          <Button
                            variant="light"
                            onClick={(e) => {
                              e.stopPropagation(); // Detiene la propagación del evento de clic
                              handleChangeMainAssistant(asistente.assistant_id);
                            }}
                          >
                            <i className={asistente.assistant_id === gojimx_assistant_id ? "bi bi-star-fill" : "bi bi-star"}></i>
                          </Button>

                        </div>
                      ) : (
                        <div className='ms-auto me-3'>
                          <h1 style={{ fontWeight: 'bold' }}><img className='loading' src={loadingGif} alt="Cargando..." /></h1>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {(gojimx_payment_type === 'Premium' && asistentes.length < 6) ||
                (gojimx_payment_type !== 'Premium' && asistentes.length < 3) ? (
                <div className='btn bg-light p-3 mb-3 w-100' onClick={handleCreateAsistente}>
                  <h1><i className="bi bi-plus-lg"></i></h1>
                </div>
              ) : null}
            </div>
          )}

          {/* CONOCIMIENTO TAB */}
          <KnowledgeTab
            activeTab={activeTab}
            basesConocimiento={basesConocimiento}
            handleShowFiles={handleShowFiles}
            truncateFileName={truncateFileName}
            handleEditBase={handleEditBase}
            handleAddBase={handleAddBase}
            selectedBase={selectedBase}
            fileDate={'Fecha de sincronización'}
            isSyncing={false}
            addFileToBase={addFileToBase}
            selectedFilesDelete={[]}
            handleFileClick={handleFileClick}
            setDeleteInfo={setDeleteInfo}
            selectedFiles={[]}
            removeFile={removeFile}
            handleFileChange={handleFileChange}
            isExpanded={false}
            inputValue={''}
            setInputValue={setInputValue}
            updateSelectedBaseStat={updateSelectedBaseStat}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
          />

          <EditAssistantModal
            showEditAsistenteModal={showEditAsistenteModal}
            handleCloseEditAsistenteModal={handleCloseEditAsistenteModal}
            editAsistente={editAsistente}
            newAsistenteName={newAsistenteName}
            setAssistantName={setAssistantName}
            newAsistenteDescription={newAsistenteDescription}
            setAssistantDescription={setAssistantDescription}
            intelligenceLevel={intelligenceLevel}
            setIntelligenceLevel={setIntelligenceLevel}
            responseLength={responseLength}
            setResponseLength={setResponseLength}
            workingHoursStart={workingHoursStart}
            setWorkingHoursStart={setWorkingHoursStart}
            workingHoursEnd={workingHoursEnd}
            setWorkingHoursEnd={setWorkingHoursEnd}
            responseSpeed={responseSpeed}
            setResponseSpeed={setResponseSpeed}
            isTextImproving={isTextImproving}
            loadingRenataLoader={loadingRenataLoader}
            instructions={instructions}
            handleTextChange={handleTextChange}
            handleTextIA={handleTextIA}
            isButtonDisabledModify={isButtonDisabledModify}
            loadingGif={loadingGif}
            selectedSkill={selectedSkill}
            handleSkillChange={handleSkillChange}
            skills={skills}
            selectedWhiteList={selectedWhiteList}
            handleChangeWhiteList={handleChangeWhiteList}
            customStyles={customStyles}
            options={options}
            handleSelectChange={handleSelectChange}
            selectedValue={selectedValue}
            loadingCost={loadingCost}
            assistantCost={assistantCost}
            savingAssistant={savingAssistant}
            handleSaveAssistantChanges={handleSaveAssistantChanges}
            setShowEditAsistenteModal={setShowEditAsistenteModal}
            setConfirmDeleteAssistant={setConfirmDeleteAssistant}
            setTitleModalAdd={setTitleModalAdd}
          />



          <CreateAssistantModal
            showCreateAsistenteModal={showCreateAsistenteModal}
            handleCloseCreateAsistenteModal={handleCloseCreateAsistenteModal}
            newAsistenteName={newAsistenteName}
            setAssistantName={setAssistantName}
            newAsistenteDescription={newAsistenteDescription}
            setAssistantDescription={setAssistantDescription}
            intelligenceLevel={intelligenceLevel}
            setIntelligenceLevel={setIntelligenceLevel}
            responseLength={responseLength}
            setResponseLength={setResponseLength}
            workingHoursStart={workingHoursStart}
            setWorkingHoursStart={setWorkingHoursStart}
            workingHoursEnd={workingHoursEnd}
            setWorkingHoursEnd={setWorkingHoursEnd}
            responseSpeed={responseSpeed}
            setResponseSpeed={setResponseSpeed}
            isTextImproving={isTextImproving}
            loadingRenataLoader={loadingRenataLoader}
            instructions={instructions}
            handleTextChange={(e: any) => setInstructions(e.target.value)}
            handleTextIA={handleTextIA}
            isButtonDisabledModify={isButtonDisabledModify}
            loadingGif={loadingGif}
            selectedWhiteList={selectedWhiteList}
            handleChangeWhiteList={(value: any) => setSelectedWhiteList(value)}
            selectedSkill={selectedSkill}
            handleSkillChange={(options: any) => setSelectedSkill(options)}
            skills={skills}
            options={options}
            handleSelectChange={handleSelectChange}
            savingAssistant={savingAssistant}
            handleSaveNewAsistente={handleSaveNewAsistente}
            customStyles={customStyles}
          />

          {/* CATEGORIAS TAB */}
          {activeTab == "Categorias" && (

            <Categories
              activeTab={activeTab}
              categorias={categorias}
              handleEditCategoria={handleEditCategoria}
              handleAddCategoria={handleAddCategoria}
            />

          )}

          {activeTab == "Canales" && (

            <ChannelTab mainAssistant={mainAssistant} channels={channels} fetchBusiness={fetchBusiness}></ChannelTab>

          )}


        </div>
      </div>

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={handleCloseConfirmationModal}
        text={confirmationModalText}
        type={confirmModalType}
        isLoading={isLoading}
        titleText='Configuración'
      />

      <AddCategoriaModal
        showAddCategoriaModal={showAddCategoriaModal}
        handleCloseAddCategoriaModal={handleCloseAddCategoriaModal}
        titleModalAdd={titleModalAdd}
        newOptionName={newOptionName}
        handleNewOptionNameChange={handleNewOptionNameChange}
        newOptionDescription={newOptionDescription}
        handleNewOptionDescriptionChange={handleNewOptionDescriptionChange}
        newOptionIcon={newOptionIcon}
        handleNewOptionIconChange={handleNewOptionIconChange}
        handleDeleteOption={handleDeleteOption}
        editOptionInDefaultCategory={editOptionInDefaultCategory}
        addNewOptionToDefaultCategory={addNewOptionToDefaultCategory}
        isLoading={isLoading}
        loadingGif={loadingGif}
      />

      <ConfirmDeleteAssistantModal
        showModalConfirmDeleteAssistant={showModalConfirmDeleteAssistant}
        setConfirmDeleteAssistant={setConfirmDeleteAssistant}
        setShowEditAsistenteModal={setShowEditAsistenteModal}
        savingAssistant={savingAssistant}
        loadingGif={loadingGif}
        handleDeleteAssistant={handleDeleteAssistant}
      />

      {/* Confirm delete base */}
      <ConfirmDeleteBaseModal
        showModalConfirmDeleteBase={showModalConfirmDeleteBase}
        titleModalAdd={titleModalAdd}
        isLoading={isLoading}
        loadingGif={loadingGif}
        setTitleModalAdd={setTitleModalAdd}
        setConfirmDeleteBase={setConfirmDeleteBase}
        setShowAddBaseModal={setShowAddBaseModal}
        handleCheckDeleteBase={handleCheckDeleteBase}
        handleMultiFileDelete={handleMultiFileDelete}
      />

      {/* Knowledge */}

      <AddBaseModal
        showAddBaseModal={showAddBaseModal}
        handleCloseAddBaseModal={() => setShowAddBaseModal(false)}
        titleModalAdd={titleModalAdd}
        baseName={baseName}
        handleBaseNameChange={handleBaseNameChange}
        handleFileChangeModal={handleFileChangeModal}
        selectedFilesModal={selectedFilesModal}
        totalSize={totalSize}
        truncateFileName={truncateFileName}
        removeFileModal={removeFileModal}
        sizeResponse={sizeResponse}
        handleSliderResponse={handleSliderResponse}
        wordNumber={wordNumber}
        handleSliderWord={handleSliderWord}
        isLoading={isLoading}
        getTokenEstimation={getTokenEstimation}
        updateBaseName={updateBaseName}
        setTitleModalAdd={setTitleModalAdd}
        setConfirmDeleteBase={setConfirmDeleteBase}
        setShowAddBaseModal={setShowAddBaseModal}
      />

      {/* Modal for displaying the selected file */}
      <FileModal
        showFileModal={showFileModal}
        handleCloseFileModal={handleCloseFileModal}
        handleBaseNameChange={handleBaseNameChange}
        selectedFile={selectedFile}
        modalPdfUrl={modalPdfUrl}
        handleFileDeleteModal={handleFileDeleteModal}
      />
      {gojimx_payment_type === 'Premium' && (
        <>
          <div className="support-bubble animate__animated animate__fadeIn" onClick={() => handleSupportChatOpen("")}>
            💬
          </div>
          <SupportChatModal show={supportChatOpen} handleClose={handleSupportChatClose} predefinedQuestion={predefinedQuestion}/>
        </>
      )}
      <Menu show={show} handleClose={handleClose} />
    </div>
  );
}

export default Settings;
