import React, { useState, ChangeEvent } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../img/renata-logo.svg';
import loadingGif from '../../img/loading.gif';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/login.css';
import '../../css/modal.css';
import makeAnimated from 'react-select/animated';
import ReactSelect from 'react-select';
import config from '../../config/config';
import CustomModal from '../../CustomModal';
import DiscountCodeModal from '../Modals/DiscountCodeModal';
import ConfirmationModal from '../Modals/ConfirmationModal';

interface OptionType {
  value: string;
  label: string;
}

function Register() {
  const animatedComponents = makeAnimated();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [usecaseError, setUsecaseError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [businessNameError, setBusinessNameError] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedCase, setSelectedCase] = useState<OptionType[]>([]);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  {/* Renata Pro and Free */ }
  const [renataPremiumSelected, setRenataPremiumSelected] = useState(false);
  const [renataStandardSelected, setRenataStandardSelected] = useState(false);
  const [renataCouponSelected, setRenataCouponSelected] = useState(false);

  const [renataGratisSelected, setRenataGratisSelected] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [coupon, setCoupon] = useState('');
  const [discountCode, setDiscountCode] = useState(undefined);
  const [loadingCoupon, setLoadingCoupon] = useState(false);


  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: '12px',
      background: '#f2f2f2',
      align: 'center'
    }),
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#56595c',
      textAlign: 'center',
    })
  };

  const caseUseOptions = [
    { value: "Atención al Cliente", label: "Atención al Cliente" },
    { value: "Gestión de Citas y Reservas", label: "Gestión de Citas y Reservas" },
    { value: "Soporte Técnico", label: "Soporte Técnico" },
    { value: "Encuestas y Feedback", label: "Encuestas y Feedback" },
    { value: "Promociones y Ofertas Especiales", label: "Promociones y Ofertas Especiales" },
    { value: "Información de Productos y Servicios", label: "Información de Productos y Servicios" },
    { value: "Asistencia en Compras", label: "Asistencia en Compras" },
    { value: "Confirmaciones y Seguimientos de Pedidos", label: "Confirmaciones y Seguimientos de Pedidos" },
    { value: "Resolución de Quejas y Reclamaciones", label: "Resolución de Quejas y Reclamaciones" },
    { value: "Información de Horarios y Disponibilidad", label: "Información de Horarios y Disponibilidad" },
  ];

  const navigate = useNavigate();

  const registerUser = async () => {
    try {

      const response = await axios.post(`${config.backendURL}/register`, {
        username,
        phoneNumber,
        password,
        businessName,
        selectedCase,
        email,
        paymentType,
        coupon : discountCode
      });

      if (response.status === 201) {
        // Store session stuff
        const storeItems = [
          localStorage.setItem('gojimx_token', response.data.token),
          localStorage.setItem('gojimx_phone', response.data.phoneNumber),
          localStorage.setItem('gojimx_username', response.data.username),
          localStorage.setItem('gojimx_businessId', response.data.businessId),
          localStorage.setItem('gojimx_business_name', response.data.business_name),
          localStorage.setItem('gojimx_assistant_id', response.data.assistant_id),
          localStorage.setItem('gojimx_baileys_status', response.data.baileys_status),
          localStorage.setItem('gojimx_baileys_port', response.data.baileys_port),
          localStorage.setItem('gojimx_needs_payment', (paymentType == 'Coupon' ? 'false' : 'true')),
          localStorage.setItem('gojimx_payment_type', paymentType),
          localStorage.setItem('gojimx_email', response.data.email),

        ];

        await Promise.all(storeItems);
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('gojimx_token')}`;

        // Navigate to home page
        window.location.reload();
      }
    } catch (error: any) {
      console.error('Error al registrar el usuario', error);
    } finally {
      setShowDiscountModal(false);
      setLoadingCoupon(false);

    }
  };

  const handleApplyCoupon = async (coupon: string) => {
    try {
      setLoadingCoupon(true);
      const response = await axios.post(`${config.backendURL}/validate_coupon`, {
        coupon : coupon
      });

      if (response.status === 200) {
        await registerUser();

        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    } catch (error: any) {
      setShowDiscountModal(false);
      setModalMessage('El cupón "' + coupon +  '" no es válido o ya expiró.');
      setIsModalOpen(true);
      setDiscountCode(undefined)
      setLoadingCoupon(false);
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/\D/g, '');
    const prefix = value.slice(0, 2);
    if (prefix === '55' || '56' || '33' || '81') {
      if (value.length > 2 && value.length <= 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2);
      } else if (value.length > 6) {
        value = value.slice(0, 2) + ' ' + value.slice(2, 6) + ' ' + value.slice(6, 10);
      }
    } else {
      if (value.length > 3 && value.length <= 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3);
      } else if (value.length > 6) {
        value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6, 10);
      }
    }

    setPhoneNumber(value);
    setPhoneError(false);
    setIsValidNumber(value.length === 12);
  };

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setUsernameError(false);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordError(false);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailError(false);
  };

  const handleBusinessNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBusinessName(event.target.value);
    setBusinessNameError(false);
  };

  const handleCaseUseChange = (selectedOption: any) => {
    setSelectedCase(selectedOption);
    setUsecaseError(selectedOption.length === 0);
  };

  const handleTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsTermsChecked(e.target.checked);
  };

  const handlePrivacyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsPrivacyChecked(e.target.checked);
  };

  const handleNextStep = () => {
    let valid = true;

    if (!username.trim()) {
      setUsernameError(true);
      valid = false;
    } else {
      setUsernameError(false);
    }

    if (!isValidNumber) {
      setPhoneError(true);
      valid = false;
    } else {
      setPhoneError(false);
    }

    if (password.length < 5) {
      setPasswordError(true);
      valid = false;
    } else {
      setPasswordError(false);
    }

    if (!isTermsChecked || !isPrivacyChecked) {
      valid = false;
      alert('Debes aceptar los términos y condiciones y haber leído el aviso de privacidad.');
    }

    if (step === 2 && !businessName.trim()) {
      setBusinessNameError(true);
      valid = false;
    } else {
      setBusinessNameError(false);
    }

    if (step === 2 && selectedCase.length === 0) {
      valid = false;
      setUsecaseError(true);
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }


    if (valid) {
      setStep(step + 1);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let valid = true;
    setIsLoading(true);

    if (renataCouponSelected) {
      setShowDiscountModal(true); // Show the modal if the coupon option is selected
      setIsLoading(false);
      return;
    }

    if (!businessName.trim()) {
      setBusinessNameError(true);
      valid = false;
    } else {
      setBusinessNameError(false);
    }

    if (selectedCase.length === 0) {
      setUsecaseError(true);
      valid = false;
    }

    if (!valid) {
      setIsLoading(false);
      return;
    }

    await registerUser();

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const toggleRenataSelection = (type: String) => {
    switch (type) {
      case "gratis":
        setPaymentType("Gratis");
        setRenataGratisSelected(!renataGratisSelected);
        setRenataStandardSelected(false);
        setRenataCouponSelected(false);
        setDiscountCode(undefined)

        setRenataPremiumSelected(false);
        break;
      case "Standard":
        setPaymentType("Standard");
        setRenataStandardSelected(!renataStandardSelected);
        setRenataGratisSelected(false);
        setRenataCouponSelected(false);
        setRenataPremiumSelected(false);
        setDiscountCode(undefined)

        break;
      case "premium":
        setPaymentType("Premium");
        setRenataPremiumSelected(!renataPremiumSelected);
        setRenataCouponSelected(false);
        setRenataGratisSelected(false);
        setRenataStandardSelected(false);
        setDiscountCode(undefined)

        break;
      case "coupon":
        setPaymentType("Coupon");
        setRenataCouponSelected(!renataCouponSelected);
        setRenataGratisSelected(false);
        setRenataPremiumSelected(false);
        setRenataStandardSelected(false);
        break;
      default:
        break;
    }
  };


  return (
    <div className='flex-column align-items-center py-2 px-5'>
      <div className='row justify-content-center'>
        <div className='col-sm-12 col-md-5 col-lg-4 text-center'>
          <img className="w-50 mt-5" src={Logo} alt="Renata Logo" />
        </div>
      </div>

      <ConfirmationModal show={isModalOpen} type='Cupón' titleText='inválido'  onHide={() => setIsModalOpen(false)} text={modalMessage} />

      <div className='row justify-content-center'>
        <div className={`col-sm-12 ${step === 3 ? 'col-lg-12 col-lg-10' : 'col-md-4 col-lg-3'} p-login pt-5`}>
          {step === 1 ? (<h1 className='fw-bolder text-dark text-center mb-3 animate__animated animate__fadeInUp'>Registra tu usuario</h1>) : (<h1 className='fw-bolder text-dark text-center mb-4 animate__animated animate__fadeInUp'>Registra tu negocio</h1>)}
          <form noValidate className='w-100 p-0 animate__animated animate__fadeInUp' onSubmit={handleSubmit}>
            {step === 1 && (
              <div className='animate__animated animate__fadeIn'>
                <div className="mb-3">
                  <input
                    id='name'
                    type="text"
                    className={`form-control bg-light centered-placeholder centered-input-container d-flex ${usernameError ? 'is-invalid' : ''}`}
                    placeholder='Nombre completo'
                    onChange={handleUsernameChange}
                    value={username}
                    autoComplete="off"
                  />
                  {usernameError && <div className="invalid-feedback">Por favor, introduce un nombre.</div>}
                </div>
                <div className="mb-3">
                  <input
                    id='email'
                    type="email"
                    className={`form-control bg-light centered-placeholder centered-input-container d-flex ${emailError ? 'is-invalid' : ''}`}
                    placeholder='Correo electrónico'
                    onChange={handleEmailChange}
                    value={email}
                    autoComplete="off"
                  />
                  {emailError && <div className="invalid-feedback">Por favor, introduce un correo electrónico válido.</div>}
                </div>
                <div className="mb-3">
                  <input
                    id='tel'
                    type="tel"
                    className={`form-control bg-light centered-placeholder centered-input-container d-flex ${phoneError ? 'is-invalid' : ''}`}
                    placeholder='Número de teléfono'
                    onChange={handlePhoneChange}
                    value={phoneNumber}
                    autoComplete="off"
                  />
                  {phoneError && <div className="invalid-feedback">Por favor, introduce un teléfono válido.</div>}
                </div>
                <div className="mb-3">
                  {isValidNumber && (
                    <input
                      id='password'
                      type="password"
                      className={`form-control bg-light centered-placeholder centered-input-container d-flex ${passwordError ? 'is-invalid' : ''}`}
                      placeholder='Contraseña'
                      onChange={handlePasswordChange}
                      autoComplete="off"
                    />
                  )}
                  {passwordError && <div className="invalid-feedback">La contraseña debe tener al menos 5 caracteres.</div>}
                </div>

                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="terms"
                    checked={isTermsChecked}
                    onChange={handleTermsChange}
                  />
                  <label className="form-check-label" htmlFor="terms">
                    Acepto los <a href="https://goji.mx/terms" target="_blank" rel="noopener noreferrer">Términos y Condiciones</a>
                  </label>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="privacy"
                    checked={isPrivacyChecked}
                    onChange={handlePrivacyChange}
                  />
                  <label className="form-check-label" htmlFor="privacy">
                    Acepto haber leído el <a href="https://goji.mx/privacy" target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a>
                  </label>
                </div>

                <button type="button" className="btn btn-dark w-100" onClick={handleNextStep}>
                  Siguiente
                </button>
              </div>
            )}
            {step === 2 && (
              <div className='animate__animated animate__fadeIn'>
                <div className="mb-3">
                  <input
                    id='business'
                    type="text"
                    className={`form-control bg-light centered-placeholder centered-input-container d-flex ${businessNameError ? 'is-invalid' : ''}`}
                    placeholder='Nombre del negocio'
                    onChange={handleBusinessNameChange}
                    value={businessName}
                    autoComplete="off"
                  />
                  {businessNameError && <div className="invalid-feedback">Por favor, introduce un nombre de negocio.</div>}
                </div>
                <div className="mb-3">
                  <ReactSelect
                    closeMenuOnSelect={true}
                    placeholder={'Caso de uso'}
                    components={animatedComponents}
                    isMulti={true}
                    onChange={handleCaseUseChange}
                    options={caseUseOptions}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                  {usecaseError && <div className="invalid-feedback d-block">Por favor, selecciona una opción.</div>}
                </div>
                <div className="mb-3"></div>
                <button type="button" className="btn btn-dark w-100 mt-4" disabled={isLoading} onClick={handleNextStep}>
                  {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Continuar'}
                </button>
                <div className="mb-3"></div>
                <button type="button" className="btn btn-outline-dark bg-transparent w-100" onClick={handlePrevStep}>
                  Regresar
                </button>
              </div>
            )}

            {step === 3 && (
              <div className='animate__animated animate__fadeIn'>
                <div className="mb-1 mx-auto text-center">
                  <div className="row justify-content-center pt-2">

                    <div
                      className={`px-0 col-lg-3 col-md-8 mb-4 pricing card mb-4 bg-white border-radius-custom-3 me-3 ${renataStandardSelected ? 'animate__animated animate__swing border border-1 border-dark shadow-payment' : ''}`}
                      onClick={() => toggleRenataSelection("Standard")}
                      style={{ cursor: 'pointer' }}
                    >

                      <div className={`card-header border-radius-custom-3 w-100 ${renataStandardSelected ? 'bg-dark' : 'bg-light'}`}>
                        <h4 className={`my-0 fw-bold p-4 w-100 ${renataStandardSelected ? 'text-white' : 'text-dark'}`}>🙋🏻‍♀️ Standard</h4>
                      </div>
                      <div className="card-body pt-4 pb-5 border-radius-custom-3">
                        <h1 className="card-title pricing-card-title text-start ps-3">$990<small className="text-muted ms-1">/mes*</small></h1>
                        <ul className="list-unstyled mt-3 mb-4 text-start ps-3">
                          <li>• 3 Números de WhatsApp</li>
                          <li>• 3 Asistentes Chat</li>
                          <li>• 3 Accesos Renata-APP</li>
                          <li>• Contesta 24/7</li>
                          <li>• 55,000 puntos</li>
                        </ul>
                      </div>
                    </div>



                    <div
                      className={`px-0 col-lg-3 col-md-8 mb-4 pricing card mb-4 bg-white border-radius-custom-3 me-3 ${renataPremiumSelected ? 'animate__animated animate__swing border border-1 border-dark shadow-payment' : ''}`}
                      onClick={() => toggleRenataSelection("premium")}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className={`card-header border-radius-custom-3 w-100 ${renataPremiumSelected ? 'bg-dark' : 'bg-light'}`}>
                        <h4 className={`my-0 fw-bold p-4 w-100 ${renataPremiumSelected ? 'text-white' : 'text-dark'}`}>⭐ Premium</h4>
                      </div>
                      <div className="card-body pt-4 pb-5 border-radius-custom-3">
                        <h1 className="card-title pricing-card-title text-start ps-3">$2990<small className="text-muted ms-1">/mes*</small></h1>
                        <ul className="list-unstyled mt-3 mb-4 text-start ps-3">
                          <li>• 6 Números de WhatsApp</li>
                          <li>• 6 Asistentes Chat y Llamada</li>
                          <li>• 6 Accesos Renata-APP</li>
                          <li>• Contesta 24/7</li>
                          <li>• Soporte Técnico</li>
                          <li>• 180,000 puntos</li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className={`px-0 col-lg-3 col-md-8 mb-4 pricing card mb-4 bg-white border-radius-custom-3 me-3 ${renataCouponSelected ? 'animate__animated animate__swing border border-1 border-dark shadow-payment' : ''}`}
                      onClick={() => toggleRenataSelection("coupon")}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className={`card-header border-radius-custom-3 w-100 ${renataCouponSelected ? 'bg-dark' : 'bg-light'}`}>
                        <h4 className={`my-0 fw-bold p-4 w-100 ${renataCouponSelected ? 'text-white' : 'text-dark'}`}>🎟️ Tengo un cupón</h4>

                      </div>
                      <div className="card-body pt-4 pb-5 border-radius-custom-3">
                        <ul className="list-unstyled mb-4 text-start ps-3 mt-auto">
                          <li>• Si usted tiene un cupón, seleccione esta opción</li>
                        </ul>
                      </div>
                    </div>




                  </div>

                </div>
                {(renataStandardSelected || renataPremiumSelected || renataGratisSelected || renataCouponSelected) && (
                  <div className='col-md-3 col-sm-8 mx-auto text-center'>
                    <button type="submit" className="btn btn-dark w-100 mt-4" disabled={isLoading}>
                      {isLoading ? <img className='loading' src={loadingGif} alt="Cargando..." /> : 'Proceder al Pago'}
                      <i className="bi bi-box-arrow-up-right ms-2"></i>
                    </button>
                    <div className="mb-3"></div>
                    <button type="button" className="btn btn-outline-dark bg-transparent w-100" onClick={handlePrevStep}>
                      Regresar
                    </button>
                  </div>
                )}
              </div>
            )}

            <div className='mt-2 text-center'>
              <span className='fs-6'>¿Ya tienes una cuenta? <Link to="/login" className="text-primary">Inicia sesión</Link></span>
            </div>
          </form>

          <DiscountCodeModal
            show={showDiscountModal}
            handleClose={() => setShowDiscountModal(false)}
            handleApplyCoupon={handleApplyCoupon}
            codeError={codeError}
            errorMessage={errorMessage}
            setCodeError={setCodeError}
            setErrorMessage={setErrorMessage}
            discountCode={discountCode}
            setDiscountCode={setDiscountCode}
            isLoading={loadingCoupon}
          />
        </div>
      </div>
    </div>
  );
}

export default Register;
