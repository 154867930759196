import { useState } from 'react';
import '../../css/main.css';
import Menu from '../Menu/Menu';
import TopBar from '../../TopBar';
import { useNavigate } from 'react-router-dom';
import SupportChatModal from './SupportChatModal';
import BannerNoRenataPoints from './AlertRenataPoints';

function Home() {
  const gojimx_username = localStorage.getItem('gojimx_username');
  const gojimx_payment_type = localStorage.getItem('gojimx_payment_type');


  const [supportChatOpen, setSupportChatOpen] = useState(false);
  const handleSupportChatOpen = () => setSupportChatOpen(true);
  const handleSupportChatClose = () => setSupportChatOpen(false);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const navigate = useNavigate();

  //Navigate only if url is different
  const redirect = (pRoute: string) => {
    const currentUrl = window.location.pathname;
    const targetRoute = '/' + pRoute;
    if (currentUrl !== targetRoute) {
      setTimeout(() => {
        navigate(targetRoute);
      }, 300); //let animation be seen      
    }
    else {
      handleClose();
    }
  };

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow} />
      </div>
      <BannerNoRenataPoints />
      <div className='row justify-content-center'>
        <div className='col-lg-6 col-md-8 col-sm-12 py-4'>
          <div className='row px-3 animate__animated animate__fadeIn'>
            <h2 className='text-secondary mb-0 '>Bienvenid@</h2>
            <h1 className='fw-bold text-dark mt-0'>{gojimx_username}</h1>
          </div>

          <div className='w-100 my-4'>
            <div className='border mb-2 animate__animated animate__fadeIn'></div>
          </div>

          <div className='row px-3 animate__animated animate__fadeIn'>
            <div className='w-100'>
              <h3 className='bg-custom-1 px-5 py-4 text-white mb-3' onClick={() => redirect('myschedule')}>📅 Mi Agenda</h3>
              <h3 className='bg-custom-2 px-5 py-4 text-white mb-3' onClick={() => redirect('myconversations')}>✉️ Mis Conversaciones</h3>
              <h3 className='bg-custom-4 px-5 py-4 text-white mb-3' onClick={() => redirect('myexpenses')}>💰 Consumo</h3>
              <h3 className='bg-custom-3 px-5 py-4 text-white mb-3' onClick={() => redirect('statistics')}>📊 Estadísticas</h3>
              <h3 className='bg-custom-3 px-5 py-4 text-white mb-3' onClick={() => redirect('settings')}>⚙️ Configuración</h3>
            </div>
          </div>

        </div>
      </div>

      <Menu show={show} handleClose={handleClose} />
      {gojimx_payment_type === 'Premium' && (
        <>
          <div className="support-bubble animate__animated animate__fadeIn" onClick={handleSupportChatOpen}>
            💬
          </div>
          <SupportChatModal show={supportChatOpen} handleClose={handleSupportChatClose} />
        </>
      )}
    </div>
  );
}

export default Home;
