import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/home.css';
import renata_img from '../../img/renata.png';
import user_img from '../../img/user.png';
import axios from 'axios';
import config from '../../config/config';

interface Message {
    sender: 'user' | 'support';
    text: string;
}

interface SupportChatModalProps {
    show: boolean;
    handleClose: () => void;
    predefinedQuestion?: string;  // Nuevo prop para pasar la pregunta
}

const SupportChatModal: React.FC<SupportChatModalProps> = ({ show, handleClose, predefinedQuestion }) => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState<string>('');
    const chatBodyRef = useRef<HTMLDivElement>(null);
    const [isQuestionAsked, setIsQuestionAsked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [messages]);

    const fetchSupportResponse = async (userMessage: string): Promise<string> => {
        try {
            const response = await axios.post(`${config.assistantURL}/questionRenataSupport`, {
                message: userMessage,
            });
            return response.data.message;
        } catch (error) {
            console.error('Error fetching support response:', error);
            return 'Lo siento, algo salió mal. Por favor intenta nuevamente.';
        }
    };

    const handleSendMessage = async () => {
        if (inputMessage.trim() !== '') {
            const userMessage: Message = { sender: 'user', text: inputMessage };
            setMessages([...messages, userMessage]);
            setInputMessage('');
            setIsLoading(true);
            setIsQuestionAsked(true);

            try {
                const supportResponse = await fetchSupportResponse(userMessage.text);
                const supportMessage: Message = { sender: 'support', text: supportResponse };
                setMessages(prevMessages => [...prevMessages, supportMessage]);
            } catch (error) {
                console.error('Error during message handling:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleRestartConversation = () => {
        const newMessage: Message = { sender: 'support', text: '¿En qué te puedo ayudar?' };
        setMessages([newMessage]);
        setIsLoading(false);
        setIsQuestionAsked(false);
    };

    useEffect(() => {
        if (show) {
            const newMessage: Message = { sender: 'support', text: '¿En qué te puedo ayudar?' };
            setMessages([newMessage]);

            // Si se proporciona una pregunta predeterminada, la configuramos
            if (predefinedQuestion && !isQuestionAsked) {
                setInputMessage(predefinedQuestion);
            }
        }
    }, [show, predefinedQuestion]);

    const handleCloseModal = () => {
        handleClose();
        setTimeout(() => {
            setIsQuestionAsked(false);
            setIsLoading(false);
            setMessages([]);
            setInputMessage('');
        }, 300);
    };

    return (
        <Modal show={show} onHide={handleCloseModal} centered>
            <Modal.Header closeButton>
                <Modal.Title>Soporte técnico</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="support-chat-body" ref={chatBodyRef}>
                    {messages.map((message, index) => (
                        <div key={index} className={`d-flex align-items-start mb-4  animate__animated animate__fadeIn ${message.sender === 'user' ? 'flex-row-reverse' : ''}`}>
                            <img
                                src={message.sender === 'user' ? user_img : renata_img}
                                className='rounded-circle chat-avatar'
                                alt={message.sender === 'user' ? "User" : "Renata"}
                            />
                            <div className={`message-bubble ${message.sender}`}>
                                {message.text}
                            </div>
                        </div>
                    ))}
                    {isLoading && (
                        <div className="d-flex justify-content-center mt-3">
                            <div className="loader"></div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {!isQuestionAsked ? (
                    <div className='d-flex w-100'>
                        <input
                            type="text"
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                            placeholder="Escribe tu mensaje..."
                            className="form-control me-2"
                        />
                        <button onClick={handleSendMessage} className="btn btn-secondary">
                            <i className="bi bi-send-fill"></i>
                        </button>
                    </div>
                ) : (
                    <button onClick={handleRestartConversation} className="btn btn-dark w-100">
                        Hacer otra pregunta
                    </button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default SupportChatModal;
