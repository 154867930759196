import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import handleLogout from './functions/functions';
import QRCode from 'react-qr-code';
import loadingGif from './img/loading.gif';
import loadingGif2 from './img/LOADER RENATA.gif';
import config from './config/config';

const ScanQR: React.FC = () => {
    const [qrCode, setQrCode] = useState<string>('');
    const [error, setError] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(true);

    const gojimx_token = localStorage.getItem('gojimx_token');
    const business_id = localStorage.getItem('gojimx_businessId');
    const [business, setBusiness] = useState();

    const fetchBaileysPortAndStatus = async () => {
        try {
            const response = await axios.get(`${config.backendURL}/get_business/${business_id}`, {
                headers: {
                    Authorization: `Bearer ${gojimx_token}`
                }
            });
            const { baileys_status, baileys_port } = response.data[0];

            if (!baileys_port) {
                const createBaileysResponse = await axios.post(`${config.apiBaileys}/createRenataBaileys`, {
                    business_id: business_id
                }, {
                    headers: {
                        Authorization: `Bearer ${gojimx_token}`
                    }
                });

                localStorage.setItem('gojimx_baileys_status', createBaileysResponse.data.baileys_status);
                localStorage.setItem('gojimx_baileys_port', createBaileysResponse.data.baileys_port);
                return createBaileysResponse.data.baileys_port;
            }

            localStorage.setItem('gojimx_baileys_status', baileys_status);
            localStorage.setItem('gojimx_baileys_port', baileys_port);
            return baileys_port;
        } catch (error) {
            console.error('Error fetching Baileys status and port:', error);
            setError(true);
        }
    };

    useEffect(() => {


        const fetchQrCode = async () => {
            const baileys_port = await fetchBaileysPortAndStatus();

            if (!baileys_port || !gojimx_token) {
                console.error('Missing baileys_port or gojimx_token');

                setTimeout(() => {
                    window.location.reload();
                }, 2000);

                setError(true);
                return;
            }

            try {
                const response = await axios.get(`${config.apiBaileys}/${baileys_port}/getQR`, {
                    headers: {
                        Authorization: `Bearer ${gojimx_token}`
                    }
                });
                setQrCode(response.data);
            } catch (error) {
                console.error('Error fetching QR code:', error);

                setTimeout(() => {
                    window.location.reload();
                }, 2000);

                setError(true);
            }
        };

        const intervalId = setInterval(fetchQrCode, 50000); // Fetch QR code every 50 seconds
        fetchQrCode(); // Initial fetch

        return () => clearInterval(intervalId);
    }, [gojimx_token]);

    useEffect(() => {
        const fetchBusinessStatus = async () => {
            if (!business_id || !gojimx_token) {
                console.error('Missing business_id or gojimx_token');
                return;
            }

            try {
                const response = await axios.get(`${config.backendURL}/get_business/${business_id}`, {
                    headers: {
                        Authorization: `Bearer ${gojimx_token}`
                    }
                });
                const { baileys_status } = response.data[0];
                setBusiness(response.data[0]);
                if (baileys_status) {
                    localStorage.setItem('gojimx_baileys_status', 'true');
                    window.location.replace('/home');
                }
            } catch (error) {
                console.error('Error fetching business status:', error);
            }
        };

        const intervalId = setInterval(fetchBusinessStatus, 5000);
        return () => clearInterval(intervalId);
    }, [business_id, gojimx_token]);

    return (
        <>
            <Modal show={showModal} size="lg" centered>
                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                        {error ? (
                            <>
                           <div className='mb-5'>
                        <img
                            src={loadingGif2}
                            alt="Cargando..."
                            style={{ width: '100px', height: '100px', borderRadius: 20 }} // Adjusted size
                        />
                    </div>

                            <h3 style={{ fontWeight: 'bold' }}>Por favor, espere un momento...</h3>

                            </>
                        ) : (
                            qrCode.length > 0 ? (
                                <div>
                                    <div>
                                        <h1 style={{ fontWeight: 'bold' }}>Te damos la bienvenida a Renata! 🎉</h1>
                                        <div className='my-5' style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div className='bg-secondary p-3 rounded'>
                                                <div className='bg-light p-3 rounded'>
                                                    <QRCode value={qrCode} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='my-5' style={{ textAlign: 'center' }}>
                                            <h4>Para comenzar a automatizar tu WhatsApp, por<br></br>favor escanea el QR</h4>
                                        </div>
                                        <div>
                                            <h5><span className='me-3 ms-4'>1. </span>Abre Whatsapp en tu teléfono</h5>
                                            <h5><span className='me-3 ms-4'>2. </span>En la configuración, entra a "Dispositivos Enlazados"</h5>
                                            <h5><span className='me-3 ms-4'>3. </span>Con la cámara del teléfono, captura el código en pantalla</h5>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='mb-5'>
                                    <h1 style={{ fontWeight: 'bold' }}><img className='loading' src={loadingGif} alt="Cargando..." /></h1>
                                </div>
                            )
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="text-danger text-start" variant="light" onClick={handleLogout}>Cerrar Sesión</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ScanQR;
