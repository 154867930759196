import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface AddCategoriaModalProps {
  showAddCategoriaModal: boolean;
  handleCloseAddCategoriaModal: () => void;
  titleModalAdd: string;
  newOptionName: string;
  handleNewOptionNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  newOptionDescription: string;
  handleNewOptionDescriptionChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  newOptionIcon: string;
  handleNewOptionIconChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleDeleteOption: (optionName: string) => void;
  editOptionInDefaultCategory: () => void;
  addNewOptionToDefaultCategory: () => void;
  isLoading: boolean;
  loadingGif: string;
}

const AddCategoriaModal: React.FC<AddCategoriaModalProps> = ({
  showAddCategoriaModal,
  handleCloseAddCategoriaModal,
  titleModalAdd,
  newOptionName,
  handleNewOptionNameChange,
  newOptionDescription,
  handleNewOptionDescriptionChange,
  newOptionIcon,
  handleNewOptionIconChange,
  handleDeleteOption,
  editOptionInDefaultCategory,
  addNewOptionToDefaultCategory,
  isLoading,
  loadingGif,
}) => {
  return (
    <Modal show={showAddCategoriaModal} onHide={handleCloseAddCategoriaModal}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">{titleModalAdd}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Option Name Input */}
        <h5 className="fw-bold text-dark mb-2">Nombre de la Opción</h5>
        <p className="text-dark mb-2">Introduce el nombre de la nueva opción</p>
        <div>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Ej. Atención Urgente"
            value={newOptionName}
            onChange={handleNewOptionNameChange}
          />
        </div>

        {/* Option Description Input */}
        <h5 className="fw-bold text-dark mb-2">Descripción</h5>
        <p className="text-dark mb-2">Detalla lo que representa esta opción</p>
        <div>
          <textarea
            className="form-control mb-3"
            placeholder="Ej. Consultas que requieren atención inmediata..."
            rows={3}
            value={newOptionDescription}
            onChange={handleNewOptionDescriptionChange}
          ></textarea>
        </div>

        {/* Option Icon Input */}
        <h5 className="fw-bold text-dark mb-2">Ícono</h5>
        <p className="text-dark mb-2">Elige un ícono para representar visualmente esta opción</p>
        <div className="d-flex">
          <select
            className="form-select mb-3"
            aria-label="Default select example"
            value={newOptionIcon}
            onChange={handleNewOptionIconChange}
          >
            <option value="">Selecciona</option>
            <option value="🔴">🔴</option>
            <option value="🟡">🟡</option>
            <option value="🟢">🟢</option>
            <option value="🔵">🔵</option>
            <option value="🚨">🚨</option>
            <option value="💯">💯</option>
            <option value="👍">👍</option>
            <option value="👎">👎</option>
            <option value="🔥">🔥</option>
            <option value="😄">😄</option>
            <option value="😞">😞</option>
            <option value="😐">😐</option>
            <option value="😠">😠</option>
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {titleModalAdd === 'Editar Categoría' && (
          <div className="w-50">
            <div className="col-1">
              <Button
                className="btn btn-lg bg-transparent border-0"
                onClick={() => handleDeleteOption(newOptionName)}
              >
                <i className="bi bi-trash3 text-danger"></i>
              </Button>
            </div>
          </div>
        )}
        <Button variant="light" onClick={handleCloseAddCategoriaModal}>
          Cancelar
        </Button>

        {titleModalAdd === 'Editar Categoría' && (
          <Button
            variant="dark px-3 w-25"
            onClick={editOptionInDefaultCategory}
            disabled={isLoading}
          >
            {isLoading ? (
              <img className="loading" src={loadingGif} alt="Cargando..." />
            ) : (
              'Actualizar'
            )}
          </Button>
        )}

        {titleModalAdd === 'Agregar Categoría' && (
          <Button
            variant="dark px-3 w-25"
            onClick={addNewOptionToDefaultCategory}
            disabled={isLoading}
          >
            {isLoading ? (
              <img className="loading" src={loadingGif} alt="Cargando..." />
            ) : (
              'Guardar'
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddCategoriaModal;