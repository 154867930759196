import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../../config/config';
import { DateCalendar } from '@mui/x-date-pickers';
import { Button, Modal } from 'react-bootstrap';
//import  DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';


interface AccordionItemProps {
  title: string;
  children: React.ReactNode;
  eventKey: string;
  activeEventKey?: string | null; // Marked as optional
  setActiveEventKey?: (eventKey: string | null) => void; // Marked as optional
}

interface Option {
  option_name: string,
  description: string,
  icon: string
}

interface Category {
  category_name: string,
  options: Array<Option>;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  children,
  eventKey,
  activeEventKey,
  setActiveEventKey
}) => {
  const isOpen = eventKey === activeEventKey;

  const handleClick = () => {
    // Here we check if setActiveEventKey is defined before calling it
    if (setActiveEventKey) {
      setActiveEventKey(isOpen ? null : eventKey);
    }
  };

  return (
    <div className="accordion-item border-0 px-0 mx-0">
      <h2 className="accordion-header" id={`heading${eventKey}`}>
        <button
          className={`accordion-button ${!isOpen && 'collapsed'} bg-light text-dark rounded`}
          type="button"
          onClick={handleClick} // This safely calls setActiveEventKey only if it's defined
        >
          <h5 className='fw-bold my-0'>{title}</h5>
        </button>
      </h2>
      <div id={`collapse${eventKey}`} className={`accordion-collapse collapse ${isOpen && 'show'}`}>
        <div className="accordion-body animate__animated animate__fadeIn px-0">{children}</div>
      </div>
    </div>
  );
};


interface AccordionProps {
  defaultActiveKey: string | null;
  children: React.ReactElement<AccordionItemProps>[] | React.ReactElement<AccordionItemProps>;
}

const Accordion: React.FC<AccordionProps> = ({ defaultActiveKey, children }) => {
  const [activeEventKey, setActiveEventKey] = useState<string | null>(defaultActiveKey);

  return (
    <div className="accordion" id="accordionExample">
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          activeEventKey: activeEventKey,
          setActiveEventKey: setActiveEventKey,
        })
      )}
    </div>
  );
};

const AdvancedFilters: React.FC<{
  selectedFecha: string | null,
  setSelectedFecha: React.Dispatch<React.SetStateAction<string | null>>,
  selectedEstado: string | null,
  setSelectedEstado: React.Dispatch<React.SetStateAction<string | null>>,
  selectedRenataState: string | null,
  setSelectedRenataState: React.Dispatch<React.SetStateAction<string | null>>,
  selectedCategory: string | null,
  setSelectedCategory: React.Dispatch<React.SetStateAction<string | null>>,
  selectedRange: [Date | null, Date | null],
  setSelectedRange: React.Dispatch<React.SetStateAction<[Date | null, Date | null]>>,
}> = ({
  selectedFecha,
  setSelectedFecha,
  selectedEstado,
  setSelectedEstado,
  selectedRenataState,
  setSelectedRenataState,
  selectedCategory,
  setSelectedCategory,
  selectedRange,
  setSelectedRange,
}) => {
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const [showRangeModal, setShowRangeModal] = useState(false);


    // Function to update the selected "fecha"
    const handleFechaClick = (fecha: string) => {
      setSelectedFecha(selectedFecha === fecha ? null : fecha);
    };

    const handleOpenRangeModal = () => {
      setShowRangeModal(true);
    };

    const handleCloseRangeModal = () => {
      setShowRangeModal(false);
    };

    const handleApplyRange = () => {
      if (startDate && endDate) {
        setSelectedRange([startDate, endDate]);
        setSelectedFecha('rango'); // Opcional: puedes usar 'rango' para identificar que se seleccionó un rango personalizado
      }
      handleCloseRangeModal();
    };


    const handleEstadoClick = (parameter: string) => {
      setSelectedEstado(selectedEstado === parameter ? null : parameter);
    };

    const handleRenataState = (parameter: string) => {
      setSelectedRenataState(selectedRenataState === parameter ? null : parameter);
    };

    const handleCategoryClick = (parameter: string) => {
      setSelectedCategory(selectedCategory === parameter ? null : parameter);
    };

    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const gojimx_token = localStorage.getItem('gojimx_token');
    const [categorias, setCategorias] = useState<Category[]>([]);

    const fetchCategories = () => {
      axios.get(`${config.backendURL}/get_category/${gojimx_businessId}`, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`
        }
      })
        .then(response => {
          setCategorias(response.data[0]);
        })
        .catch(error => {
          console.error('There was an error fetching the categories:', error);
        });
    };

    useEffect(() => {
      fetchCategories();
    }, []);

    return (
      <div>
        <Accordion defaultActiveKey="0">
          <AccordionItem eventKey="1" title="Filtros avanzados">
            <div className='p-3 border-start border-2 mb-3'>
              <h5 className='fw-bold mb-3'>Fechas</h5>
              <button className={`btn ${selectedFecha === 'hoy' ? 'btn-dark' : 'btn-outline-dark'} me-3 mb-3`} onClick={() => handleFechaClick('hoy')}>Hoy</button>
              <button className={`btn ${selectedFecha === 'esta semana' ? 'btn-dark' : 'btn-outline-dark'} me-3 mb-3`} onClick={() => handleFechaClick('esta semana')}>Esta semana</button>
              <button className={`btn ${selectedFecha === 'este mes' ? 'btn-dark' : 'btn-outline-dark'} me-3 mb-3`} onClick={() => handleFechaClick('este mes')}>Este mes</button>
              <button className={`btn ${selectedFecha === 'este año' ? 'btn-dark' : 'btn-outline-dark'} me-3 mb-3`} onClick={() => handleFechaClick('este año')}>Este año</button>
              <button className={`btn ${selectedFecha === 'rango' ? 'btn-dark' : 'btn-outline-dark'} me-3 mb-3`} onClick={handleOpenRangeModal}>Rango</button>
            </div>


            <div className='p-3 border-start border-2 mb-3'>
              <h5 className='fw-bold mb-3'>Estados de conversación</h5>
              <button className={`btn ${selectedEstado === 'Activas' ? 'btn-dark' : 'btn-outline-dark'} me-3 mb-3`} onClick={() => handleEstadoClick('Activas')}>🟢 Conversaciones Activas</button>
              <button className={`btn ${selectedEstado === 'Inactivas' ? 'btn-dark' : 'btn-outline-dark'} me-3 mb-3`} onClick={() => handleEstadoClick('Inactivas')}>⚪ Conversaciones Inactivas</button>

              <br />
              <button className={`btn ${selectedRenataState === 'renata_sleep' ? 'btn-dark' : 'btn-outline-dark'} me-3 mb-3`} onClick={() => handleRenataState('renata_sleep')}>💤 Renata Durmiendo</button>
              <button className={`btn ${selectedRenataState === 'renata_on' ? 'btn-dark' : 'btn-outline-dark'} me-3 mb-3`} onClick={() => handleRenataState('renata_on')}>👩🏻‍💻 Renata Trabajando</button>

            </div>

            <div className='p-3 border-start border-2'>
              <h5 className='fw-bold mb-1'>Categorías</h5>
              <p className='mb-3'>Puedes gestionar las categorías en la <a href='/settings' className='text-primary'>configuración</a></p>
              {categorias.filter(categoria => categoria.category_name === "default").map((categoria, index) => (
                <div key={index}>
                  {categoria.options.map((option, optionIndex) => (
                    <button key={optionIndex} className={`btn ${selectedCategory === option.option_name ? 'btn-dark' : 'btn-outline-dark'} me-3 mb-3`} onClick={() => { handleCategoryClick(option.option_name) }}>
                      {option.icon} {option.option_name}
                    </button>
                  ))}
                </div>
              ))}
            </div>

            <Modal show={showRangeModal} onHide={handleCloseRangeModal} size='lg' centered>
              <Modal.Header closeButton>
                <Modal.Title>Seleccionar Rango de Fechas</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='text-center me-3'>
                    <label className='form-label'>Fecha de Inicio</label>
                    <DateCalendar
                      value={startDate}
                      onChange={setStartDate}
                    />
                  </div>
                  <div className='text-center'>
                    <label className='form-label'>Fecha de Fin</label>
                    <DateCalendar
                      value={endDate}
                      onChange={setEndDate}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="light" onClick={handleCloseRangeModal}>
                  Cerrar
                </Button>
                <Button variant="dark" onClick={handleApplyRange}>
                  Aplicar
                </Button>
              </Modal.Footer>
            </Modal>


          </AccordionItem>
        </Accordion>
      </div>
    );
  };

export default AdvancedFilters;
