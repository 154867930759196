import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import config from '../../config/config';
import loadingGif from '../../img/loading.gif';

interface User {
    id: number;
    username: string;
    phoneNumber: string;
    email: string;
}

interface UsersEditModalProps {
    show: boolean;
    onHide: () => void;
    user: User | null;
    onSave: () => void;
}

const UsersEditModal: React.FC<UsersEditModalProps> = ({ show, user, onHide, onSave }) => {
    const gojimx_token = localStorage.getItem('gojimx_token');
    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const [formData, setFormData] = useState<User | null>(null);
    const [loading, setLoading] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    useEffect(() => {
        if (user) {
            setFormData(user);
        }
    }, [user]);

    const handleClose  = () => {
        setPhoneError(false);
        setEmailError(false);
        setUsernameError(false);
        onHide();
    }

    const formatPhoneNumber = (value: string) => {
        value = value.replace(/\D/g, '');
        const prefix = value.slice(0, 2);
        if (['55', '56', '33', '81'].includes(prefix)) {
            if (value.length > 2 && value.length <= 6) {
                value = value.slice(0, 2) + ' ' + value.slice(2);
            } else if (value.length > 6) {
                value = value.slice(0, 2) + ' ' + value.slice(2, 6) + ' ' + value.slice(6, 10);
            }
        } else {
            if (value.length > 3 && value.length <= 6) {
                value = value.slice(0, 3) + ' ' + value.slice(3);
            } else if (value.length > 6) {
                value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6, 10);
            }
        }
        return value;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof User) => {
        if (formData) {
            let value = e.target.value;
            if (field === 'phoneNumber') {
                value = formatPhoneNumber(value);
                setPhoneError(value.length !== 12);
            }
            if (field === 'username') {
                setUsernameError(value.trim() === '');
            }
            if (field === 'email') {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                setEmailError(!emailRegex.test(value));
            }
            setFormData({ ...formData, [field]: value });
        }
    };

    const handleSave = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!formData?.username || !formData?.email || !formData?.phoneNumber) {
            setUsernameError(!formData?.username);
            setEmailError(!formData?.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email));
            setPhoneError(formData?.phoneNumber.length !== 12);
            return;
        }
        if (!usernameError && !emailError && !phoneError) {
            try {
                setLoading(true);
                const response = await axios.post(`${config.backendURL}/updateSecondaryUser`, {
                    businessId: gojimx_businessId,
                    id: formData.id,
                    username: formData.username,
                    phoneNumber: formData.phoneNumber,
                    email: formData.email
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${gojimx_token}`
                    }
                });
                if (response.status === 200) {
                    onSave();
                    onHide();
                }
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (error.response) {
                        console.log(error.response.data.message);
                    } else if (error.request) {
                        console.log('Request made but no response received:', error.request);
                    } else {
                        console.log('Error message:', error.message);
                    }
                } else {
                    console.error('An unknown error occurred:', error);
                }
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Editar Usuario</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSave}>
                <Modal.Body>
                    <Form.Group controlId="formName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData?.username || ''}
                            onChange={(e) => handleChange(e, 'username')}
                            isInvalid={usernameError}
                        />
                        {usernameError && <Form.Control.Feedback type="invalid">Por favor, introduce un nombre.</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Correo</Form.Label>
                        <Form.Control
                            type="email"
                            value={formData?.email || ''}
                            onChange={(e) => handleChange(e, 'email')}
                            isInvalid={emailError}
                        />
                        {emailError && <Form.Control.Feedback type="invalid">Por favor, introduce un correo electrónico válido.</Form.Control.Feedback>}
                    </Form.Group>
                    <Form.Group controlId="formPhone">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control
                            type="text"
                            value={formData?.phoneNumber || ''}
                            onChange={(e) => handleChange(e, 'phoneNumber')}
                            isInvalid={phoneError}
                        />
                        {phoneError && <Form.Control.Feedback type="invalid">Por favor, introduce un número de teléfono válido (12 caracteres).</Form.Control.Feedback>}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={onHide}>
                        Cancelar
                    </Button>
                    <Button variant="dark" type="button" onClick={handleSave}>
                        {loading ? (
                            <div className='ms-auto'>
                                <img className='loading' src={loadingGif} alt="Cargando..." />
                            </div>
                        ) : (
                            'Guardar cambios'
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UsersEditModal;