import React, { useEffect, useState } from "react";
import { fetchBusinessStatus } from "../../functions/functions";
import StoreModal from "../Modals/StoreModal";

const BannerNoRenataPoints = () => {
  const [renataPoints, setRenataPoints] = useState(null);
  const [showStoreModal, setShowStoreModal] = useState<boolean>(false);

  useEffect(() => {
    const getBusinessStatus = async () => {
      try {
        const data = await fetchBusinessStatus();
        setRenataPoints(data.renata_points);
      } catch (error) {}
    };

    getBusinessStatus();
  }, []);

  const handleStoreModalShow = () => setShowStoreModal(true);
  const handleStoreModalClose = () => setShowStoreModal(false);

  return (
    <>
      <div>
        {renataPoints != null && renataPoints <= 0 ? (
          <div className="row justify-content-center animate__animated animate__fadeIn">
            <div
              className="bg-custom-6 col-lg-6 col-md-8 col-sm-12 py-4"
              onClick={handleStoreModalShow}
            >
              <h5>
                ⚠️ Tus puntos se han agotado (Renata no puede contestar tus
                mensajes), por favor recarga más en la tienda.
              </h5>
            </div>
          </div>
        ) : null}
      </div>
      <StoreModal show={showStoreModal} handleClose={handleStoreModalClose} />
    </>
  );
};

export default BannerNoRenataPoints;
