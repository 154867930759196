import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import UsersEditModal from './UsersEditModal';
import '../../css/userModal.css';
import config from '../../config/config';

interface User {
    id: number,
    username: string,
    phoneNumber: string,
    email: string
}

interface UsersModalProps {
    show: boolean;
    onHide: () => void;
}

const UsersModal: React.FC<UsersModalProps> = ({
    show,
    onHide
}) => {
    const gojimx_token = localStorage.getItem('gojimx_token');
    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const [secondaryUsers, setSecondaryUsers] = useState<User[]>([]);
    const [editUser, setEditUser] = useState<User | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const navigate = useNavigate();
    const handleAddUser = () => {
        navigate('/create-user');
    };

    const getUsers = async () => {
        try {
            //const response = await axios.post(`${config.backendURL}/}`, {
            const response = await axios.post(`${config.backendURL}/getSecondaryUsers`, {
                businessId: gojimx_businessId
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${gojimx_token}`
                }
            });
            if (response.status === 200) {
                setSecondaryUsers(response.data);
            }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    const getError = error.response.data.message;
                    console.log(getError);

                } else if (error.request) {
                    console.log('Request made but no response received:', error.request);
                } else {
                    console.log('Error message:', error.message);
                }
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    };

    useEffect(() => {
        if (show) {
            
            getUsers();
        }
        return () => {

        };
    }, []);

    const handleDelete = async (id: number) => {
        try {
            const response = await axios.post(`${config.backendURL}/deleteSecondaryUser`, {
                businessId: gojimx_businessId,
                id : id
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${gojimx_token}`
                }
            });

            if (response.status == 200) {
                const filteredUsers = secondaryUsers.filter((user) => user.id !== id);
                setSecondaryUsers(filteredUsers);
            } else {
                console.error('Error al eliminar el usuario');
            }
        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    };


    const handleEdit = (user: User) => {
        setEditUser(user);
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setEditUser(null);
    };

    const handleCancelEdit = () => {
        setIsEditModalOpen(false);
        setEditUser(null);
        if (!show) {
          onHide();
        }
      };

    return (
        <>
            <Modal show={show && !isEditModalOpen} onHide={onHide} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className='fw-bold'>Usuarios secundarios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className='userTab'>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Correo</th>
                                <th>Teléfono</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {secondaryUsers.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phoneNumber}</td>
                                    <td className='actions'>
                                        <button className='btn' onClick={() => { handleEdit(user) }}><i className="bi bi-pencil-square"></i></button>
                                        <button className='btn' onClick={() => handleDelete(user.id)}><i className="bi bi-trash3"></i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <center>
                        <button className='btn btn-outline-dark mt-3' onClick={handleAddUser}>Agregar Usuario</button>
                    </center>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={onHide}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            <UsersEditModal
                show={isEditModalOpen}
                user={editUser}
                onHide={handleCancelEdit}
                onSave={getUsers}
            />
        </>
    );
};

export default UsersModal;