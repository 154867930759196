import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Login from './Login';
import Register from './Components/Register/Register';
import Home from './Components/Home/Home';
import MySchedule from './Components/Schedule/MySchedule';
import Settings from './Components/Settings/Settings';
import MyConversations from './Components/Conversations/MyConversations';
import Messages from './Components/Conversations/Messages';
import Statistics from './Components/Statistics/Statistics';
import ScanQR from './ScanQR';
import Payment from './Payment';
import PaymentCustom from './PaymentCustom';
import ChangePassword from './ChangePassword';
import ForgotPassword from './ForgotPasword';
import RecoverPassword from './RecoverPassword';
import MyExpenses from './Components/Expenses/MyExpenses';
import CreateUser from './Components/Settings/CreateUser';
import DeleteAccount from './Components/Menu/DeleteAccount';

function App() {

  const token = () => {
    return localStorage.getItem('gojimx_token'); // Get the token from local storage
  }

  const baileys_status = () => {
    return localStorage.getItem('gojimx_baileys_status') === 'true'; // Check if baileys status is true
  }

  const user_status = () => {
    return localStorage.getItem('gojimx_user_status') === 'idle'; // Check if user status is idle
  }

  const needs_payment = () => {
    return localStorage.getItem('gojimx_needs_payment') === 'true'; // Check if payment is needed
  }

  return (
    <div style={{ overflowX: 'hidden' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          <Routes>
            <Route 
              path="/" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <Navigate to="/home" /> : <Navigate to="/scanQR" />))) : <Login />} 
            />
            <Route 
              path="/register" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <Navigate to="/home" /> : <Navigate to="/scanQR" />))) : <Register />} 
            />
            <Route 
              path="/login" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <Navigate to="/home" /> : <Navigate to="/scanQR" />))) : <Login />} 
            />
             <Route 
              path="/forgot-password" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <Navigate to="/home" /> : <Navigate to="/scanQR" />))) : <ForgotPassword />} 
            />
             <Route 
              path="/recover-password" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <Navigate to="/home" /> : <Navigate to="/scanQR" />))) : <RecoverPassword />} 
            />
            <Route 
              path="/home" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <Home /> : <Navigate to="/scanQR" />))) : <Navigate to="/login" />} 
            />
            <Route 
              path="/myschedule" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <MySchedule /> : <Navigate to="/scanQR" />))) : <Navigate to="/login" />} 
            />
            <Route 
              path="/myconversations" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <MyConversations /> : <Navigate to="/scanQR" />))) : <Navigate to="/login" />} 
            />
            <Route 
              path="/settings" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <Settings /> : <Navigate to="/scanQR" />))) : <Navigate to="/login" />} 
            />
            <Route 
              path="/messages" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <Messages /> : <Navigate to="/scanQR" />))) : <Navigate to="/login" />} 
            />
            <Route 
              path="/statistics" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <Statistics /> : <Navigate to="/scanQR" />))) : <Navigate to="/login" />} 
            />
            <Route 
              path="/scanQR" 
              element={token() ? <ScanQR /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/payment" 
              element={token() ? <Payment /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/payment-custom" 
              element={token() ? <PaymentCustom /> : <Navigate to="/login" />} 
            />
           <Route 
              path="/change-password" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <ChangePassword /> : <Navigate to="/scanQR" />))) : <Navigate to="/login" />} 
            />
            <Route 
              path="/myexpenses" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <MyExpenses /> : <Navigate to="/scanQR" />))) : <Navigate to="/login" />} 
            />
            <Route 
              path="/create-user" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <CreateUser /> : <Navigate to="/scanQR" />))) : <Navigate to="/login" />} 
            />
             <Route 
              path="/delete-account" 
              element={token() ? (needs_payment() ? <Navigate to="/payment" /> : (user_status() ? <Navigate to="/payment" /> : (baileys_status() ? <DeleteAccount /> : <Navigate to="/scanQR" />))) : <Navigate to="/login" />} 
            />
          </Routes>
        </Router>
      </LocalizationProvider>
    </div>
  );
}

export default App;