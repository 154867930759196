import React from 'react';

interface Option {
    option_name: string,
    description: string,
    icon: string
  }

interface Categoria {
  category_name: string;
  options: Option[];
}

interface CategoriasProps {
  activeTab: string;
  categorias: Categoria[];
  handleEditCategoria: (option_data: Option) => void;
  handleAddCategoria: () => void;
}

const Categories: React.FC<CategoriasProps> = ({
  activeTab,
  categorias,
  handleEditCategoria,
  handleAddCategoria,
}) => {
  return (
    <>
      {activeTab === 'Categorias' && (
        <div className="row px-3 animate__animated animate__fadeIn mb-3">
          <h5 className="fw-bold text-dark my-0">Categorías actuales</h5>
          <p className="text-dark mt-0 mb-3">
            Renata lo utilizará para segmentar las conversaciones, puedes agregar hasta 6.
          </p>

          <div className="d-block w-100">
            <div className="row px-2">
              {categorias.length > 0 &&
                categorias.map((categoria) =>
                  categoria.category_name === 'default' ? (
                    categoria.options.length > 0 &&
                    categoria.options.map((option) => (
                      <div
                        key={option.option_name}
                        className="btn bg-light p-3 me-3 mb-3 col-lg-3 col-md-5 col-sm-11 custom-height-categoria"
                        onClick={() => {
                          handleEditCategoria(option);
                        }}
                      >
                        <h1>{option.icon}</h1>
                        <span>{option.option_name}</span>
                      </div>
                    ))
                  ) : null
                )}

              {categorias[0]?.options.length < 6 && (
                <div
                  className="btn bg-light p-3 me-3 mb-3 col-lg-3 col-md-5 col-sm-11 custom-height-categoria"
                  onClick={handleAddCategoria}
                >
                  <h1>
                    <i className="bi bi-plus-lg"></i>
                  </h1>
                  <span>Agregar nueva</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Categories;