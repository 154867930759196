import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Modal } from 'react-bootstrap';
import '../../css/main.css';
import '../../css/modal.css';
import Menu from '../Menu/Menu';
import TopBar from '../../TopBar';
import { exportExampleFormat, exportToExcel, handleFileUpload } from '../../functions/excel'; // Ajusta la ruta según sea necesario
import { fetchBusinessStatus, formatPhoneNumber, getRawPhoneNumber, validateToken } from '../../functions/functions';
import loadingGif from '../../img/loading.gif';
import AdvancedFilters from './AdvancedFilters';
import Clipboard from '@react-native-clipboard/clipboard';
import config from '../../config/config';
import CustomModal from '../../CustomModal';
import ConfirmationModal from '../Modals/ConfirmationModal';
import SupportChatModal from '../Home/SupportChatModal';
import RightColumn from './RightColumn';
import LeftColumn from './LetfColumn';

interface Conversation {
  category: string;
  id: string;
  threadId: string;
  business_id: string;
  summary: string;
  user_name: string;
  user_phone: string;
  renata_sleep: boolean;
  active_conversation: boolean;
  last_message_date: string;
}

interface CategoryIcons {
  [key: string]: string | undefined;
}

function MyConversations() {
  const gojimx_businessId = localStorage.getItem('gojimx_businessId');
  const gojimx_token = localStorage.getItem('gojimx_token');
  const gojimx_payment_type = localStorage.getItem('gojimx_payment_type');


  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [categoryIcons, setCategoryIcons] = useState<CategoryIcons>({});

  const [uploaded_conversations, setUploadedConversations] = useState<Conversation[]>([]);

  const [supportChatOpen, setSupportChatOpen] = useState(false);
  const handleSupportChatOpen = () => setSupportChatOpen(true);
  const handleSupportChatClose = () => setSupportChatOpen(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalMensaje, setShowModalMensaje] = useState(false);
  const [showModalCarga, setShowModalCarga] = useState(false);

  const [opened_conversation, setOpenedConversation] = useState<Conversation>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  let selectedConversations = conversations.filter(conversation => selectedIds.includes(conversation.id));
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingConversations, setFetchingConversations] = useState(true);

  const [buttonText, setButtonText] = useState('Cargar');

  const [messageToSend, setMessageToSend] = useState('');

  const [copied, setCopied] = useState(false);

  const [selectedConversationPreview, setSelectedConversationPreview] = useState<Conversation>();

  /*FILTER STATES*/
  const [filterFecha, setFilterFecha] = useState<string | null>(null);
  const [filterEstado, setFilterEstado] = useState<string | null>(null);
  const [filterRenataState, setFilterRenataState] = useState<string | null>(null);
  const [filterCategory, setFilterCategory] = useState<string | null>(null);
  const [filterRange, setFilterRange] = useState<[Date | null, Date | null]>([null, null]);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleCloseModal = () => setShowModal(false);
  const handleCloseModalCarga = () => setShowModalCarga(false);
  const handleCloseModalMensaje = () => setShowModalMensaje(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState('✅')

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  const handleResetConversation = async () => {
    if (opened_conversation) {
      try {
        await axios.post(`${config.backendURL}/reset-conversation`, {
          id: opened_conversation.id
        }, {
          headers: {
            Authorization: `Bearer ${gojimx_token}`,
            'Content-Type': 'application/json',
          },
        });
        setShowModal(false);
        setConfirmModalType('✅');
        setModalMessage('La conversación ha sido reiniciada');
        setIsModalOpen(true);
        fetchConversations();
      } catch (error) {
        console.error('Error reiniciando la conversación:', error);
        setConfirmModalType('❌');
        setModalMessage('Error al reiniciar la conversación. Por favor, intente nuevamente.');
        setIsModalOpen(true);
      }
    }
    setShowModalConfirm(false);
  };

  const handleMessageChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setMessageToSend(e.target.value);
  };

  const copyToClipboard = () => {
    setCopied(true);
    if (opened_conversation) {
      const rawPhoneNumber = getRawPhoneNumber(opened_conversation.user_phone);
      Clipboard.setString(rawPhoneNumber);
    }
    // Set a timeout to change the icon back after 5 seconds
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  useEffect(() => {
    fetchBusinessStatus();
  }, [])


  function handleSelectConversation(id: string) {
    setSelectedIds(prevSelectedIds => {
      if (prevSelectedIds.includes(id)) {
        // Si el id ya está seleccionado, lo quitamos
        return prevSelectedIds.filter(selectedId => selectedId !== id);
      } else {
        // Si el id no está seleccionado, lo agregamos
        return [...prevSelectedIds, id];
      }
    });
  }

  function handleShowModal() {
    setOpenedConversation(selectedConversationPreview); // Establece la conversación abierta
    setShowModal(true); // Muestra el modal
  }

  function handleShowModalCarga() {
    setShowModalCarga(true); // Muestra el modal
  }

  function handleShowModalMensaje() {
    setShowModalMensaje(true); // Muestra el modal
  }

  const fetchConversations = () => {
    setFetchingConversations(true);
    axios.get(`${config.backendURL}/get_conversations/${gojimx_businessId}`, {
      headers: {
        Authorization: `Bearer ${gojimx_token}`
      }
    })
      .then(response => {
        setConversations(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the conversations:', error);
      }).finally(() => {
        setFetchingConversations(false);
      })
  };

  const fetchCategories = () => {
    axios.get(`${config.backendURL}/get_category/${gojimx_businessId}`, {
      headers: {
        Authorization: `Bearer ${gojimx_token}`
      }
    })
      .then(response => {
        // Assuming response.data[0] is the category document you want to use
        const options = response.data[0][0].options;
        const iconsMapping = options.reduce((acc: { [x: string]: any; }, option: { option_name: string | number; icon: any; }) => {
          acc[option.option_name] = option.icon;
          return acc;
        }, {});

        setCategoryIcons(iconsMapping);
      })
      .catch(error => {
        console.error('There was an error fetching the categories:', error);
      });
  };


  const uploadConversations = async () => {
    let loadedCount = 0; // Contador para el número de conversaciones cargadas
    const totalConversations = uploaded_conversations.length; // Total de conversaciones a cargar

    setIsLoading(true); // Inicia la carga

    const updateButtonText = () => {
      loadedCount++; // Incrementa por cada conversación cargada
      setButtonText(`${loadedCount} de ${totalConversations}`); // Actualiza el texto del botón
    };

    // Modifica cada promesa para resolver con un objeto de resultado en lugar de rechazar directamente
    const promises = uploaded_conversations.map(conversation => {
      const conversationWithBusinessId = { ...conversation, business_id: gojimx_businessId };
      return axios.post(`${config.backendURL}/addConversation`, conversationWithBusinessId, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`,
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          updateButtonText(); // Actualiza el texto del botón después de cada carga exitosa
          return { success: true, data: response.data }; // Resultado exitoso
        })
        .catch(error => {
          updateButtonText(); // Asegúrate de actualizar el contador incluso en caso de error
          return { success: false, error: error.response.data, conversation: conversationWithBusinessId }; // Resultado fallido
        });
    });

    try {
      const results = await Promise.all(promises);
      // Filtra y alerta sobre los errores
      const errors = results.filter(result => !result.success);
      if (errors.length > 0) {
        const errorMessages = errors.map((error, index) => {
          // Verifica explícitamente si el objeto de error tiene la propiedad 'error'
          if ('error' in error) {
            return `Error ${index + 1}: ${error.error}: ${JSON.stringify(error.conversation.user_phone)}`;
          } else {
            return `Error ${index + 1}: Error desconocido.`;
          }
        }).join('\n');
        setConfirmModalType('❌');
        setModalMessage('Algunas conversaciones no se pudieron cargar:\n' + errorMessages);
        setIsModalOpen(true);
      } else {
        setConfirmModalType('✅');

        setModalMessage('Todas las conversaciones han sido cargadas exitosamente.');
        setIsModalOpen(true);
      }

      fetchConversations(); // Recargar la lista de conversaciones.
    } catch (error) {
      // Este bloque catch ya no es necesario para capturar errores en las promesas,
      // pero se deja por si hay otros errores inesperados.
      console.error('Error inesperado:', error);
    } finally {
      setIsLoading(false); // Finaliza la carga
      setButtonText('Cargar'); // Restablece el texto del botón a su estado original
      handleCloseModalCarga();
    }
  };

  const extractPhoneNumbers = (id: string) => {
    const [userPhone, channelPhone] = id.split('@s.whatsapp.net');
    const channelNumber = channelPhone.slice(0);
    return {
      userPhone,
      channelPhone: channelNumber
    };
  };

  function sendMessages() {
    // Asegúrate de que hay un mensaje para enviar
    if (!messageToSend.trim()) {
      setConfirmModalType('❌');

      setModalMessage('El mensaje no puede estar vacío.');
      setIsModalOpen(true);
      return;
    }

    setIsLoading(true);

    // Crear un array de promesas basado en las conversaciones seleccionadas
    const sendMessagePromises = uniqueConversations.map(conversation => {
      // Prepara el cuerpo de la solicitud para cada conversación
      const requestBody = {
        param_threadId: conversation.threadId,
        business_id: gojimx_businessId,
        whatsAppNumber: conversation.user_phone,
        messageText: messageToSend.trim(),
        channel: conversation.id
      };

      // Hace la solicitud POST para enviar el mensaje
      return axios.post(`${config.backendURL}/addMessage`, requestBody, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`,
          'Content-Type': 'application/json'
        }
      }).then(response => ({
        success: true,
        data: response.data
      })).catch(error => ({
        success: false,
        error: error.response ? error.response.data : 'Error desconocido',
        conversationId: conversation.id
      }));
    });

    // Usa Promise.all para esperar a que todas las solicitudes se completen
    Promise.all(sendMessagePromises)
      .then(results => {
        // Aquí puedes manejar las respuestas, por ejemplo, mostrar un mensaje de éxito o error
        const failedMessages = results.filter(result => !result.success);
        if (failedMessages.length > 0) {
          // Manejo de errores para los mensajes que fallaron
          setConfirmModalType('❌');

          setModalMessage('Algunos mensajes no se pudieron enviar. Total fallos: ' + failedMessages.length);
          setIsModalOpen(true);
        } else {
          // Todos los mensajes se enviaron con éxito
          setConfirmModalType('✅');
          setModalMessage('Todos los mensajes se enviaron con éxito.')
          setIsModalOpen(true);
        }
      })
      .catch(error => {
        // Manejo de un error inesperado durante el proceso de envío
        console.error('Error inesperado:', error);
        setConfirmModalType('❌');

        setModalMessage('Ocurrió un error inesperado al enviar los mensajes.');
        setIsModalOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
        setMessageToSend('');
        handleCloseModalMensaje();
      });
  }

  const handleSearchChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setSearchTerm(e.target.value);
  };

  const now = new Date();
  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - now.getDay() + 1);
  startOfWeek.setHours(0, 0, 0, 0); // Set to the beginning of the day for accurate comparison
  const filteredConversations = conversations.filter(conversation => {
    const matchesSearchTerm = conversation.user_name.toLowerCase().includes(searchTerm.toLowerCase()) || conversation.user_phone.includes(searchTerm);

    // Determine if the conversation matches the active state filter
    let matchesEstadoFilter = true; // Assume true by default
    if (filterEstado) {
      if (filterEstado === 'Activas') {
        matchesEstadoFilter = isActive(conversation.last_message_date);
      } else if (filterEstado === 'Inactivas') {
        matchesEstadoFilter = !isActive(conversation.last_message_date);
      }
    }

    // Existing logic for date filtering
    const conversationDate = new Date(conversation.last_message_date);
    const now = new Date();
    let matchesDateFilter = true; // Initialize as true by default

    switch (filterFecha) {
      case 'hoy':
        matchesDateFilter = conversationDate.toDateString() === now.toDateString();
        break;
      case 'esta semana':
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // End of the week (Sunday)
        matchesDateFilter = conversationDate >= startOfWeek && conversationDate <= endOfWeek;
        break;
      case 'este mes':
        matchesDateFilter = conversationDate.getMonth() === now.getMonth() && conversationDate.getFullYear() === now.getFullYear();
        break;
      case 'este año':
        matchesDateFilter = conversationDate.getFullYear() === now.getFullYear();
        break;
      default:
        break;
    }

    if (filterRange[0] && filterRange[1]) {
      matchesDateFilter = matchesDateFilter && conversationDate >= filterRange[0] && conversationDate <= filterRange[1];
    }

    // Filtering based on Renata's sleep state
    let matchesRenataState = true; // Assume true if not filtering by Renata state
    if (filterRenataState === 'renata_sleep') {
      matchesRenataState = conversation.renata_sleep === true;
    } else if (filterRenataState === 'renata_on') {
      matchesRenataState = conversation.renata_sleep !== true;
    }

    // Filtering based on Renata's sleep state
    let matchesCategory = true; // Assume true if not filtering by Renata state
    if (filterCategory != null) {
      matchesCategory = conversation.category === filterCategory;
    }

    let activeConversation = true;
    if (!conversation.active_conversation) {
      activeConversation = false;
    }


    return matchesSearchTerm && activeConversation && matchesDateFilter && matchesEstadoFilter && matchesRenataState && matchesCategory;
  });

  function isActive(fechaStr: string): boolean {
    const fecha = new Date(fechaStr);
    const fechaActual = new Date();
    const diferencia = fechaActual.getTime() - fecha.getTime();
    const horas = diferencia / (1000 * 60 * 60);
    return horas <= 24;
  }

  function selectAll() {
    if (filteredConversations.length === selectedIds.length) {
      setSelectedIds([]);
    } else {
      const allIds = filteredConversations.map(conversation => conversation.id);
      setSelectedIds(allIds);
    }
  }

  function isConversationActive(conversation: Conversation): boolean {
    if (!conversation.threadId) return false; // No activa si no hay threadId

    const lastMessageDate = new Date(conversation.last_message_date);
    const now = new Date();
    const differenceInHours = (now.getTime() - lastMessageDate.getTime()) / (1000 * 3600);

    return differenceInHours <= 23; // Activa si el último mensaje fue hace menos de 23 horas
  }

  // Determina si al menos una conversación seleccionada es inactiva
  const hasInactiveConversation = selectedConversations.some(conv => !isConversationActive(conv));

  const sendTemplateMessage = async () => {
    // Verificar si hay conversaciones seleccionadas
    if (selectedConversations.length === 0) {
      setConfirmModalType('❌');

      setModalMessage('Por favor, seleccina al menos una conversación.')
      setIsModalOpen(true);
      return;
    }

    setIsLoading(true);

    //FER ROBLES FIX
    let template_id = "renata_hola";
    if (String(gojimx_businessId) == "299594866569176") {
      template_id = "fer_robles";
    }

    const sendMessagePromises = selectedConversations.map((conversation) => {
      const requestBody = {
        business_id: String(gojimx_businessId), // Utiliza el ID de negocio almacenado en localStorage
        whatsAppNumber: String(conversation.user_phone), // Número de WhatsApp de la conversación seleccionada
        templateName: template_id, // Nombre de la plantilla
        templateLanguage: "es_MX", // Idioma de la plantilla
        templateParameters: [
          String(messageToSend)
        ], // Parámetros de la plantilla
      };

      return axios.post(`${config.backendURL}/addMessageTemplate`, requestBody, {
        headers: {
          Authorization: `Bearer ${gojimx_token}`, // Asegúrate de tener un token válido aquí
          'Content-Type': 'application/json',
        },
      }).then(response => ({
        success: true,
        data: response.data,
      })).catch(error => ({
        success: false,
        error: error.response ? error.response.data : 'Error desconocido',
        conversationId: conversation.id,
      }));
    });

    // Usa Promise.all para esperar a que todas las solicitudes se completen
    Promise.all(sendMessagePromises)
      .then(results => {
        const failedMessages = results.filter(result => !result.success);
        if (failedMessages.length > 0) {
          setConfirmModalType('❌');
          setModalMessage('Algunos mensajes de plantilla no se pudieron enviar. Total fallos: ' + failedMessages.length);
          setIsModalOpen(true);
        } else {
          setConfirmModalType('✅');
          setModalMessage('Todos los mensajes de plantilla se enviaron con éxito.');
          setIsModalOpen(true);
        }
      })
      .catch(error => {
        console.error('Error inesperado:', error);
        setConfirmModalType('❌');

        setModalMessage('Ocurrió un error inesperado al enviar los mensajes de plantilla.');
        setIsModalOpen(true);
      })
      .finally(() => {
        setIsLoading(false);
        setMessageToSend('');
        handleCloseModalMensaje();
      });
  };

  async function handleRenataSleepChange(e: { target: { checked: any; }; }) {
    const newRenataSleepStatus = !e.target.checked;

    // Ensure opened_conversation is not null or undefined
    if (opened_conversation) {
      // Create a new object with the updated renata_sleep value
      const updatedConversation = { ...opened_conversation, renata_sleep: newRenataSleepStatus };

      // Update the state to trigger a re-render
      setOpenedConversation(updatedConversation);

      const url = `${config.backendURL}/updateRenataSleep`;
      const requestBody = {
        threadId: updatedConversation.threadId,
        renata_sleep: newRenataSleepStatus,
      };

      try {
        await axios.post(url, requestBody, {
          headers: {
            Authorization: `Bearer ${gojimx_token}`,
            'Content-Type': 'application/json',
          },
        });
        setConfirmModalType('✅');
        setModalMessage(`Renata ha sido ${newRenataSleepStatus ? 'apagada' : 'encendida'} en esta conversación.`);
        setIsModalOpen(true);
        fetchConversations();
      } catch (error) {
        console.error('Error updating Renata sleep status:', error);
        setConfirmModalType('❌');

        setModalMessage('Failed to update Renata sleep status. Please try again.');
        setIsModalOpen(true);
        window.location.reload();
      }
    }
  }

  useEffect(() => {
    const checkToken = async () => {
      const isValidToken = await validateToken();
      if (!isValidToken) {
        // Manejar el caso de token inválido si es necesario
      } else {
        fetchConversations();
        fetchCategories();
      }
    }

    checkToken();
  }, []);

  useEffect(() => {
        setSelectedConversationPreview(filteredConversations[0]);
  }, [conversations])

  const uniqueConversations = selectedConversations.filter((conv, index, self) =>
    index === self.findIndex((c) => c.id === conv.id)
  );

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow} />
      </div>
      <ConfirmationModal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        text={modalMessage}
        type={confirmModalType}
        titleText="Resultado de la Operación"
      />

      <div className='container-fluid'>
        <div className='row'>
          <LeftColumn
            conversations={conversations}
            categoryIcons={categoryIcons}
            selectedConversationPreview={selectedConversationPreview}
            setSelectedConversationPreview={setSelectedConversationPreview}
            handleShowModalCarga={handleShowModalCarga}
            handleShowModalMensaje={handleShowModalMensaje}
            exportToExcel={exportToExcel}
            gojimx_businessId={gojimx_businessId}
            gojimx_token={gojimx_token}
            filterFecha={filterFecha}
            setFilterFecha={setFilterFecha}
            filterEstado={filterEstado}
            setFilterEstado={setFilterEstado}
            filterRenataState={filterRenataState}
            setFilterRenataState={setFilterRenataState}
            filterCategory={filterCategory}
            setFilterCategory={setFilterCategory}
            filterRange={filterRange}
            setFilterRange={setFilterRange}
            fetchingConversations={fetchingConversations}
            selectedIds={selectedIds} // Añade esto
            setSelectedIds={setSelectedIds} // Y esto
          />

          <RightColumn
            selectedConversationPreview={selectedConversationPreview}
            categoryIcons={categoryIcons}
            handleShowModalDetails={handleShowModal}
            fetchConversations={fetchConversations}
          />
        </div>

      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>{opened_conversation?.user_name}</Modal.Title>
          {/* <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} className='mx-2'>
            <Button
              variant="danger"
              onClick={() => setShowModalConfirm(true)}
            >
              Reiniciar conversación
            </Button>
          </div> */}
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className="col-3">
              <p className='me-3 fw-bold'>Fecha</p>
            </div>
            <div className="col-9">
              <p className=''>{opened_conversation?.last_message_date}</p>
            </div>
          </div>
          <div className='row'>
            <div className="col-3">
              <p className='me-3 fw-bold'>Teléfono</p>
            </div>
            <div className="col-9">
              <p className='me-3 d-flex'>
                {opened_conversation ? formatPhoneNumber(opened_conversation.user_phone) : ''}
                <i
                  className={`bi ${copied ? 'bi-check' : 'bi-clipboard'} mt-0 ms-3 py-0`}
                  style={{ fontSize: 18, cursor: 'pointer', display: 'flex', alignItems: 'center', top: 0 }}
                  onClick={copyToClipboard}
                ></i>
              </p>
            </div>
          </div>



          <div className='row'>
            <div className="col-3">
              <p className='me-3 fw-bold'>Canal</p>
            </div>
            <div className="col-9">
              <p className='me-3 d-flex'>
                {opened_conversation ? formatPhoneNumber(extractPhoneNumbers(opened_conversation.id).channelPhone) : ''}
              </p>
            </div>
          </div>

          {opened_conversation?.threadId &&
            <div className='row'>
              <div className='col-3'>
                <p className='me-3 fw-bold'>Limpiar conversación</p>
              </div>
              <div className='col-6 text-start mt-1'>
                <Button variant="outline-danger" onClick={() => {
                              setShowModal(false)

                  setShowModalConfirm(true)
                }
                }>
                  Limpiar
                </Button>
              </div>
            </div>
          }


        </Modal.Body>
      </Modal>

      <Modal show={showModalConfirm} onHide={() => {
        setShowModalConfirm(false)
        setShowModal(true)
      }
      }>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>Confirmar reinicio de conversación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Estás seguro de que deseas reiniciar esta conversación? Esta acción no se puede deshacer.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => {
            setShowModalConfirm(false)
            setShowModal(true)
          }
          }>
            Cancelar
          </Button>
          <Button variant="danger" onClick={handleResetConversation}>
            Reiniciar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModalCarga} onHide={handleCloseModalCarga}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>🔗 Cargar Conversaciones</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-3'>
            <div className="col-12">
              <p className='me-3 mb-0 fw-bold'>Formato</p>
              <a href='#' onClick={exportExampleFormat} className='text-primary'>Descarga el formato para carga masiva</a>
            </div>
          </div>
          <div className='row'>
            <div className="col-12">
              <p className='me-3 mb-0 fw-bold'>Archivo</p>
            </div>
          </div>


          <div className="row">
            <div className="mb-3">
              <label className="form-label">Carga el archivo con el formato de Renata</label>
              <input className="form-control" type="file" id="formFile" onChange={(e) => {
                // Llama a handleFileUpload y proporciona una función callback para actualizar el estado
                handleFileUpload(e, (data) => {
                  setUploadedConversations(data);
                });
              }}></input>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowModalCarga(false)}>
            Cancelar
          </Button>
          <Button variant="dark px-3 w-25" onClick={uploadConversations}>
            {isLoading ? <img src={loadingGif} alt="Cargando..." style={{ width: '20px', marginRight: '10px' }} /> : null}
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showModalMensaje} onHide={handleCloseModalMensaje}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>💬 Enviar Mensaje</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='row mb-1'>
            <div className="col-12 align-items-center">
              <p className='lead mb-1 fw-bold'>Para</p>
            </div>
          </div>

          {/* Iterar sobre las conversaciones seleccionadas */}
          {uniqueConversations.map((conv, index) => (
            <div key={index} className='row border mx-0 d-flex align-items-center p-2 rounded mb-1 bg-white'>
              <div className="col-11 d-flex align-items-center">
                <p className={`${isConversationActive(conv) ? 'text-success' : 'text-secondary'}  mb-0 fw-bolder me-3`}><i className='bi bi-circle-fill'></i></p>
                <p className='mb-0 me-3 fw-bold'>{conv.user_name}</p>
                <p className='mb-0 ms-3'>{conv.user_phone.replace('@s.whatsapp.net', '')}</p>
              </div>
              <div className="col-1 d-flex align-items-center justify-content-center">
                {selectedConversations.length > 1 && (
                  <i className="bi bi-x-circle-fill text-secondary" onClick={() => handleSelectConversation(conv.id)} style={{ cursor: 'pointer' }}></i>
                )}
              </div>
            </div>
          ))}

          {/* Mostraba mensaje de advertencia o área de texto basado en el estado de la conversación */}
          <div className='row mt-4'>
            <div className="col-12 align-items-center">
              <p className='lead mb-1 fw-bold'>Mensaje</p>
              <p className='mb-2'>Ingresa el mensaje que deseas que Renata envíe</p>
              <textarea
                className="form-control mb-2"
                placeholder="Mensaje para enviar"
                rows={3}
                onChange={handleMessageChange}
                value={messageToSend}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowModalMensaje(false)}>
            Cancelar
          </Button>

          {hasInactiveConversation ?
            (
              <Button
                variant="dark px-3 w-25"
                onClick={sendMessages}
                disabled={messageToSend.trim().length === 0} // Deshabilita el botón si el mensaje está vacío o hay conversaciones inactivas
              >
                {isLoading ? <img src={loadingGif} alt="Enviando..." style={{ width: '20px', marginRight: '10px' }} /> : "Enviar"}
              </Button>

            ) : (
              <Button
                variant="dark px-3 w-25"
                onClick={sendMessages}
                disabled={messageToSend.trim().length === 0} // Deshabilita el botón si el mensaje está vacío o hay conversaciones inactivas
              >
                {isLoading ? <img src={loadingGif} alt="Enviando..." style={{ width: '20px', marginRight: '10px' }} /> : "Enviar"}
              </Button>
            )}

        </Modal.Footer>
      </Modal>


      <Menu show={show} handleClose={handleClose} />
      {gojimx_payment_type === 'Premium' && (
        <>
          <div className="support-bubble animate__animated animate__fadeIn" onClick={handleSupportChatOpen}>
            💬
          </div>
          <SupportChatModal show={supportChatOpen} handleClose={handleSupportChatClose} />
        </>
      )}
    </div>
  );
}

export default MyConversations;
