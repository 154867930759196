import React, { useEffect, useState } from 'react';
import '../../css/main.css';
import Menu from '../Menu/Menu';
import TopBar from '../../TopBar';
import axios from 'axios';
import moment from 'moment';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  TooltipItem,
} from 'chart.js';
import config from '../../config/config';
import SupportChatModal from '../Home/SupportChatModal';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Transaction {
  business_id: string;
  transaction_date: string;
  transaction_type: string;
  transaction_ammount: number;
  transaction_source: string;
  __v: number;
}

interface AverageAmountData {
  averageAmount: number;
  transaction_source: string;
}

function MyExpenses() {
  const [show, setShow] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('day');
  const [averageTransactionAmount, setAverageTransactionAmount] = useState<number>(0);
  const [averageTransactionAmountAverage, setAverageTransactionAmountAverage] = useState<number>(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [visibleTransactions, setVisibleTransactions] = useState<Transaction[]>([]);
  const [showMore, setShowMore] = useState(false);
  const [averageAmountBySourceData, setAverageAmountBySourceData] = useState<AverageAmountData[]>([]);
  const [currentPoints, setCurrentPoints] = useState<number>(0);

  const gojimx_payment_type = localStorage.getItem('gojimx_payment_type');
  const [predefinedQuestion, setPredefinedQuestion] = useState<string>();

  const [supportChatOpen, setSupportChatOpen] = useState(false);
  const handleSupportChatOpen = (question: string) => {
    setPredefinedQuestion(question);
    setSupportChatOpen(true);
  };
const handleSupportChatClose = () => setSupportChatOpen(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handlePeriodChange = (period: React.SetStateAction<string>) => {
    setSelectedPeriod(period);
  };

  const animateValue = (start: number, end: number, duration: number, setter: (value: number) => void) => {
    let startTime: number | null = null;
  
    const animate = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      const elapsed = timestamp - startTime;
  
      // Calcular el progreso como un valor entre 0 y 1
      const progress = Math.min(elapsed / duration, 1); // Asegúrate de que no supere 1
      const current = start + (end - start) * progress; // Interpolación lineal
  
      setter(current); // Establecer el valor actual
  
      if (progress < 1) {
        requestAnimationFrame(animate); // Solicitar el siguiente cuadro de animación
      } else {
        setter(end); // Asegúrate de que el valor final se establezca
      }
    };
  
    requestAnimationFrame(animate); // Iniciar la animación
  };
    
  // Helper function to format numbers with commas
  const formatNumberWithCommas = (number: number) => {
    return new Intl.NumberFormat().format(number);
  };


  useEffect(() => {
    fetchTransactionsByPeriod(selectedPeriod);

    const fetchBusinessInfo = async () => {
      const gojimx_businessId = localStorage.getItem('gojimx_businessId');
      const gojimx_token = localStorage.getItem('gojimx_token');
    
      try {
        const response = await axios.get(
          `${config.backendURL}/get_business/${gojimx_businessId}`,
          {
            headers: {
              'Authorization': `Bearer ${gojimx_token}`
            }
          }
        );
        setCurrentPoints(Number(response.data[0].renata_points.toFixed(0)));
      } catch (error) {
        console.error('Error fetching business info:', error);
      }
    };
    
    fetchBusinessInfo();
    
    animateValue(0, Number(currentPoints.toFixed(0)), 1000, setCurrentPoints);
  }, [selectedPeriod]);

  useEffect(() => {
    const fetchAverageAmountBySourceData = async () => {
      const gojimx_businessId = localStorage.getItem('gojimx_businessId');
      const gojimx_token = localStorage.getItem('gojimx_token');

      try {
        const response = await axios.get<AverageAmountData[]>(
          `${config.backendURL}/averageAmountBySource/${gojimx_businessId}`,
          {
            headers: {
              'Authorization': `Bearer ${gojimx_token}`
            }
          }
        );
        setAverageAmountBySourceData(response.data);
      } catch (error) {
        console.error('Error fetching average amount data:', error);
      }
    };
    fetchAverageAmountBySourceData();
  }, []);
  
  const getPeriodDates = (period: string) => {
    const endDate = moment().endOf('day');
    let startDate;
    switch (period) {
      case 'day':
        startDate = moment().startOf('day');
        break;
      case 'week':
        startDate = moment().startOf('isoWeek');
        break;
      case 'month':
        startDate = moment().startOf('month');
        break;
      case 'year':
        startDate = moment().startOf('year');
        break;
      default:
        startDate = moment().startOf('year');
    }
    return { startDate: startDate.toISOString(), endDate: endDate.toISOString() };
  };

  const fetchTransactionsByPeriod = async (period: string) => {
    const gojimx_businessId = localStorage.getItem('gojimx_businessId');
    const gojimx_token = localStorage.getItem('gojimx_token');
    const { startDate, endDate } = getPeriodDates(period);

    try {
      const response = await axios.get<Transaction[]>(`${config.backendURL}/transactionsByDate/${gojimx_businessId}?startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          'Authorization': `Bearer ${gojimx_token}`
        }
      });
      const transactions = response.data.reverse();
      setTransactions(transactions);
      setVisibleTransactions(transactions.slice(0, 10));
      setShowMore(transactions.length > 10);
  
      const subtractTransactions = transactions.filter(transaction => transaction.transaction_type === 'subtract');
  
      const newAverage = calculateTransactionAmount(subtractTransactions, 'total');
      const newAmountAverage = calculateTransactionAmount(subtractTransactions, 'average');
  
      animateValue(averageTransactionAmount, newAverage, 500, setAverageTransactionAmount);
      animateValue(averageTransactionAmountAverage, newAmountAverage, 400, setAverageTransactionAmountAverage);
      } catch (error) {
      console.error('Error fetching transactions:', error);
      }
    };

  const calculateTransactionAmount = (transactions: Transaction[], type: 'total' | 'average') => {
    if (transactions.length === 0) return 0;
  
    const totalAmount = transactions.reduce((sum, transaction) => sum + transaction.transaction_ammount, 0);
  
    return type === 'average' ? totalAmount / transactions.length : totalAmount;
  };

  const handleLoadMore = () => {
    const newVisibleCount = visibleTransactions.length + 10;
    setVisibleTransactions(transactions.slice(0, newVisibleCount));
    setShowMore(newVisibleCount < transactions.length);
  };

  const sortedAverageAmountBySourceData = [...averageAmountBySourceData].sort((a, b) => b.averageAmount - a.averageAmount);

  const colors = [
    'rgba(54, 162, 235, 0.6)',  // Blue
    'rgba(75, 192, 192, 0.6)',  // Green
    'rgba(255, 99, 132, 0.6)',  // Red
    'rgba(255, 205, 86, 0.6)',  // Yellow
    'rgba(255, 159, 64, 0.6)',  // Orange
    'rgba(153, 102, 255, 0.6)', // Purple
    'rgba(255, 159, 64, 0.6)',  // Pink
    'rgba(201, 203, 207, 0.6)', // Grey
    'rgba(75, 192, 192, 0.6)',  // Light Blue
    'rgba(255, 99, 132, 0.6)'   // Light Red
  ];
  
  const borderColors = [
    'rgba(54, 162, 235, 1)',    // Blue
    'rgba(75, 192, 192, 1)',    // Green
    'rgba(255, 99, 132, 1)',    // Red
    'rgba(255, 205, 86, 1)',    // Yellow
    'rgba(255, 159, 64, 1)',    // Orange
    'rgba(153, 102, 255, 1)',   // Purple
    'rgba(255, 159, 64, 1)',    // Pink
    'rgba(201, 203, 207, 1)',   // Grey
    'rgba(75, 192, 192, 1)',    // Light Blue
    'rgba(255, 99, 132, 1)'     // Light Red
  ];
  
  const chartData = {
    labels: sortedAverageAmountBySourceData.map(item => item.transaction_source),
    datasets: [
      {
        label: 'Promedio por asistente',
        data: sortedAverageAmountBySourceData.map(item => item.averageAmount),
        backgroundColor: sortedAverageAmountBySourceData.map((_, index) => colors[index % colors.length]),
        borderColor: sortedAverageAmountBySourceData.map((_, index) => borderColors[index % borderColors.length]),
        borderWidth: 1,
      },
    ],
  };
  const chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem: TooltipItem<'bar'>) {
            // Customize tooltip if necessary
            return `${tooltipItem.label}: $${(tooltipItem.raw as number).toFixed(2)}`;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Promedio por transacción',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Asistente',
          padding: {
            top: 10,
          }
        },
        ticks: {
          autoSkip: false,
          maxRotation: 90, // Rotate labels 90 degrees
          minRotation: 60, // Ensure labels are always vertical
        },
      },
    },
  };
  

  return (
    <div className={show ? 'blur-effect pt-main' : 'pt-main'}>
      <div id="topbar">
        <TopBar handleShow={handleShow} />
      </div>
      <div className='row justify-content-center'>
        <div className='col-lg-6 col-md-8 col-sm-12 py-4'>
          <div className='row px-3 animate__animated animate__fadeIn'>
            <h1 className='fw-bold text-dark mt-0'>Consumo</h1>
          </div>
          <div className="mt-2 bg-light p-0 rounded-pill d-flex justify-content-center animate__animated animate__fadeIn">
            <button
              className={selectedPeriod === "day" ? 'btn btn-lg border-0 btn-dark rounded-pill' : 'btn btn-lg border-0 btn-light rounded-pill'}
              style={{ width: '150px' }}
              onClick={() => handlePeriodChange('day')}
            >
              Hoy
            </button>
            <button
              className={selectedPeriod === "week" ? 'btn btn-lg border-0 btn-dark rounded-pill' : 'btn btn-lg border-0 btn-light rounded-pill'}
              style={{ width: '150px' }}
              onClick={() => handlePeriodChange('week')}
            >
              Semana
            </button>
            <button
              className={selectedPeriod === "month" ? 'btn btn-lg border-0 btn-dark rounded-pill' : 'btn btn-lg border-0 btn-light rounded-pill'}
              style={{ width: '150px' }}
              onClick={() => handlePeriodChange('month')}
            >
              Mes
            </button>
            <button
              className={selectedPeriod === "year" ? 'btn btn-lg border-0 btn-dark rounded-pill' : 'btn btn-lg border-0 btn-light rounded-pill'}
              style={{ width: '150px' }}
              onClick={() => handlePeriodChange('year')}
            >
              Año
            </button>
            <button
              className={selectedPeriod === "total" ? 'btn btn-lg border-0 btn-dark rounded-pill' : 'btn btn-lg border-0 btn-light rounded-pill'}
              style={{ width: '150px' }}
              onClick={() => handlePeriodChange('total')}
            >
              Total
            </button>
          </div>
          <div className='w-100 my-4'>
            <div className='border mb-2 animate__animated animate__fadeIn'></div>
          </div>

          <div className='w-100 my-4 px-3'>
            <div className="row">
              <div className="col-6 border-end border-2">
                <h2>Acumulado transacciones</h2>
                <h1 className='d-flex'> <i className='bi bi-x-diamond-fill text-primary me-3'></i> <span><samp>{formatNumberWithCommas(averageTransactionAmount)}</samp></span></h1>
                <h2 className='mt-4'>Transacciones</h2>
                {visibleTransactions.map((transaction, index) => (
                  <h4 key={index} className='d-flex bg-light rounded p-2 mb-2'>
                    <span className={`me-2 ${transaction.transaction_type === 'subtract' ? 'text-danger fw-bold' : 'text-success'}`}>
                      {transaction.transaction_type === 'subtract' ? '-' : '+'}
                    </span>
                    {transaction.transaction_ammount.toFixed(1)}
                    <span className='text-secondary ms-2'>
                      <i>{new Date(transaction.transaction_date).toLocaleString('es-MX', { dateStyle: 'short', timeStyle: 'short' })}</i>
                    </span>
                  </h4>
                ))}
                {showMore && (
                  <h4 className='d-flex bg-light rounded p-2 mb-2 btn' onClick={handleLoadMore}>
                    <span className='text-secondary ms-2'> <i>Cargar más</i> </span>
                  </h4>
                )}
              </div>
              <div className="col-6">
                <h2>Promedio interacción <i className="bi bi-question-circle" onClick={() => handleSupportChatOpen("¿Qué es el promedio de interacción?")}></i></h2>
                <h2 className='bg-light p-2 rounded'>{averageTransactionAmountAverage.toFixed(4)}</h2>
                <h2>Promedio por asistente</h2>
                <div style={{ width: '500px',  height: '500px' }}>
                  <Bar data={chartData} options={chartOptions} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Menu show={show} handleClose={handleClose} />
      {gojimx_payment_type === 'Premium' && (
        <>
          <div className="support-bubble animate__animated animate__fadeIn" onClick={() => handleSupportChatOpen("")}>
            💬
          </div>
          <SupportChatModal show={supportChatOpen} handleClose={handleSupportChatClose} predefinedQuestion={predefinedQuestion}/>
        </>
      )}
    </div>
  );
}

export default MyExpenses;