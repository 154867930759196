import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmDeleteModalProps {
  showModalConfirmDeleteBase: boolean;
  titleModalAdd: string;
  isLoading: boolean;
  loadingGif: string;
  setTitleModalAdd: (title: string) => void;
  setConfirmDeleteBase: (value: boolean) => void;
  setShowAddBaseModal: (value: boolean) => void;
  handleCheckDeleteBase: () => void;
  handleMultiFileDelete: () => void;
}

const ConfirmDeleteBaseModal: React.FC<ConfirmDeleteModalProps> = ({
  showModalConfirmDeleteBase,
  titleModalAdd,
  isLoading,
  loadingGif,
  setTitleModalAdd,
  setConfirmDeleteBase,
  setShowAddBaseModal,
  handleCheckDeleteBase,
  handleMultiFileDelete,
}) => {
  const handleClose = () => {
    if (titleModalAdd === 'Eliminar Base de conocimiento') {
      setTitleModalAdd('Editar Base de Conocimiento');
      setConfirmDeleteBase(false);
      setShowAddBaseModal(true);
    }
  };

  return (
    <Modal show={showModalConfirmDeleteBase} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">{titleModalAdd}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {titleModalAdd === 'Eliminar Base de conocimiento' && (
          <p>
            ¿Estás seguro de que deseas eliminar esta base de conocimiento? Esta acción no se puede
            deshacer.
          </p>
        )}
        {titleModalAdd === 'Eliminar archivo' && (
          <p>
            ¿Estás seguro de que deseas eliminar este archivo de la base? Esta acción no se puede
            deshacer.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {titleModalAdd === 'Eliminar Base de conocimiento' && (
          <div>
            <Button
              className="me-2"
              variant="light"
              onClick={() => {
                setTitleModalAdd('Editar Base de Conocimiento');
                setConfirmDeleteBase(false);
                setShowAddBaseModal(true);
              }}
            >
              Cancelar
            </Button>
            <Button
              className="me-2"
              disabled={isLoading}
              variant="danger"
              onClick={handleCheckDeleteBase}
            >
              {isLoading ? (
                <img className="loading" src={loadingGif} alt="Cargando..." />
              ) : (
                'Eliminar base'
              )}
            </Button>
          </div>
        )}
        {titleModalAdd === 'Eliminar archivo' && (
          <div>
            <Button variant="light" onClick={() => setConfirmDeleteBase(false)}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleMultiFileDelete}>
              {isLoading ? (
                <img className="loading" src={loadingGif} alt="Cargando..." />
              ) : (
                'Eliminar archivo de la base'
              )}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteBaseModal;