import React from 'react';
import { Tooltip } from 'react-tooltip'
import 'bootstrap-icons/font/bootstrap-icons.css';

interface BaseConocimiento {
  knowledge_base_nickname: string;
}

interface Files {
    file: string,
    file_url: string
  }

  interface Base {
    _id: string,
    knowledge_base_name: string,
    knowledge_base_nickname: string,
    baseName: string,
    archivos: Array<Files>,
    stat: string;
  }

interface ConocimientoComponentProps {
  activeTab: string;
  basesConocimiento: BaseConocimiento[];
  handleShowFiles: (nickname: string) => void;
  truncateFileName: (name: string, maxLength: number) => string;
  handleEditBase: (nickname: string) => void;
  handleAddBase: () => void;
  selectedBase: Base | null;
  fileDate: string;
  isSyncing: boolean;
  addFileToBase: (baseName: string) => void;
  selectedFilesDelete: string[];
  handleFileClick: (file: Files) => void;
  setDeleteInfo: (info: { file: string; baseName: string }) => void;
  selectedFiles: File[];
  removeFile: (index: number) => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isExpanded: boolean;
  inputValue: string;
  setInputValue: (value: string) => void;
  updateSelectedBaseStat: (status: string) => void;
  handleFocus: () => void;
  handleBlur: () => void;
}

const KnowledgeTab: React.FC<ConocimientoComponentProps> = ({
  activeTab,
  basesConocimiento,
  handleShowFiles,
  truncateFileName,
  handleEditBase,
  handleAddBase,
  selectedBase,
  fileDate,
  isSyncing,
  addFileToBase,
  selectedFilesDelete,
  handleFileClick,
  setDeleteInfo,
  selectedFiles,
  removeFile,
  handleFileChange,
  isExpanded,
  inputValue,
  setInputValue,
  updateSelectedBaseStat,
  handleFocus,
  handleBlur,
}) => {
  return (
    <>
      {activeTab === 'Conocimiento' && (
        <div className="row px-3 animate__animated animate__fadeIn mb-3">
          <h5 className="fw-bold text-dark my-0">Bases de conocimiento</h5>
          <p className="text-dark mt-0 mb-4">
            Una base de conocimiento es la información digital que usa Renata; El RAG mejora
            respuestas al buscar y organizar datos relevantes para ofrecer respuestas claras y
            precisas
          </p>

          <div className="d-block w-100">
            <div className="row px-2">
              <div className="col-3">
                {basesConocimiento.length > 0 ? (
                  <div className="mb-1 btn-base">
                    <div className="row mb-1">
                      {basesConocimiento.map((base, index) => (
                        <button
                          key={index}
                          type="button"
                          className="btn btn-sm border-0 btn-light rounded-pill w-100 btn-size mb-2 cita-option"
                          onClick={() => {
                            handleShowFiles(base.knowledge_base_nickname);
                          }}
                        >
                          <span className="float-start">🧠</span>
                          <Tooltip id="base" />
                          <span
                            data-tooltip-id="base"
                            data-tooltip-content={base.knowledge_base_nickname}
                            data-tooltip-place="top"
                          >
                            {truncateFileName(base.knowledge_base_nickname, 15)}
                          </span>
                          <span
                            className="float-end"
                            onClick={() => handleEditBase(base.knowledge_base_nickname)}
                          >
                            <i className="bi bi-pencil"></i>
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="row">
                  <button
                    type="button"
                    className="btn btn-sm border-0 btn-light rounded-pill w-100"
                    onClick={handleAddBase}
                  >
                    <i className="bi bi-plus-lg"></i>
                  </button>
                </div>
              </div>
              <div className="col-9">
                {selectedBase ? (
                  <div className="fw-bold b-vertical">
                    <Tooltip id="selBase" />
                    <h2
                      data-tooltip-id="selBase"
                      data-tooltip-content={selectedBase.baseName}
                      data-tooltip-place="top"
                      key={selectedBase.baseName}
                      className="fw-bold text-dark"
                    >
                      🧠 {truncateFileName(selectedBase.baseName, 26)}
                    </h2>
                    <div>
                      Estatus:
                      {selectedBase.stat === 'ok' && (
                        <span className="d-inline">
                          <i className="bi bi-check-circle link-success ms-1"></i> Sincronizado{' '}
                          <span className="text-secondary"> {fileDate}</span>
                        </span>
                      )}
                      {selectedBase.stat === 'err' && (
                        <span className="d-inline">
                          <i className="bi bi-x-circle link-danger ms-1"></i> ERROR{' '}
                          <span className="text-secondary">Puedes volver a intentar</span>
                        </span>
                      )}
                      {selectedBase.stat === 'sync' && (
                        <span className="d-inline-flex ps-2">
                          <div className="i-circle">
                            <i
                              className={`bi bi-arrow-repeat ${isSyncing ? 'spin' : ''}`}
                            ></i>
                          </div>
                          <span className="ps-1">
                            Sincronizando{' '}
                            <span className="text-secondary">Tiempo estimado 2 minutos</span>
                          </span>
                        </span>
                      )}
                      {selectedBase.stat === 'no-sync' && (
                        <span className="d-inline">
                          <i className="bi bi-exclamation-circle text-warning ms-1"></i> No
                          sincronizado <span className="fw-light">Por favor presiona el botón</span>
                        </span>
                      )}
                    </div>
                    <hr className="border border-2 opacity-100" />
                    <p className="d-inline me-2">Archivos de conocimiento</p>
                    <button
                      className="btn btn-dark btn-sm rounded-pill border-0 px-4"
                      disabled={isSyncing}
                      onClick={() => addFileToBase(selectedBase.baseName)}
                    >
                      <i className="bi bi-arrow-repeat me-1"></i> Sincronizar
                    </button>
                    <div className="row bg-light px-4 py-2 mt-2 mx-1 rounded-4">
                      <div className="row">
                        {selectedBase.archivos &&
                          selectedBase.archivos.map((file, index) => (
                            <div key={index} className="col-6 mb-2">
                              <Tooltip id="btn" />
                              <div
                                data-tooltip-id="btn"
                                data-tooltip-content={file.file}
                                data-tooltip-place="top"
                                className={`btn d-flex justify-content-center align-items-center btn-sm btn-secondary rounded-pill w-100 ${
                                  selectedFilesDelete.includes(file.file) ? 'selected' : ''
                                }`}
                                onClick={() => {
                                  handleFileClick(file);
                                  setDeleteInfo({
                                    file: file.file,
                                    baseName: selectedBase.baseName,
                                  });
                                }}
                              >
                                <span
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'inline-block',
                                    maxWidth: '100%',
                                  }}
                                >
                                  📄 {truncateFileName(file.file, 20)}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                      {selectedFiles.length > 0 && (
                        <div className="mt-1 row">
                          {selectedFiles.map((file, index) => (
                            <div key={index} className="col-6">
                              <button
                                key={file.name}
                                type="button"
                                className="btn btn-sm btn-outline-secondary rounded-pill w-100"
                              >
                                📄 {truncateFileName(file.name, 19)}
                                <span onClick={() => removeFile(index)}>
                                  <i className="bi bi-x ms-2"></i>
                                </span>
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                      <div className="row">
                        <div className="col-4">
                          <label id="input-img">
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              multiple
                              onChange={handleFileChange}
                            />
                            <i className="bi bi-folder-plus px-5 mx-1 border border-dark rounded-pill point"></i>
                          </label>
                        </div>
                        <div className="col-8 position-relative">
                          <div
                            style={{
                              position: 'absolute',
                              display: 'inline-block',
                              bottom: '0',
                            }}
                          >
                            {!isExpanded && (
                              <span
                                style={{
                                  position: 'absolute',
                                  left: '15px',
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  pointerEvents: 'none',
                                  zIndex: 1,
                                  fontSize: '18px',
                                }}
                              >
                                🔍
                              </span>
                            )}
                            <input
                              type="text"
                              value={inputValue}
                              onChange={(e) => {
                                setInputValue(e.target.value);
                                updateSelectedBaseStat('no-sync');
                                if (e.target.value === '') {
                                  updateSelectedBaseStat('ok');
                                }
                              }}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              className="rounded-pill d-flex align-content-center"
                              placeholder={
                                isExpanded ? '🔍 link de wikipedia para webscraping' : ''
                              }
                              style={{
                                width: isExpanded ? '300px' : '50px',
                                height: '25px',
                                paddingLeft: isExpanded ? '10px' : '30px',
                                transition: 'width 0.3s ease',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p className="text-body-secondary b-vertical">
                    Aún no cuentas con ninguna base de conocimiento, haz click en el botón "+".
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default KnowledgeTab;