import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmDeleteAssistantModalProps {
  showModalConfirmDeleteAssistant: boolean;
  setConfirmDeleteAssistant: (value: boolean) => void;
  setShowEditAsistenteModal: (value: boolean) => void;
  savingAssistant: boolean;
  loadingGif: string;
  handleDeleteAssistant: () => void;
}

const ConfirmDeleteAssistantModal: React.FC<ConfirmDeleteAssistantModalProps> = ({
  showModalConfirmDeleteAssistant,
  setConfirmDeleteAssistant,
  setShowEditAsistenteModal,
  savingAssistant,
  loadingGif,
  handleDeleteAssistant,
}) => {
  const handleClose = () => {
    setConfirmDeleteAssistant(false);
    setShowEditAsistenteModal(true);
  };

  return (
    <Modal show={showModalConfirmDeleteAssistant} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">Eliminar asistente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Estás seguro de que deseas eliminar este asistente? Esta acción no se puede deshacer.</p>
      </Modal.Body>
      <Modal.Footer>
        {!savingAssistant ? (
          <>
            <Button variant="light" onClick={handleClose}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleDeleteAssistant}>
              Eliminar asistente
            </Button>
          </>
        ) : (
          <div className="ms-auto me-3">
            <h1 style={{ fontWeight: 'bold' }}>
              <img className="loading" src={loadingGif} alt="Cargando..." />
            </h1>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteAssistantModal;