import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface FileModalProps {
  showFileModal: boolean;
  handleCloseFileModal: () => void;
  handleBaseNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFile: { file: string } | null;
  modalPdfUrl: string | null;
  handleFileDeleteModal: () => void;
}

const FileModal: React.FC<FileModalProps> = ({
  showFileModal,
  handleCloseFileModal,
  handleBaseNameChange,
  selectedFile,
  modalPdfUrl,
  handleFileDeleteModal,
}) => {
  return (
    <Modal show={showFileModal} onHide={handleCloseFileModal} size="lg" scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Editar archivo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-9">
            <h5 className="fw-bold text-dark mb-2">Nombre</h5>
            <div>
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Ej. Base de conocimiento A"
                readOnly
                value={selectedFile?.file}
                onChange={handleBaseNameChange}
              />
            </div>
          </div>
          <div className="col-3 position-relative">
            <button
              className="btn btn-outline-danger position-absolute rounded-pill bottom-0 mb-3"
              onClick={handleFileDeleteModal}
            >
              Eliminar archivo
            </button>
          </div>
        </div>
        {modalPdfUrl && (
          <>
            {/* Puedes usar el componente Viewer si está disponible */}
            {/* 
            <Viewer 
              fileUrl={modalPdfUrl}
              plugins={[defaultLayoutPluginInstance]}
            />
            */}
            <iframe
              title="modalPdf"
              src={modalPdfUrl}
              width="100%"
              height="600px"
              style={{ border: 'none' }}
            >
              Este navegador no soporta PDFs. Por favor descarga el PDF para verlo: <a href={modalPdfUrl}>Descargar PDF</a>
            </iframe>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={handleCloseFileModal}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileModal;