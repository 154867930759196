import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Slider from '@mui/material/Slider';
import loadingGif from '../../img/loading.gif';
import { Tooltip } from 'react-tooltip'


interface FileType {
  name: string;
}

interface AddBaseModalProps {
  showAddBaseModal: boolean;
  handleCloseAddBaseModal: () => void;
  titleModalAdd: string;
  baseName: string;
  handleBaseNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileChangeModal: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFilesModal: FileType[];
  totalSize: number;
  truncateFileName: (name: string, maxLength: number) => string;
  removeFileModal: (index: number) => void;
  sizeResponse: string;
  handleSliderResponse: (event: Event, newValue: number | number[]) => void;
  wordNumber: string;
  handleSliderWord: (event: Event, newValue: number | number[]) => void;
  isLoading: boolean;
  getTokenEstimation: () => void;
  updateBaseName: () => void;
  setTitleModalAdd: (title: string) => void;
  setConfirmDeleteBase: (value: boolean) => void;
  setShowAddBaseModal: (value: boolean) => void;
}

const AddBaseModal: React.FC<AddBaseModalProps> = ({
  showAddBaseModal,
  handleCloseAddBaseModal,
  titleModalAdd,
  baseName,
  handleBaseNameChange,
  handleFileChangeModal,
  selectedFilesModal,
  totalSize,
  truncateFileName,
  removeFileModal,
  sizeResponse,
  handleSliderResponse,
  wordNumber,
  handleSliderWord,
  isLoading,
  getTokenEstimation,
  updateBaseName,
  setTitleModalAdd,
  setConfirmDeleteBase,
  setShowAddBaseModal,
}) => {
  return (
    <Modal show={showAddBaseModal} onHide={handleCloseAddBaseModal} className="bg-modal-cont">
      <Modal.Header closeButton>
        <Modal.Title className="fw-bold">{titleModalAdd}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="fw-bold text-dark mb-2">Nombre</h5>
        <div>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Ej. Base de conocimiento A"
            value={baseName}
            onChange={handleBaseNameChange}
          />
        </div>

        {/* Base File Input */}
        {titleModalAdd === 'Añadir Base de Conocimiento' && (
          <div>
            <h5 className="fw-bold text-dark mb-2">Archivo de conocimiento</h5>
            <div className="bg-light p-2 rounded-4 px-5">
              <label id="input-img">
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  multiple
                  onChange={handleFileChangeModal}
                />
                <i className="bi bi-folder-plus px-5 mx-1 border border-dark rounded-pill point"></i>
              </label>

              {selectedFilesModal.length > 0 && (
                <div className="row ms-1">
                  <Tooltip id="mod" />
                  {selectedFilesModal.map((file, index) => (
                    <div
                      className="border border-dark rounded-pill px-2 mt-2 mw-50 col-6"
                      key={index}
                      data-tooltip-id="mod"
                      data-tooltip-content={file.name}
                      data-tooltip-place="top"
                    >
                      <i className="bi bi-file-earmark-text me-1"></i>
                      {truncateFileName(file.name, 16)}
                      <span onClick={() => removeFileModal(index)}>
                        <i className="bi bi-x ms-1"></i>
                      </span>
                    </div>
                  ))}
                </div>
              )}
              <small>Tamaño total: {(totalSize / (1024 * 1024)).toFixed(2)} MB</small>
            </div>
            <div>
              <h5 className="fw-bold text-dark mb-2 mt-4">Número de resultados</h5>
              <div className="row justify-content-center mt-4">
                <div className="col-9">
                  <Slider
                    value={parseInt(sizeResponse)}
                    onChange={handleSliderResponse}
                    min={2}
                    max={5}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={[
                      { value: 2, label: '2' },
                      { value: 3, label: '3' },
                      { value: 4, label: '4' },
                      { value: 5, label: '5' },
                    ]}
                  />
                </div>
                <div className="col-2">
                  <Form.Control value={sizeResponse} readOnly />
                </div>
              </div>
            </div>
            <div>
              <h5 className="fw-bold text-dark mt-4">Longitud del contexto</h5>
              <div className="row justify-content-center">
                <div className="col-9">
                  <Slider
                    value={parseInt(wordNumber)}
                    onChange={handleSliderWord}
                    min={150}
                    max={250}
                    step={10}
                    valueLabelDisplay="auto"
                    marks={[
                      { value: 150, label: '150' },
                      { value: 160, label: '160' },
                      { value: 170, label: '170' },
                      { value: 180, label: '180' },
                      { value: 190, label: '190' },
                      { value: 200, label: '200' },
                      { value: 210, label: '210' },
                      { value: 220, label: '220' },
                      { value: 230, label: '230' },
                      { value: 240, label: '240' },
                      { value: 250, label: '250' },
                    ]}
                  />
                </div>
                <div className="col-2">
                  <Form.Control value={wordNumber} readOnly />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {titleModalAdd === 'Añadir Base de Conocimiento' && (
          <div>
            <Button className='mx-2' variant="light" onClick={handleCloseAddBaseModal} disabled={isLoading}>
              Cancelar
            </Button>
            <Button variant="dark" onClick={getTokenEstimation} disabled={isLoading}>
              {isLoading ? (
                <img className="loading" src={loadingGif} alt="Cargando..." />
              ) : (
                'Guardar'
              )}
            </Button>
          </div>
        )}

        {titleModalAdd === 'Editar Base de Conocimiento' && (
          <div>
            <Button
              variant="danger"
              className='mx-2'
              onClick={() => {
                setTitleModalAdd('Eliminar Base de conocimiento');
                setConfirmDeleteBase(true);
                setShowAddBaseModal(false);
              }}
            >
              Eliminar
            </Button>
            <Button variant="dark" onClick={updateBaseName} disabled={isLoading}>
              {isLoading ? (
                <img className="loading" src={loadingGif} alt="Cargando..." />
              ) : (
                'Actualizar'
              )}
            </Button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddBaseModal;